import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const AGENCE_API = environment.back_url + '/agencies/';

@Injectable({
  providedIn: 'root'
})
export class AgenceService {
  config = { withCredentials: true}
  constructor() { }
  private agenceUpdatedSubject = new Subject<void>();
  agenceUpdated$: Observable<void> = this.agenceUpdatedSubject.asObservable();
  async getAgences(){
    return (await axios.get(AGENCE_API , this.config)).data.data;
  }

  getAgence(id: string){
    return axios.get(AGENCE_API + id, this.config);
  }

  async getClients(){
    return (await axios.get(`${AGENCE_API}clients`, this.config)).data.data;
  }

  async getSites(){
    return (await axios.get(`${AGENCE_API}sites`, this.config)).data.data;
  }

  async getSubcontractors(){
    return (await axios.get(`${AGENCE_API}subcontractors`, this.config)).data.data;
  }
  getAgencesByCompany(id: string){
    return axios.get(AGENCE_API + 'company/' +id, this.config);
  }

  async getAgenciesByCompany(id: string){
    return (await axios.get(AGENCE_API + 'company/' +id, this.config)).data.data;
  }

  addAgence(agence: any){
    return  axios.post(AGENCE_API, agence, this.config);
  }

  updateAgence(agence: any){
    return  axios.put(`${AGENCE_API}${agence?.id}`, agence, this.config);
  }

  triggerAgenceUpdated() {
    this.agenceUpdatedSubject.next();
  }
}

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FollowerService } from 'src/app/core/services/follower.service';
import { OrderService } from 'src/app/core/services/order.service';
import { RdvService } from 'src/app/core/services/rdv.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-command-infos',
  templateUrl: './command-infos.component.html',
  styleUrls: ['./command-infos.component.scss']
})
export class CommandInfosComponent implements OnInit {
  @Input() command: any;
  isFollowing : boolean = false;
  current_user!: any ;
  currentEscalation!: any;
  rdvVT: any;
  rdvMES: any;
  deadLineDate!: Date;
  constructor(public datepipe: DatePipe, private _snackBar: MatSnackBar,
    private followerService: FollowerService, private tokenService: TokenStorageService, private orderService: OrderService, private rdvService: RdvService) { }

  async ngOnInit(): Promise<void> {
    this.orderService.orderUpdated$.subscribe(()=> {
      this.loadData();
    })
    this.loadData();

  }

  async ngOnChanges(changes: any) {
    try {
      this.current_user = this.tokenService.getUser();
      if (changes.command.currentValue._id) {
        let followers = await this.followerService.getFolowers(changes.command.currentValue?._id);
        this.isFollowing = followers.some((item: any) => item?.userId?._id === this.current_user?.userId);
      }
      this.loadData();
    }catch(err){
      console.log('')
    }
    
  }

  async follow(){
    try {
      let follower = await this.followerService.addFollower(this.command._id, this.current_user?.userId);
      if(follower){
        this.isFollowing = true;
      }
      }
      catch(error: any){
        if(error?.response?.data?.motif === 'Cet utilisateur est déjà abonné à cette commande ')
        {
          this._snackBar.open(error?.response?.data?.motif, 'Fermer', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          });
        }
        else {
          this._snackBar.open("Ajout d'abonné echoué ", 'Fermer', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          });
        }
      }
      finally {
        this.followerService.triggerFollowerUpdated();
      }
   
  }

  async unfollow(){
    try {
        let follower = await this.followerService.removeFollower(this.command._id, this.current_user?.userId);
        this.isFollowing = false;
        this._snackBar.open("Désabonnement effectué avec succès", 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
      }
      catch(error: any){
        if(error?.response?.data?.motif === 'Cet utilisateur est déjà désabonné à cette commande ')
        {
          this._snackBar.open(error?.response?.data?.motif, 'Fermer', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          });
        }
        else {
          this._snackBar.open("Ajout d'abonné echoué ", 'Fermer', {
            duration: 4000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
          });
        }
      }
      finally {
        this.followerService.triggerFollowerUpdated();
      }
  }

  async loadData(){
    let escalations = await this.orderService.getEscalations(this.command?._id);
    this.currentEscalation = escalations.find((item : any ) => item?.cause && item.dateEnd === null);
    if (this.command?._id) {
      try {
        let acceptedRdvVTResponse = await this.rdvService.getRdvsByOrderStatusPublic(this.command?._id, 'ACCEPTED', 'VT');
        if (!acceptedRdvVTResponse.error && acceptedRdvVTResponse.data && acceptedRdvVTResponse.data.length > 0) {
          this.rdvVT = acceptedRdvVTResponse.data[0]?.dateStart;
        } else {
          this.rdvVT = null;
        }
      } catch (err) {
        console.warn('Accepted VT appointment not found ');
      }
    
      try {
        let acceptedRdvMESResponse = await this.rdvService.getRdvsByOrderStatusPublic(this.command?._id, 'ACCEPTED', 'MES');
        if (!acceptedRdvMESResponse.error && acceptedRdvMESResponse.data && acceptedRdvMESResponse.data.length > 0) {
          this.rdvMES = acceptedRdvMESResponse.data[0]?.dateStart;
        } else {
          this.rdvMES = null;
        }
      } catch (err) {
        console.warn('Accepted MES appointment not found');
      }
      
    }
    if (this.command?.dateSubmission && !this.command?.dateInitialDeadline) {
      let dateSubmission = new Date(this.command?.dateSubmission);
      this.deadLineDate = new Date(dateSubmission);
      this.deadLineDate.setDate(dateSubmission.getDate() + 35 );
    }
  }
}

<h1 mat-dialog-title>
    <span>Nouveau Statut</span>
    <span class="close">
        <mat-icon (click)="exit()">close</mat-icon>
    </span>
</h1>
<div class="row standard">
    <div class="col-xl-2"></div>
    <div class="col-xl-9">
        <form [formGroup]="statutFG">
            <p>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Référence</mat-label>
                    <input matInput placeholder="Référence" formControlName="reference" required>
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Nom</mat-label>
                    <input matInput placeholder="Nom" formControlName="name" required>
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="Description" formControlName="description" required>
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Délai en heures</mat-label>
                    <input matInput placeholder="maxDurationInHours" formControlName="maxDurationInHours"  type="number" required>
                </mat-form-field>
                <mat-form-field appearance="fill" class="input">
                    <mat-label>Catégorie</mat-label>
                    <mat-select name="statusCategories" formControlName="statusCategoryId" required>
                        <mat-option *ngFor="let statusCategory of statusCategories" value="{{statusCategory?._id}}">
                            {{statusCategory?.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="input">
                            <mat-label>Rôles</mat-label>
                            <mat-select formControlName="assignedRoleId" required>
                                <mat-option *ngFor="let role of roles" [value]="role?._id">
                                    {{role?.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
            </p>
            <div>
                <button mat-button-raised (click)="onSubmit()" class="btn btn-success">Valider</button>
            </div>
        </form>
    </div>
    <div class="col-xl-2"></div>
</div>
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgenceService } from 'src/app/core/services/agence.service';
import { RdvService } from 'src/app/core/services/rdv.service';

@Component({
  selector: 'app-rdv-view-modal',
  templateUrl: './rdv-view-modal.component.html',
  styleUrls: ['./rdv-view-modal.component.scss']
})
export class RdvViewModalComponent implements OnInit {

  rdv!: any;
  site!: any;
  constructor(private dialogRef: MatDialogRef<any>,public datepipe: DatePipe, @Inject(MAT_DIALOG_DATA) public data: any, private rdvService:  RdvService, private agencyService: AgenceService) { }

  async ngOnInit(): Promise<void> {
    this.rdv = await this.rdvService.getRdvById(this.data?.rdvId);
    this.site = await this.agencyService.getAgence(this.rdv?.orderId?.content?.siteId);
  }

  cancel(){
    this.dialogRef.close();
  }
}

import { DatePipe } from '@angular/common';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PermissionService } from 'src/app/core/services/permission.service';
import { RdvService } from 'src/app/core/services/rdv.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-rdv-status',
  templateUrl: './rdv-status.component.html',
  styleUrls: ['./rdv-status.component.scss', '../vt.component.scss']
})
export class RdvStatusComponent implements OnInit {
  @Input() command: any;
  current_user: any = null;
  hasPermission = false;
  rdv!: any[];
  constructor(public permissionService: PermissionService, public datepipe: DatePipe,
    private tokenService: TokenStorageService,
    private rdvService: RdvService) { }

  async ngOnInit(): Promise<void> {  
    this.current_user = this.tokenService.getUser();
    this.hasPermission = this.permissionService.hasPermission(this.current_user  , 'RDV_VIEW_ID');
    this.rdv = await this.rdvService.getRdvsByOrderStatus(this.command?._id, 'ACCEPTED');
    this.rdv = this.rdv.filter((r: any) => r?.type === 'VT');
  }

}

<div class="row">
  <div class="col-xl-9">
    <full-calendar [options]="calendarOptions" [events]="rdvEvents" (eventClick)="eventClick($event)"></full-calendar>
  </div>
  <div class="col-xl-3" [formGroup]="filterRdvGP">
    <div class="row">
      <h2>
        <mat-icon color="primary">filter_alt</mat-icon>
      </h2>
      <div class="filterTitle">Filtres</div>
      <br>
    </div>
    <div class="row">
        <div class="col-xl-5">
          <span class="icon-span" (click)="clearForm()"><mat-icon color="primary">clear_all</mat-icon> Réinitialiser les filtres</span>
        </div>
        <div class="col-xl-2"></div>
        <div class="col-xl-5">
          <span class="icon-span" (click)="refreshForm()"><mat-icon color="primary">refresh</mat-icon> Actualiser</span>
        </div>
    </div>
    <div class="row">
      <div class="col-xl-12 marged-div">
        <mat-button-toggle-group fxLayout="row" formControlName="personalFilter" class="searchField" aria-label="filters">
          <mat-button-toggle [value]="'all'" fxFlex="25%" *ngIf="isAdmin" matTooltip="Tout">Tout</mat-button-toggle> <br>
          <mat-button-toggle [value]="'myOrders'" fxFlex="25%" matTooltip="Mes commandes">Mes commandes</mat-button-toggle> <br>
          <mat-button-toggle [value]="'myCompany'" fxFlex="25%" matTooltip="Mon entreprise">Mon entreprise</mat-button-toggle> <br>
          <mat-button-toggle [value]="'myAgency'" fxFlex="25%" matTooltip="Mon agence">Mon agence</mat-button-toggle>
        </mat-button-toggle-group>

      </div>
    </div>
    <br>
    <div class="row searchField">
      <div class="col-xl-12">
        <mat-form-field appearance="fill">
          <mat-label>Fournisseur</mat-label>
          <mat-select formControlName="subcontractor" (ngModelChange)="onChangeSubcontractor($event)" required>
            <mat-option *ngFor="let s of subcontractor" [value]="s?._id">
              {{s?.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-xl-12">
        <mat-form-field appearance="fill">
          <mat-label>Agence</mat-label>
          <mat-select formControlName="subcontractorAgency" required multiple>
            <mat-option *ngFor="let sa of subcontractorAgency" [value]="sa?._id">
              {{sa?.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-xl-12">
        <mat-button-toggle-group fxLayout="row" formControlName="type" class="searchField" aria-label="Font Style" multiple>
          <mat-button-toggle  fxFlex="50%" *ngFor="let t of types" [value]="t?.id" matTooltip= {{t?.name}}>{{ t?.name}}</mat-button-toggle>
        </mat-button-toggle-group>
        <!--mat-form-field appearance="fill" class="searchField">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type" required>
            <mat-option *ngFor="let t of types" [value]="t?.id">
              {{t?.name}}
            </mat-option>
          </mat-select>
        </mat-form-field-->
      </div>
      <br>
      <br>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <table class="table table-stripped">
          <th>Statut</th>
          <th>Icône</th>
          <th><mat-icon>tune</mat-icon></th>
          <tr  *ngFor="let s of rdvStatusMapping" [ngStyle]="{'color': s?.color}">
            <td>{{s?.translate}}</td>
            <td>
              <mat-icon>{{s?.icon}}</mat-icon>
            </td>
            <td>
              <mat-slide-toggle color="primary" [checked]="isStatusChecked(s?.name)" (change)="toggleStatus(s?.name)">
              </mat-slide-toggle>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
const ROLE_API = environment.back_url + '/roles/';
const config = { withCredentials: true}
@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor() { }
  async getRoles(){
    return  (await axios.get(ROLE_API , config)).data.data;
  }
  getRole(id: string){
    return  axios.get(ROLE_API + id, config);
  }

  addNewRole(role: any){
    return  axios.post(ROLE_API, role, config);
  }

  UpdateRole(role: any){
    return axios.put(`${ROLE_API}${role.roleId}` , role, config)
  }

  AddNewPermissionToRole(rolePermission: any){
    return axios.post(`${ROLE_API}permissions` , rolePermission , config) ;
  }

  async getRoleWithPermissions(roleId: string){
    return  (await axios.get(`${ROLE_API}${roleId}/permissions` , config)).data.data;
  }

  async getAllPermissions(){
    return (await axios.get(`${environment.back_url}/permissions`, config)).data.data;
  }
}

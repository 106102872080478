<button mat-button color="accent" (click)="OpenAddFollowereModal()">
    <mat-icon color="accent">add</mat-icon>
    <span>Ajouter</span>
</button>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions</th>
        <td mat-cell *matCellDef="let element">
            <button mat-menu-item (click)="unsubscribe(element)">
                <mat-icon color="warn">unsubscribe</mat-icon>
            </button>
        </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nom et prénom</th>
        <td mat-cell *matCellDef="let element"> {{ element?.userId?.contactId?.lastName }} {{
            element?.userId?.contactId?.firstName }} </td>
    </ng-container>
    <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef> Adresse mail </th>
        <td mat-cell *matCellDef="let element"> {{ element?.userId?.contactId?.emailAddress }} </td>
    </ng-container>
    <ng-container matColumnDef="phone1">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{ element?.userId?.contactId?.phone1 }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
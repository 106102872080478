<div class="row">
    <div class="col-xl-2">
        <div class="PrefilterTitle">
            <mat-icon color="primary">tune</mat-icon> Filtres prédéfinis
        </div>
    </div>
    <div class="col-xl-9">

        <mat-chip-list #chipList aria-label="Filter selection" class="mat-chip-list">
            <mat-chip [ngStyle]="{'background-color': filter?.color}" *ngFor="let filter of savedFilters" matTooltip="{{ filter?.description }}"
                (removed)="removeFilter(filter)" (click)="loadSavedFilter(filter)">
                {{filter?.name}}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </mat-chip-list>
    </div>
</div>
<br>
<mat-accordion class="accordion-header" multi>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon color="primary">filter_alt</mat-icon>
                <div class="filterTitle">Filtres</div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="filterGP">
            <div class="wrapper">
                <div class="clearAll">
                    <span (click)="clearForm()"> Réinitialiser les filtres<mat-icon color="primary">clear_all</mat-icon>
                        </span>
                </div>
            </div>
            <!--div class="row">
                    <div class="col-xl-3 filterMenu">
                        <div mat-button [matMenuTriggerFor]="menu" class="menuStyle"><mat-icon color="primary">more_vert</mat-icon> <b class="menuTitle">Mes filtres</b></div>
                        <mat-menu #menu="matMenu">
                            <div *ngFor="let filter of savedFilters" mat-menu-item (click)="loadSavedFilter(filter)">{{filter?.name}}</div>
                        </mat-menu>
                    </div>
            </div-->
            <br>
            <br>
            <div class="row">

                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>N° de commande MAE</mat-label>
                        <input matInput placeholder="Placeholder" formControlName="orderNumber">
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Fournisseur</mat-label>
                        <mat-select formControlName="subcontractor" (ngModelChange)="onChangeSubcontractor($event)"
                            required>
                            <mat-option *ngFor="let s of subcontractor" [value]="s?._id">
                                {{s?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Agence</mat-label>
                        <mat-select formControlName="subcontractorAgency" required multiple>
                            <mat-option *ngFor="let sa of subcontractorAgency" [value]="sa?._id">
                                {{sa?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div class="row">
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Statut</mat-label>
                        <mat-select formControlName="statusCategory" (ngModelChange)="onChangeStatusCategory($event)"
                            required>
                            <mat-option *ngFor="let sc of statusCategory" [value]="sc?.reference">
                                {{sc?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="col-xl-2">
                        <mat-checkbox class="check-input" color="primary" formControlName="finishedOrders">Masquer les
                            terminées</mat-checkbox>
                    </div>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Sous statut</mat-label>
                        <mat-select formControlName="status" required multiple>
                            <mat-option *ngFor="let s of status" [value]="s?._id">
                                {{s?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-button-toggle-group formControlName="isEscalated" aria-label="Font Style">
                        <mat-button-toggle [value]="'escalated'">Escaladé</mat-button-toggle>
                        <mat-button-toggle [value]="'all'">Toutes</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Motif d'escalade</mat-label>
                        <mat-select formControlName="escalationMotif" required>
                            <mat-option *ngFor="let escalationMotif of escalationMotifs" [value]="escalationMotif?.id">
                                {{escalationMotif?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-button-toggle-group formControlName="following" aria-label="Font Style">
                        <mat-button-toggle [value]="'following'">Abonné</mat-button-toggle>
                        <mat-button-toggle [value]="'others'">Autres</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="col-xl-2">
                    <div class="col-xl-8">
                        <mat-button-toggle-group formControlName="assignedRole" aria-label="Font Style">
                            <mat-button-toggle [value]="'true'">Rôle actif</mat-button-toggle>
                            <mat-button-toggle [value]="'false'">Toutes</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Date de création MAE</mat-label>
                        <mat-date-range-input [rangePicker]="pickerCreation">
                            <input matStartDate placeholder="Debut" formControlName="dateCreation">
                            <input matEndDate placeholder="Fin" formControlName="dateCreationE">
                        </mat-date-range-input>
                        <mat-hint>JJ/MM/AAAA - JJ/MM/AAAA</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="pickerCreation"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerCreation></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Date de soumission</mat-label>
                        <mat-date-range-input [rangePicker]="pickerSubmission">
                            <input matStartDate placeholder="Debut" formControlName="dateSubmission">
                            <input matEndDate placeholder="Fin" formControlName="dateSubmissionE">
                        </mat-date-range-input>
                        <mat-hint>JJ/MM/AAAA - JJ/MM/AAAA</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="pickerSubmission"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerSubmission></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Date de livraison</mat-label>
                        <mat-date-range-input [rangePicker]="pickerDelivery">
                            <input matStartDate placeholder="Debut" formControlName="dateDelivery">
                            <input matEndDate placeholder="Fin" formControlName="dateDeliveryE">
                        </mat-date-range-input>
                        <mat-hint>JJ/MM/AAAA - JJ/MM/AAAA</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="pickerDelivery"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerDelivery></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Date de clôture</mat-label>
                        <mat-date-range-input [rangePicker]="pickerEnd">
                            <input matStartDate placeholder="Debut" formControlName="dateEnd">
                            <input matEndDate placeholder="Fin" formControlName="dateEndE">
                        </mat-date-range-input>
                        <mat-hint>JJ/MM/AAAA - JJ/MM/AAAA</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerEnd></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Date de RDV VT</mat-label>
                        <mat-date-range-input [rangePicker]="pickerRdvVT">
                            <input matStartDate placeholder="Debut" formControlName="dateRdvVt">
                            <input matEndDate placeholder="Fin" formControlName="dateRdvVtE">
                        </mat-date-range-input>
                        <mat-hint>JJ/MM/AAAA - JJ/MM/AAAA</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="pickerRdvVT"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerRdvVT></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Statut VT</mat-label>
                        <mat-select formControlName="statusVt" required multiple>
                            <mat-option *ngFor="let s of statusRdv" [value]="s?.name">
                                {{s?.translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Date de RDV MES</mat-label>
                        <mat-date-range-input [rangePicker]="pickerRdvMes">
                            <input matStartDate placeholder="Debut" formControlName="dateRdvMes">
                            <input matEndDate placeholder="Fin" formControlName="dateRdvMesE">
                        </mat-date-range-input>
                        <mat-hint>JJ/MM/AAAA - JJ/MM/AAAA</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="pickerRdvMes"></mat-datepicker-toggle>
                        <mat-date-range-picker #pickerRdvMes></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Statut MES</mat-label>
                        <mat-select formControlName="statusMes" required multiple>
                            <mat-option *ngFor="let s of statusRdv" [value]="s?.name">
                                {{s?.translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <br>
            <mat-divider></mat-divider>
            <br>
            <div class="row">
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>N° de commande EMA</mat-label>
                        <input matInput placeholder="Placeholder" formControlName="emaOrderNumber">
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Partenaire</mat-label>
                        <mat-select formControlName="clients" required multiple>
                            <mat-option *ngFor="let client of clients" [value]="client?._id">
                                {{client?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="products" required multiple>
                            <mat-option *ngFor="let product of products" [value]="product?.id">
                                {{product?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-xl-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Boucle</mat-label>
                        <mat-select formControlName="opticalLoops" required multiple>
                            <mat-option *ngFor="let opticalLoop of opticalLoops" [value]="opticalLoop">
                                {{opticalLoop}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
            <div class="row">
                <div class="col-xl-10">
                </div>
                <div class="col-xl-2">
                    <span (click)="openSaveFilterDialog()"> Enregistrer le filtre<mat-icon color="primary">save
                        </mat-icon></span>
                </div>
            </div>
            <br>

        </form>

    </mat-expansion-panel>
</mat-accordion>
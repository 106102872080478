<button mat-raised-button routerLink='/admin'>Retour</button>
<button mat-raised-button color="warn" routerLink='/user/edit/{{user?._id?._id || user?._id}}'>Modifier</button>
<br>
<br>
<div class="row">
    <div class="col-sm">
        <div class="card">
            <div class="card-header">Information d'utilisateur</div>
            <div class="card-body" *ngIf="user">
                <table class="table table-stripped">
                        <tr>
                            <th>Date de création</th>
                            <td>{{ datepipe.transform(user.date, 'dd-MM-yyyy hh:mm')}}</td>
                            <th>Date de modification</th>
                            <td>{{ datepipe.transform(user.dateLastModification, 'dd-MM-yyyy hh:mm')}}</td>
                        </tr>
                        <tr>
                            <th>Nom  </th>
                            <td *ngIf="user.contactId">{{ user?.contactId?.lastName }}</td>
                            <th>Prénom </th>
                            <td *ngIf="user.contactId">{{ user?.contactId?.firstName }} </td>
                        </tr>
                        <tr>
                            <th>E-mail </th>
                            <td *ngIf="user.contactId">{{ user?.contactId?.emailAddress}}</td>
                            <th>Identifiant </th>
                            <td>{{ user.username }}</td>
                        </tr>
                        <tr>
                            <th>Rôle</th>
                            <td *ngIf="user.rolesIds">
                                <ul>
                                    <li *ngFor="let role of user.rolesIds">{{ role.name }}</li>
                                </ul>
                            </td>
                            <th>Manager</th>
                            <td *ngIf="user.managerId">
                                {{ user.managerId?  user?.managerId?.username : '' }}
                            </td>
                        </tr>
                        <tr>
                            <th>Agence </th>
                            <td *ngIf="user.agencyId">{{user?.agencyId?.name}}</td>
                            <th>Status </th>
                            <td>{{ user?.isActive? "Actif" : "Inactif" }}</td>
                        </tr>
                        <tr>
                            <th>Téléphone </th>
                            <td *ngIf="user.contactId">{{ user?.contactId?.phone1 }}</td>
                            <th>Téléphone secondaire </th>
                            <td *ngIf="user.contactId">{{ user?.contactId?.phone2 }}</td>
                        </tr>

                        <tr>
                            <th>Notifications d'information lié à l'équipee</th>
                            <td>{{ user?.emailCCAuto ? user?.emailCCAuto : "" }}</td>
                            <th>Notifications d'information  en tant qu'abonné(e)</th>
                            <td>{{ user?.emailFollower ? user?.emailFollower : "" }}</td>
                        </tr>
                        <tr>
                            <th>Addresses Email CC </th>
                            <td *ngIf="user?.emailCCAddresses">
                                <ul>
                                    <li *ngFor="let e of user?.emailCCAddresses">{{ e}}</li>
                                </ul>
                            </td>
                        </tr>
                </table>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthentificationService } from './authentification.service';
import { TokenStorageService } from './token-storage.service';
const USER_API = environment.back_url + '/users/';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  config =
   { withCredentials: true}
  constructor(private authService: AuthentificationService, private tokenService : TokenStorageService) { 
  }
  getUsers(){
    return  axios.get(USER_API,  this.config);
  }

  getUsersByAgency(agencyId: string){
    return  axios.get(USER_API + 'agency/' + agencyId,  this.config);
  }
  async getUser(id: string){
    axios.defaults.withCredentials = true;
    return  (await axios.get(USER_API + id, this.config)).data.data;
  }

  getProfile(id: string){
    axios.defaults.withCredentials = true;
    return  axios.get(USER_API + 'profile/'+ id, this.config);
  }

  addNewUser(user: any){
    return  axios.post(USER_API, user, this.config);
  }

  updateUser(id: string, body: any){
    return axios.put(USER_API + id, body, this.config);
  }

  updateUserPassWord(id: string, body: any){
    return axios.put(USER_API + 'password/' + id, body, this.config);
  }

  updateProfile(id: string, body:any){
    return axios.put(USER_API + 'profile/' + id, body, this.config);
  }

  async getManagers(){
    return  (await axios.get(USER_API,  this.config)).data.data;
  }

  async getColleagues(id: string){
    return  (await axios.get(USER_API + 'company/' +  id,  this.config)).data.data;
  }
  async getUsersByRole(roleId: string){
    return (await axios.get(USER_API + 'roles/' + roleId,  this.config)).data.data;
  }

  async getUsersByCompany(companyId: string){
    return (await axios.get(USER_API + 'companies/' + companyId,  this.config)).data.data;
  }
  async getMyCompanyColleagues(companyId: string){
    return (await axios.get(USER_API + 'colleagues/' + companyId,  this.config)).data.data;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleService } from 'src/app/core/services/role.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-role-modal',
  templateUrl: './role-modal.component.html',
  styles: [
  ]
})
export class RoleModalComponent implements OnInit {
  public  permissions :any[] = [];
  public users : any[] = []
  constructor(private roleService: RoleService, private userService: UserService, private dialogRef: MatDialogRef<any>, private _snackBar: MatSnackBar ,@Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit(): Promise<void> {
    let response = await this.roleService.getRoleWithPermissions(this.data?.role?._id);
    this.permissions = response?.permissions;
    this.users = await this.userService.getUsersByRole(this.data?.role?._id);
  }

  exit(){
    this.dialogRef.close();
  }
}

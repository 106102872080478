import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';


@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  constructor( private tokenService: TokenStorageService, private router: Router, private authService: AuthentificationService, ) { }

  ngOnInit(): void {
    let current_user = this.tokenService.getUser();
    this.authService.getLogin().subscribe((data ) => {
    }, (err) => {
      this.router.navigate(['/login']); 
    })
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleService } from 'src/app/core/services/role.service';
import { StatusService } from 'src/app/core/services/status.service';
import { TransitionsLogsService } from 'src/app/core/services/transitions-logs.service';

@Component({
  selector: 'app-transition-update-form',
  templateUrl: './transition-update-form.component.html',
  styleUrls: ['./transition-update-form.component.scss']
})
export class TransitionUpdateFormComponent implements OnInit {

  public onlyReadInput =  true;
  transitionFG!: FormGroup;
  status: any[] = [];
  roles: any[] = [];
  constructor(private statusService: StatusService,
    private transitionService: TransitionsLogsService,
    private roleService: RoleService ,
    private _formBuilder: FormBuilder, 
    private dialogRef: MatDialogRef<any>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar ) { }

  async ngOnInit(): Promise<void> {
    this.transitionFG = this._formBuilder.group({
      reference: new FormControl(this.data?.transition?.reference , Validators.required),
      name: [this.data?.transition?.name , Validators.required],
      description: [this.data?.transition?.description],
      statusId: [this.data?.transition?.statusId?._id , Validators.required],
      nextStatusId: [this.data?.transition?.nextStatusId?._id , Validators.required]
    });

    this.roles = await this.roleService.getRoles();
    this.status = await this.statusService.getStatus();
    this.status.sort((a: any, b: any) => a.name.localeCompare(b.name));
  }

  onSubmit(){
    let transition = {
      id: this.data.transition?._id,
      reference: this.transitionFG.value.reference,
      name: this.transitionFG.value.name,
      description: this.transitionFG.value.description,
      statusId: this.transitionFG.value.statusId,
      nextStatusId: this.transitionFG.value.nextStatusId
    }

    this.transitionService.updateTransition(transition).then((response)  => {
      this._snackBar.open('Mise à jour de transition est effectuée avec succès', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }).catch(err => {
      this._snackBar.open('Erreur lors de la mise à jour de transition', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      })
    }).finally(() => {
        this.transitionService.triggerTransitionsUpdated();
        this.exit();
    });

  }

  exit(){
    this.dialogRef.close();
  }

}

<h1 mat-dialog-title>
    <span>Nouvelle transition</span>
    <span class="close">
        <mat-icon (click)="exit()">close</mat-icon>
    </span>
</h1>
<div class="row standard">
    <div class="col-xl-2"></div>
    <div class="col-xl-9">
        <form [formGroup]="transitionFG">
            <p>
                <mat-form-field appearance="fill" class="input">
                    <mat-label>Status courant</mat-label>
                    <mat-select name="status" formControlName="statusId" (ngModelChange)="onChangeStatus($event)" required>
                        <mat-option *ngFor="let s of status" [value]="s">
                            {{s?.reference}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="input">
                    <mat-label>Prochain status</mat-label>
                    <mat-select name="status" formControlName="nextStatusId" required (ngModelChange)="onChangeNextStatus($event)">
                        <mat-option *ngFor="let s of status" [value]="s">
                            {{s?.reference}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Référence</mat-label>
                    <input matInput placeholder="Référence" formControlName="reference" required readonly [disabled]="onlyReadInput">
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Nom</mat-label>
                    <input matInput placeholder="Nom" formControlName="name" required>
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="Description" formControlName="description" required>
                </mat-form-field>
            </p>
            <div>
                <button mat-button-raised (click)="onSubmit()" class="btn btn-success">Valider</button>
            </div>
        </form>
    </div>
    <div class="col-xl-2"></div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/core/services/company.service';

@Component({
  selector: 'app-newentreprise',
  templateUrl: './newentreprise.component.html',
  styleUrls: ['./newentreprise.component.scss']
})
export class NewentrepriseComponent implements OnInit {

  types = [{id: 'SUBCONTRACTOR', name:'Sous traitant'} , {id: 'CLIENT', name:'Client final'} , {id: 'SITE', name:'Site de livraison'} ]
  formdata = this.formBuilder.group({
    company: '',
    siret: '',
    type: ''
  });
  constructor(private formBuilder: FormBuilder, private companyService: CompanyService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
  }

  onClickSubmit() {

    let company: any;
    company = {
      name: this.formdata.value.company,
      siret: this.formdata.value.siret,
      type: this.formdata.value.type
    }
    this.companyService.addCompany(company).then(response =>{
      if (response.data.error == false) {
        this.router.navigate(['/admin']);
      }else{
        console.log("not rendered ....")
      }
    })
  }

}

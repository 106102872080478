<div class="df" *ngIf="hasPermission">
    <h2 class='title'>
        <mat-icon color="primary">payments</mat-icon> Données financières
    </h2>
    <div class="col-xl-12 twoboxes">
        <div class="row">
            <div class="col-xl-6">
                <!--app-quotation [command]="command"></app-quotation-->
                <app-document-manager [command]="command" documentType = "DF_QUOTATION"></app-document-manager>
            </div>
            <div class="col-xl-6">
                <app-quotation-status></app-quotation-status>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6">
                <app-document-manager [command]="command" documentType = "DF_PURCHASE_ORDER"></app-document-manager>
            </div>
            <div class="col-xl-6">
                <app-document-manager [command]="command" documentType = "DF_BDR"></app-document-manager>
            </div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// guards
import { AuthGuard } from './core/guards/auth.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { STCGuard } from './core/guards/stc.guard';

// pages public principales sans sous-pages
import { LoginComponent } from './pages/public/login/login.component';
import { NotAvailablePageComponent } from './pages/public/not-available-page/not-available-page.component';
import { RdvChoiceComponent }       from './pages/public/order/rdvChoice/rdv-choice.component';

//page principale avec sous-pages
import { DefaultComponent } from './pages/private/default.component';
// sous-pages d'administration
import { AdministrationComponent }  from './pages/private/admin/administration.component';
import { UsersComponent }           from './pages/private/admin/users/users.component';
import { NewuserComponent }         from './pages/private/admin/users/new/newuser.component';
import { UserComponent }            from './pages/private/admin/users/show/user.component';
import { EditUserComponent }        from './pages/private/admin/users/edit/edit-user.component';
import { NewentrepriseComponent }   from './pages/private/admin/entreprises/new/newentreprise.component';
import { EntreprisesComponent }     from './pages/private/admin/entreprises/entreprises.component';
import { NewAgencyComponent }       from './pages/private/admin/agencies/new/new-agency.component';
import { NewRoleComponent }         from './pages/private/admin/roles/new/new-role.component';
// autres sous-page
import { RdvCalendarComponent }             from './pages/private/calendar/rdv-calendar.component';
import { DashboardComponent }               from './pages/private/dashboard/dashboard.component';
import { NewCommandComponent }              from './pages/private/orders/new/newtreatment.component';
import { CommandTableComponent }            from './pages/private/orders/command-table.component';
import { CommandContainerComponent }        from './pages/private/order/command-container.component';
import { OrderRedirectComponentComponent }  from './pages/private/search/order-redirect-component.component';
import { EditProfileComponent }             from './pages/private/user/edit_profile/edit-profile.component';


const routes: Routes = [
  {
    // private route (must be at the begining in order to superseed when authenticated)
    path: '',
    component: DefaultComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'orders',
        children: [
          { path: '', component: CommandTableComponent, canActivate: [AuthGuard] },
          { path: 'new', component: NewCommandComponent, canActivate: [AuthGuard] },
        ]
      },
      { path: 'search',
        children: [
          { path: 'mae/:reference', component: OrderRedirectComponentComponent, canActivate: [AuthGuard] },
          { path: 'ema/:reference', component: OrderRedirectComponentComponent, canActivate: [AuthGuard] },
        ]
      },
      { path: 'order/:id', component: CommandContainerComponent, canActivate: [AuthGuard] },
      { path: 'calendar', component: RdvCalendarComponent, canActivate: [AuthGuard] },
      { path: 'admin',
        children: [
          { path: '', component: AdministrationComponent, canActivate: [AuthGuard, AdminGuard] },
          { path: 'users',
            children: [
              { path: '', component: UsersComponent, canActivate: [AuthGuard, AdminGuard] },
              { path: 'new', component: NewuserComponent, canActivate: [AuthGuard, AdminGuard] },
              { path: 'show/:id', component: UserComponent, canActivate: [AuthGuard, AdminGuard] },
              { path: 'edit/:id', component: EditUserComponent, canActivate: [AuthGuard, AdminGuard] },
            ]
          },
          { path: 'entreprises', component: EntreprisesComponent, canActivate: [AuthGuard, AdminGuard] },
          { path: 'entreprises/new', component: NewentrepriseComponent, canActivate: [AuthGuard, AdminGuard] },
          { path: 'agencies/new', component: NewAgencyComponent, canActivate: [AuthGuard, AdminGuard] },
      //  { path: 'contacts/new', component: NewAgencyComponent, canActivate: [AuthGuard, AdminGuard] },
          { path: 'roles/new', component: NewRoleComponent, canActivate: [AuthGuard, AdminGuard] },
        ]
      },
      { path: 'user/edit_profile/:id', component: EditProfileComponent, canActivate: [AuthGuard] },
    ]
  },
  {
    // public routes
    path: '',
    children: [
      { path: 'order/rdvChoice/:type/:id', component: RdvChoiceComponent },
      { path: 'notFound', component: NotAvailablePageComponent },
      { path: 'login', component: LoginComponent },
    ]
  },
  {
    // fallback routes (must be at the end)
    path: '',
    children: [
      { path: '**', component: DefaultComponent, canActivate: [AuthGuard] },
      { path: '**', component: LoginComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

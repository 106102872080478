import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from 'src/app/core/services/product.service';

@Component({
  selector: 'app-product-update-form',
  templateUrl: './product-update-form.component.html',
  styleUrls: ['./product-update-form.component.scss']
})
export class ProductUpdateFormComponent implements OnInit {

  updateForm!: FormGroup;
  public onlyReadInput =  true;
  constructor(private dialogRef: MatDialogRef<any>, private _snackBar: MatSnackBar , private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any, private productService: ProductService) { }

  ngOnInit(): void {
    this.updateForm = this._formBuilder.group({
      displayName:[this.data?.product?.displayName],
      hardware:[this.data?.product?.hardware]
    });
  }

  updateProduct(){
    let agency = {
      id: this.data.product?._id,
      displayName: this.updateForm.value.displayName,
      hardware: this.updateForm.value.hardware,
    }
    
    this.productService.updateProduct(agency).then((response) => {
      if (response.data.error == false){
        this._snackBar.open('Produit mis à jour avec succès', 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
        this.productService.triggerProductUpdated();
      }else{
        this._snackBar.open("Mise à jour de Produit a echoué", 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
      }
    }).catch(err => {
      this._snackBar.open("Mise à jour de Produit a echoué", 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    }).finally(()=>{
      //window.location.reload();
      this.productService.triggerProductUpdated();
      this.dialogRef.close();
    })
  }

  cancelUpdate(){
    this.dialogRef.close();
  }
}

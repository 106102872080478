<div class="col-xl-12" id="facturation">
    <br>
    <h1><mat-icon color="primary">attach_file</mat-icon>  Facturation </h1>
    <hr>
    <br>
    <div class="row">
        <div class="col-xl-1"></div>
        <div class='col-xl-8'>
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab label="Bon de commande">
                    <br>
                    <app-bdc [command]='command'></app-bdc>
                </mat-tab>
                <mat-tab label="Devis Fournisseur">Devis Fournisseur</mat-tab>
                <mat-tab label="Bon de réception">Bon de réception</mat-tab>
            </mat-tab-group>

        </div>
        <div class="col-xl-3">
            <table  class='recap-row'>
                <tr>
                    <td>Prix de vente : </td>
                    <td class='amount-price'>1500€</td>
                </tr>
                <tr>
                    <td>Montant initial : </td>
                    <td class='amount-price'>800€</td>
                </tr>
                <tr>
                    <td>Montant revisé : </td>
                    <td class='amount-price'>1200€</td>
                </tr>
                <tr>
                    <td>Réceptionné : </td>
                    <td class='amount-price'>1500€</td>
                </tr>
                <tr>
                    <td>Facturé : </td>
                    <td class='amount-price'>1500€</td>
                </tr>
            </table>
        </div>
    </div>


</div>
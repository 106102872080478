import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-facturation',
  templateUrl: './facturation.component.html',
  styleUrls: ['./facturation.component.scss']
})
export class FacturationComponent implements OnInit {

  @Input() command: any;
  constructor() { }

  ngOnInit(): void {
  }

}

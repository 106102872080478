import { Injectable } from '@angular/core';
import { TokenStorageService } from './token-storage.service';
import * as _ from 'lodash';
import { OrderService } from './order.service';
import { ComponentCallerService } from './component-caller.service';

const TransitionsComponents: any = {
    "EC_ADV -> EC_WAIT_PROVIDER": {
        MANDATORY_FIELDS: "DT_PLAN_PDF,DT_PLAN_ZIP,DF_PURCHASE_ORDER,ASSIGN_AGENCY,ASSIGN_SUPERVISOR,ASSIGN_EngineeringOfficer,ASSIGN_PROVIDER",
        OPTIONAL_FIELDS: "DT_OTHER_BE,DT_FCI_REF",
        RO_FIELDS: ""
    },
    "EC_WAIT_PROVIDER -> EC_PREPARE_RDV_VT": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: "DF_PURCHASE_ORDER"
    },
    "EC_PREPARE_RDV_VT -> EC_WAIT_RETAILER_RDV_VT": {
        MANDATORY_FIELDS: "VT_RDV_DATE",
        OPTIONAL_FIELDS: "VT_RDV_DESRIPTION",
        RO_FIELDS: ""
    },
    "EC_WAIT_RETAILER_RDV_VT -> EC_PREPARE_RDV_VT": {
        MANDATORY_FIELDS: "VT_RDV_STATUS",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_WAIT_RETAILER_RDV_VT -> EC_RDV_VT": {
        MANDATORY_FIELDS: "VT_RDV_STATUS",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_RDV_VT -> EC_VT_FAILED": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_RDV_VT -> EC_WAIT_COTATION_VALIDATION": {
        MANDATORY_FIELDS: "VT_DTI_VT,DF_QUOTATION",
        OPTIONAL_FIELDS: "VT_FOA,VT_OTHER_VT",
        RO_FIELDS: ""
    },
    "EC_RDV_VT -> EC_PREPARE_RDV_MES": {
        MANDATORY_FIELDS: "VT_DTI_VT",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_VT_FAILED -> EC_PREPARE_RDV_VT": {
        MANDATORY_FIELDS: "VT_FAILURE_REASON",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_WAIT_COTATION_VALIDATION -> EC_NEW_COTATION": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: "DF_QUOTATION"
    },
    "EC_WAIT_COTATION_VALIDATION -> EC_PREPARE_RDV_MES": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: "DF_QUOTATION"
    },
    "EC_PREPARE_RDV_MES -> EC_WAIT_RETAILER_RDV_MES": {
        MANDATORY_FIELDS: "RT_RDV_DATE",
        OPTIONAL_FIELDS: "VT_RDV_DESRIPTION",
        RO_FIELDS: ""
    },
    "EC_PREPARE_RDV_MES -> EC_WAIT_COLLECTIVITE": {
        MANDATORY_FIELDS: "RT_AC_REQUEST",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_NEW_COTATION -> EC_WAIT_COTATION_VALIDATION": {
        MANDATORY_FIELDS: "DF_QUOTATION",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_WAIT_COLLECTIVITE -> EC_PREPARE_RDV_MES": {
        MANDATORY_FIELDS: "RT_AC_RESPONSE",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_WAIT_RETAILER_RDV_MES -> EC_PREPARE_RDV_MES": {
        MANDATORY_FIELDS: "RT_RDV_STATE",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_WAIT_RETAILER_RDV_MES -> EC_RDV_MES": {
        MANDATORY_FIELDS: "RT_RDV_STATE",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_RDV_MES -> EC_MES_FAILED": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_RDV_MES -> EC_WAIT_MES_CONFIRMATION": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_MES_FAILED -> EC_PREPARE_RDV_MES": {
        MANDATORY_FIELDS: "RT_FAILURE_REASON",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_WAIT_MES_CONFIRMATION -> EC_RDV_MES": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_WAIT_MES_CONFIRMATION -> EC_DOE": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_DOE -> EC_DOE_VALIDATION": {
        MANDATORY_FIELDS: "DT_DOE,DT_DFT",
        OPTIONAL_FIELDS: "DT_FCI_REF",
        RO_FIELDS: ""
    },
    "EC_DOE_VALIDATION -> EC_DOE": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "EC_DOE_VALIDATION_INT -> EC_WAITING_DFT_VALIDATION": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "DT_FCI_REF",
        "RO_FIELDS": ""
    },
    "EC_WAITING_DFT_VALIDATION -> EC_DOE_VALIDATION_INT": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "DT_FCI_REF",
        RO_FIELDS: ""
    },
    "EC_DOE_INT -> EC_DOE_VALIDATION_INT": {
        MANDATORY_FIELDS: "DT_DOE",
        OPTIONAL_FIELDS: "DT_DFT",
        RO_FIELDS: ""
    },
    "EC_DOE_VALIDATION_INT -> CLOSED": {
        MANDATORY_FIELDS: "DT_DOE,DT_FCI_REF",
        OPTIONAL_FIELDS: "DT_DFT",
        RO_FIELDS: ""
    },
    "EC_DOE_VALIDATION -> CLOSING_BDR": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "CLOSING_BDR -> CLOSING_BDR_PDF": {
        MANDATORY_FIELDS: "DF_BDR",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "CLOSING_BDR_PDF -> CLOSING_BDR_VALIDATTION": {
        MANDATORY_FIELDS: "DF_BDR",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    },
    "CLOSING_BDR_VALIDATTION -> CLOSING_BILLING": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: "DF_BDR"
    },
    "CLOSING_BDR_VALIDATTION -> CLOSING_BDR": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: "DF_BDR"
    },
    "CLOSING_BILLING -> CLOSED": {
        MANDATORY_FIELDS: "",
        OPTIONAL_FIELDS: "",
        RO_FIELDS: ""
    }
}
@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    private transitionsCache: { [orderId: string]: Promise<any[]> } = {};

    constructor(private orderService: OrderService, private componentCallerService: ComponentCallerService,) {
    }



    hasPermission(current_user: any, permission: string): boolean {
        if (current_user?.permissions) {
            let permissions = current_user.permissions.map((p: any) => p.reference);
            return (permissions.includes(permission));
        }
        else return false;
    }

    async hasRole(current_user: any, assignedRole: string): Promise<boolean> {
        let hasRole = current_user?.role.includes(assignedRole);
        return Promise.resolve(hasRole);
    }

    async canShowComponent(component: string, orderId: string): Promise<boolean> {
        if (orderId && component) {
            if (!this.transitionsCache[orderId]) {
                // Lock access to this orderId to prevent concurrent API calls
                this.transitionsCache[orderId] = this.getAndCacheTransitions(orderId);
            }

            const transitions = await this.transitionsCache[orderId];
            if (transitions) {
                const components = this.getComponentsForTransitions(transitions);
                return components.includes(component);
            }
        }

        return false;
    }

    private async getAndCacheTransitions(orderId: string): Promise<any[]> {
        const transitions = await this.orderService.getAvailableTransitions(orderId);
        this.transitionsCache[orderId] = Promise.resolve(transitions); // Cache the result
        return transitions;
    }

    private getComponentsForTransitions(transitions: any[]): string[] {
        const components: string[] = [];

        for (let transition of transitions) {
            const componentsTransition = TransitionsComponents[transition?.reference as keyof any];
            if (componentsTransition) {
                components.push(...componentsTransition?.MANDATORY_FIELDS?.split(','));
                components.push(...componentsTransition?.OPTIONAL_FIELDS?.split(','));
            }
        }
        return components;
    }
   
}

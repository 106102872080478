<div class="col-xl-12">

    <button mat-stroked-button color="primary" (click)="addRow()">Ajouter un item</button> <br> <br>
    <div #content>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <td><label>Action</label></td>
                    <td><label>Item</label></td>
                    <td><label>Description</label></td>
                    <td><label>Qté</label></td>
                    <td><label>PU HT</label></td>
                    <td><label>Total HT</label></td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let dynamic of dynamicArray; let i = index;">
                    <td>
                        <mat-icon (click)="deleteRow(i)">delete</mat-icon>
                    </td>
                    <td>
                        <mat-form-field>
                            <input matInput style="width:100%;" name="{{dynamic.reference}}"
                                [(ngModel)]="dynamic.reference" type="text" placeholder="" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field>
                            <input matInput style="width:100%;" name="{{dynamic.description}}"
                                [(ngModel)]="dynamic.description" type="text" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field>
                            <input matInput style="width:100%;" name="{{dynamic.quantity}}"
                                [(ngModel)]="dynamic.quantity" type="text" />
                        </mat-form-field>

                    </td>
                    <td>
                        <mat-form-field>
                            <input matInput style="width:100%;" name="{{dynamic.unitPrice}}"
                                [(ngModel)]="dynamic.unitPrice" type="text" />
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field>
                            <input matInput readonly="true" style="width:100%;" name="{{dynamic.totalPrice}}"
                                [(ngModel)]="dynamic.totalPrice" type="text" [value]="calculateTotalPrice(dynamic)" />
                        </mat-form-field>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>



    <button class='btn btn-default btn-violet' (click)="getValues()">Générer Fichier PDF</button>
</div>
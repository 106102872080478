<div class="col-xl-12">
    <div class="row">
        <!-- General Div -->
        <ng-container
            *ngIf="this.permissionService.hasPermission(current_user, 'ORDER_VIEW_ID'); then generalDiv else generalHidden">
        </ng-container>

        <!-- Hidden General Div -->
        <ng-template #generalHidden>
            
        </ng-template>

        <!-- Visible General Div -->
        <ng-template #generalDiv>
            <div
                class="col-xl-{{ hiddenDivCount === 3 ? '12' : (hiddenDivCount === 2 ? '4' : (hiddenDivCount === 1 ? '4' :(hiddenDivCount === 0 ? '3' : '2' ))) }}">
                <div class="btnsecondary" (click)="showGeneralInfos()" [class.active]="activeOption === 'general'">
                    <span class="colxl12-text033 BodyMedium"><span>Général</span></span>
                </div>
            </div>
        </ng-template>

        <!-- Messages Div -->
        <ng-container
            *ngIf="this.permissionService.hasPermission(current_user, 'ORDER_VIEW_ID'); then messagesDiv else messagesHidden">
        </ng-container>

        <!-- Hidden Messages Div -->
        <ng-template #messagesHidden>
            
        </ng-template>

        <!-- Visible Messages Div -->
        <ng-template #messagesDiv>
            <div
                class="col-xl-{{ hiddenDivCount === 3 ? '12' : (hiddenDivCount === 2 ? '4' : (hiddenDivCount === 1 ? '4' :(hiddenDivCount === 0 ? '3' : '2' ))) }}">
                <div class="btnsecondary" (click)="showMessages()" [class.active]="activeOption === 'messages'">
                    <span class="colxl12-text035 BodyMedium">
                        <span>Messages</span>
                    </span>
                </div>
            </div>
        </ng-template>
        <!-- Facturation div-->
        <ng-container
            *ngIf="this.permissionService.hasPermission(current_user, 'FINANCIAL_CREATE'); then facturationDiv else facturationHidden">
        </ng-container>

        <!-- Hidden Facturation Div -->
        <ng-template #facturationHidden>
            
        </ng-template>

        <!-- Visible Facturation Div -->
        <ng-template #facturationDiv>
            <div
                class="col-xl-{{ hiddenDivCount === 3 ? '12' : (hiddenDivCount === 2 ? '4' : (hiddenDivCount === 1 ? '4' :(hiddenDivCount === 0 ? '2' : '3' ))) }}">
                <div class="btnsecondary" (click)="showFacturation()" [class.active]="activeOption === 'facturation'">
                    <span class="colxl12-text037 Icon12">
                        <mat-icon>lock</mat-icon>
                    </span>
                    <span class="colxl12-text039 BodyMedium">
                        <span>Facturation</span>
                    </span>
                </div>
            </div>
        </ng-template>
        <!-- Historique Div -->
        <ng-container
            *ngIf="this.permissionService.hasPermission(current_user, 'ORDER_ADMIN'); then historiqueDiv else historiqueHidden">
        </ng-container>

        <!-- Hidden Historique Div -->
        <ng-template #historiqueHidden>
            
        </ng-template>

        <!-- Visible Historique Div -->
        <ng-template #historiqueDiv>
            <div
                class="col-xl-{{hiddenDivCount === 3 ? '12' : (hiddenDivCount === 2 ? '4' : (hiddenDivCount === 1 ? '4' :(hiddenDivCount === 0 ? '2' : '3' ))) }}">
                <div class="btnsecondary" (click)="showHistorique()" [class.active]="activeOption === 'historique'">
                    <span class="colxl12-text041 BodyMedium">
                        <span>Historique</span>
                    </span>
                </div>
            </div>
        </ng-template>


        <ng-container
            *ngIf="this.permissionService.hasPermission(current_user, 'ORDER_VIEW_ID'); then followersDiv else followersHidden">
        </ng-container>

        <!-- Hidden Followers Div -->
        <ng-template #followersHidden>
            
        </ng-template>

        <!-- Visible Followers Div -->
        <ng-template #followersDiv>
            <div
                class="col-xl-{{ hiddenDivCount === 3 ? '12' : (hiddenDivCount === 2 ? '4' : (hiddenDivCount === 1 ? '4' :(hiddenDivCount === 0 ? '2' : '3' ))) }}">
                <div class="btnsecondary" (click)="showFollowers()" [class.active]="activeOption === 'followers'">
                    <span class="colxl12-text041 BodyMedium">
                        <span>Abonnés</span>
                    </span>
                </div>
            </div>
        </ng-template>
    </div>
</div>
<h1 mat-dialog-title>
    <span>Catégorie de statut - {{ statusCatgory?.statusCategory?.name}}</span>
    <span class="close">
        <mat-icon (click)="exit()">close</mat-icon>
    </span>
</h1>
<mat-dialog-content class="mat-typography">
    <table class="table">
        <tr class="alert alert-warning">
            <th>Réference</th>
            <th>Nom</th>
            <th>Déscription </th>
            <th>Rôle assigné </th>
        </tr>
        <tr *ngFor="let status of statusCatgory?.status" >
            <td>{{ status?.reference}}</td>
            <td>{{ status?.name }}</td>
            <td>{{ status?.description}}</td>
            <td>{{ status?.assignedRoleId?.name}}</td>
        </tr>
    </table>
</mat-dialog-content>


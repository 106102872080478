<!-- save-filter-dialog.component.html -->
<h2 mat-dialog-title>Enregistrer le filtre</h2>
<div mat-dialog-content>
    <div class="col-xl-9">
        <form [formGroup]="saveFilterForm">
            <mat-form-field class="input" appearance="fill">
            <input matInput placeholder="Nom de filtre" formControlName="name" required>
            </mat-form-field>
            <br>
            <mat-form-field class="input" appearance="fill">
            <textarea matInput placeholder="Description" formControlName="description" ></textarea>
            </mat-form-field>
            <div> Couleur de filtre : 
                <app-color-picker (colorSelected)="onColorSelected($event)"></app-color-picker>
              </div>
        </form>
        <code>
            <pre>{{ data?.filter |json}}</pre>
        </code>
       
    </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="saveFilter()">Enregistrer</button>
  <button mat-button (click)="cancel()">annuler</button>
</div>

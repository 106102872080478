<form [formGroup]="myForm" (ngSubmit)="submit()">
    <div class="form-group">
        <label for="file">Fichier</label>
        <input formControlName="file" id="file" type="file" class="form-control" (change)="onFileChange($event)">
        <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-info">
            <div *ngIf="f.file.errors && f.file.errors.required">File is required.</div>
        </div>
    </div>
    <div class="form-group">
        <mat-form-field >
            <mat-label>Catégorie</mat-label>
            <mat-select formControlName="category" [(ngModel)]="defaultCategory">
                <mat-option *ngFor="let category of documentCategories" [value]="category">{{category}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <button class="btn btn-primary" type="submit">Submit</button>
</form>
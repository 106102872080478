import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from 'src/app/core/services/order.service';
import { DocumentService } from 'src/app/core/services/document.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-file-validation-form',
  templateUrl: './file-validation-form.component.html',
  styleUrls: ['./file-validation-form.component.scss']
})
export class FileValidationFormComponent implements OnInit {

  myForm = new FormGroup({
    status: new FormControl('', [Validators.required])
  });
  current_user!: any;
  public defaultStatus!: string; 
  public documentStatus : any[] = []
  constructor(private documentService: DocumentService ,private orderService: OrderService, @Inject(MAT_DIALOG_DATA) public data: any, private toastr: ToastrService ,
    private dialogRef: MatDialogRef<FileValidationFormComponent>, public datepipe: DatePipe, private tokenService: TokenStorageService) { }

  ngOnInit(): void {
    this.fetchDocumentStatus();
    this.defaultStatus = this.data?.element?.status;
    
  }


  submit(){

    this.current_user = this.tokenService.getUser();
    const formData = new FormData();
    formData.append('documentId', this.data?.element?._id);
    formData.append('status', this.myForm?.value?.status ?? '');
    formData.append('userId', this.current_user?.userId);
    formData.append('orderId', this.data?.element?.orderId);
    this.documentService.updateDocument(formData).then(async response =>{
      this.toastr.success('Status de document modifié avec succès', 'Infos : ' ,{positionClass: 'toast-top-full-width'});
      this.dialogRef.close();
      this.orderService.triggerOrderUpdated();
      this.documentService.triggerDocumentUpdated();

    }).catch(err=>{
      this.toastr.error('Une Erreur est survenu lors de l\'import de fichier ', 'Infos : ' ,{positionClass: 'toast-top-center'})
    })
  }
  async fetchDocumentStatus() {
    try {
      this.documentStatus = [{ id: 'ACCEPTED' , name:'Accepté'}, { id: 'WAITING_VALIDATION' , name:'En attente de validation'} , { id: 'REJECTED' , name:'Rejeté'}];
      
    } catch (error) {
      console.error('Error fetching document status:', error);
    }
  }
}

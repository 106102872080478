<div class="rt">
    <h2 class='title'>
        <mat-icon color="primary">power</mat-icon> Raccordement technique
    </h2>
    <div class="col-xl-12 twoboxes">
        <div class="row">
            <div class="col-xl-12">
                <app-rdv-date-mes [command]='command'></app-rdv-date-mes>
            </div>
            <!--div class="col-xl-6">
            <app-state></app-state>
        </div>
        <div class="col-xl-6">
            <app-rdv-status-mes></app-rdv-status-mes>
        </div-->
        </div>
        <div class="row">
            <div class="col-xl-6">
                <app-document-manager [command]="command" documentType = "RT_AC_REQUEST"></app-document-manager>
            </div>
            <div class="col-xl-6">
                <app-document-manager [command]="command" documentType = "RT_AC_RESPONSE"></app-document-manager>
            </div>
        </div>
        <div class="row">

            <div class="col-xl-6">
                <app-document-manager [command]="command" documentType = "RT_MEASURES"></app-document-manager>
            </div>
        </div>
        <!--div class="row">
        <div class="col-xl-6">
            <app-rt-failure-reason></app-rt-failure-reason>
        </div>
    </div-->
    </div>
</div>
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from 'src/app/core/services/order.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { AssignmentFormComponent } from 'src/app/pages/private/order/components/general-infos/assignment/assignment-form/assignment-form.component';
import { PermissionService } from 'src/app/core/services/permission.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})
export class AssignmentComponent implements OnInit {

  @Input() command: any;
  current_user!: any;
  hasPermission = false;
  supervisorSelect: boolean = false;
  supervisors: any = [];
  engineeringofficers: any = [];
  providers: any = [];
  engineeringofficerSelect: boolean = false;
  providerSelect: boolean = false;
  companySelect: boolean = false;
  selectedSupervisor = '';
  selectedProvider = '';
  selectedEngineeringOfficer = '';
  command_id!: string;
  constructor(public permissionService: PermissionService,private tokenService: TokenStorageService, private userService: UserService, private orderService: OrderService,private modalDialog: MatDialog,
    private route: ActivatedRoute, private toastr: ToastrService, private router: Router, private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.current_user = this.tokenService.getUser();
    this.permissionService.hasPermission(this.current_user  , 'ORDER_ASSIGN');
  }
  
 
}

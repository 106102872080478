import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rt',
  templateUrl: './rt.component.html',
  styleUrls: ['./rt.component.scss']
})
export class RTComponent implements OnInit {
  @Input() command: any;
  constructor() { }

  ngOnInit(): void {
  }

}

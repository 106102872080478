// save-filter-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SavedFilterService } from 'src/app/core/services/saved-filter.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-save-filter-dialog',
  templateUrl: './save-filter-dialog.component.html',
})
export class SaveFilterDialogComponent {
  saveFilterForm: FormGroup;
  selectedColor = '#ffffff';

  constructor(
    private dialogRef: MatDialogRef<SaveFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private savedFilterService: SavedFilterService,
    private formBuilder: FormBuilder, private _snackBar: MatSnackBar
  ) {
    this.saveFilterForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
    });
  }

  saveFilter() {
    let data = {
      filters: this.data?.filter,
      userId: this.data?.userId,
      name: this.saveFilterForm.value.name,
      description: this.saveFilterForm.value.description,
      color: this.selectedColor
    }
    this.savedFilterService.saveFilter(data).then((response)  => {
      this._snackBar.open('nouveau filtre créé avec succès', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      this.savedFilterService.triggerFilterUpdated();
    }).catch(err => {
      this._snackBar.open('Erreur lors de la création de nouveau filtre', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      })
    }).finally(() => {
        this.savedFilterService.triggerFilterUpdated()
        this.cancel();
    });
  }

  cancel() {
    this.dialogRef.close();
  }


  onColorSelected(color: any) {
    this.selectedColor = color;
  }
}

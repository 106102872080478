<br>
<br>
<div class="row">
    <div class="col-sm">
        <div class="card">
            <div class="card-header">Information d'utilisateur</div>
            <div class="card-body" *ngIf="user">
                <table class="table table-stripped">
                    <th>Mon Manager</th>
                    <td>{{ user?.managerId?.contactId?.firstName}}  {{ user?.managerId?.contactId?.lastName}}</td>
                    <th>Mes rôles </th>
                    <td><div *ngFor="let role of user?.rolesIds"><li>{{ role?.name}}</li></div></td>
                </table>
                <form [formGroup]="updateForm">
                    <p>
                        <mat-form-field  class="inputText" appearance="fill">
                            <mat-label>Nom</mat-label>
                            <input matInput placeholder="Tocquaine" formControlName="contactLastName"  [value] ="user.contactId? user.contactId.lastName : ''" required>
                        </mat-form-field>
                        <mat-form-field class="inputText" appearance="fill">
                            <mat-label>Prénom</mat-label>
                            <input matInput placeholder="Frédéric" formControlName="contactFirstName"  [value] ="user.contactId? user.contactId.firstName : ''" required>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field class="inputText" appearance="fill">
                            <mat-label>E-mail</mat-label>
                            <input matInput placeholder="frederic@alphalink.fr" formControlName="contactEmailAddress"  [value] ="user.contactId? user.contactId.emailAddress : ''" required>
                        </mat-form-field>
                        <mat-form-field class="inputText" appearance="fill">
                            <mat-label>Identifiant</mat-label>
                            <input matInput placeholder="f.toquaine" formControlName="username"  [value] ="user.username" required>
                        </mat-form-field>
                    </p>
                    <p>
                    <mat-form-field class="inputText" appearance="fill">
                        <mat-label>Téléphone</mat-label>
                        <input matInput formControlName="contactPhone1" [value]="user.contactId? user.contactId.phone1 : '' " placeholder="0661737723"  minlength="10" maxlength="10" required >
                    </mat-form-field>
                    <mat-form-field  class="inputText" appearance="fill">
                        <mat-label>Téléphone</mat-label>
                        <input matInput formControlName="contactPhone2" [value]="user.contactId? user.contactId.phone2 : ''" placeholder="0661737723"  minlength="10" maxlength="10" required >
                    </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="fill" class="inputText">
                            <mat-label>Notifications d'information lié à l'équipe</mat-label>
                            <mat-select formControlName="emailCCAuto" required >
                                <mat-option [value]="null" selected>
                                    Sélectionner une des options dans la liste
                                </mat-option>
                                <mat-option *ngFor="let e of emailCCAuto" [value]="e?.id">
                                    {{e?.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="inputText">
                            <mat-label>Notifications d'information  en tant qu'abonné</mat-label>
                            <mat-select formControlName="emailFollower" required>
                                <mat-option [value]="null" selected>
                                    Sélectionner une des options dans la liste
                                </mat-option>
                                <mat-option *ngFor="let e of emailFollower" [value]="e?.id">
                                    {{e?.name}} 
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="fill" class="inputText">
                          <mat-label>Addresses Email CC </mat-label>
                          <mat-hint color='primary'>Cliquer sur la touche Entrer pour valider l'entrée</mat-hint>
                          <mat-chip-grid #chipListEmails formControlName="emailCCAddresses">
                            <mat-chip-row *ngFor="let email of emailAddresses" [removable]="true" (removed)="removeEmail(email)">
                              {{ email }}
                              <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                              </button>
                            </mat-chip-row>
                            <input placeholder="Ajouter des addresses mail"
                                   [matChipInputFor]="chipListEmails"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   (matChipInputTokenEnd)="addEmail($event)">
                          </mat-chip-grid>
                      </mat-form-field>
                </form>
                <button mat-button-raised (click)="updateProfile()"  class="btn btn-success">Valider</button>
                <form [formGroup]="updatePassword">
                    
                    <p>
                        <mat-form-field class="inputText" appearance="fill">
                            <mat-label>Ancien mot de passe</mat-label>
                            <input matInput formControlName="old_password" [type]="hide ? 'password' : 'text'" minlength="8" required >
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="inputText">
                            <mat-label>Nouveau mot de passe</mat-label>
                            <input matInput placeholder="" pattern="^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$" minlength="8" [type]="hide ? 'password' : 'text'" formControlName="new_password" required>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="inputText">
                            <mat-label>Confirmation de mot de passe</mat-label>
                            <input matInput placeholder=""  [type]="hide ? 'password' : 'text'" formControlName="new_password_confirmation" minlength="8" required>
                        </mat-form-field>
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                          </button>
                    </p>
                    <button mat-button-raised (click)="updateUserPassWord()"  class="btn btn-warning">Changer le mot de passe</button>
                </form>
            </div>
        </div>  
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from 'src/app/core/interfaces/user';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss']
})
export class HeaderV2Component implements OnInit {

  profile !: User;
  public isAuthenticated!: boolean;
  public notificationCounter!: number;
  isUserAdmin = false;
  isOrderAdmin = false;
  env = '';
  public refPrj: string = "993";
  constructor(private tokenService: TokenStorageService, private userService: UserService, private router: Router, private authService: AuthentificationService,
    private notificationService: NotificationService, public dialog: MatDialog, public permissionService: PermissionService) { }

  current_user: any = null;

  ngOnInit(): void {
    this.env = environment.name;
    this.current_user = this.tokenService.getUser();
    this.isUserAdmin = this.permissionService.hasPermission(this.current_user, 'USER_ADMIN') || this.permissionService.hasPermission(this.current_user, 'ROOT');
    this.isOrderAdmin = this.permissionService.hasPermission(this.current_user, 'ORDER_ADMIN');
    if(this.current_user.userId !== undefined){
      this.userService.getProfile(this.current_user.userId).then((response) =>{
        this.profile = response.data.data;
        this.isAuthenticated = true; // TO DO
        /*
          this.notificationService.getNotificationsCount(this.current_user.userId).then(response =>{
            this.notificationCounter = response.data.data
          }).catch(error =>{
            console.log(error);
          })
        */
      }).catch(error=>{
        console.error(error)
      })
    }
  }

  

  signout(event: Event){
    this.authService.logout(this.current_user).subscribe(
      data => {
        this.tokenService.signOut();
        this.router.navigate(['/login']);
      },
      err => {
        console.error(err)
      }
    );
    
    
  }
  signIn(event: Event){
    this.router.navigate(['/login']);
    
  }

  editProfile(event:Event){
    this.router.navigate(['user/edit_profile/'+ this.current_user.userId]);
  }

}

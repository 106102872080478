import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NewDocumentComponent } from 'src/app/shared/new-document/new-document.component';
import { StatusCategoriesModalComponent } from 'src/app/pages/private/admin/components/status-categories/status-categories-modal/status-categories-modal.component';
import { StatusModalComponent } from 'src/app/pages/private/admin/components/status/status-modal/status-modal.component';
import { TransitionModalComponent } from 'src/app/pages/private/admin/components/transitions/transition-modal/transition-modal.component';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { WorkflowDocumentsService } from 'src/app/core/services/workflow-documents.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

  current_user!: any;
  constructor(public dialog: MatDialog ,private toastr: ToastrService, private tokenService: TokenStorageService, private WorkflowDocumentsService: WorkflowDocumentsService) { }

  ngOnInit(): void {
    this.current_user = this.tokenService.getUser();
  }

  openStatusModal() {
    
    const dialogRef = this.dialog.open(StatusModalComponent, {
      width: '60%',
      height: '55%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  
  }

  openTransitionModal() {
    const dialogRef = this.dialog.open(TransitionModalComponent, {
      width: '60%',
      height: '55%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openStatusCategoriesModal() {
    const dialogRef = this.dialog.open(StatusCategoriesModalComponent, {
      width: '60%',
      height: '55%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  @ViewChild('fileInput') fileInput!: ElementRef;
  openWorkflowDocumentModal(){
    //this.fileInput?.nativeElement.click();
    const dialogRef = this.dialog.open(NewDocumentComponent, {
      width: '60%',
      height: '40%',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  
  handleFileInputChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('userId', this.current_user.userId);
      formData.append('category','RACCORDEMENT');
      this.WorkflowDocumentsService.addWorkflowDocument(formData).then(async response =>{
        this.toastr.success('Document importé avec succès', 'Infos : ' ,{positionClass: 'toast-top-full-width'});
        this.WorkflowDocumentsService.triggerWorkflowDocumentsUpdated();
      }).catch(err=>{
        this.toastr.error('Une Erreur est survenu lors de l\'import de fichier ', 'Infos : ' ,{positionClass: 'toast-top-center'})
      })
    }
  }
}

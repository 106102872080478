<div class="col-xl-12 header-v8-container">
    <div class="header-v8-header-v8">
        <div class="header-v8-header-v81">
            <div class="header-v8-container1">
                <div class="header-v8-logo3">
                    <div class="header-v8-frame100">
                        <img routerLink="/commandes" src="../../../../assets/images/mae.png" alt="MAE" class="logo">
                    </div>
                    <span class="header-v8-text"><span>MAE</span></span>
                </div>
                <div class="header-v8-nav-list">
                    <div class="header-v8-link">
                        <div class="header-v8-master-link">
                            <span class="header-v8-text02">
                                <mat-icon>bar_chart</mat-icon>
                                <a mat-list-item [routerLinkActive]="['list-item-active']"  *ngIf="profile"
                                routerLink="/dashboard">Dashboard</a>
                            </span>
                        </div>
                    </div>
                    <div class="header-v8-link1">
                        <div class="header-v8-master-link1">
                            <span class="header-v8-text02">
                                <mat-icon>list</mat-icon>
                                <a mat-list-item [routerLinkActive]="['list-item-active']"  *ngIf="profile"
                                routerLink="/orders">Commandes</a>
                            </span>
                        </div>
                    </div>
                    <div class="header-v8-link1">
                        <div class="header-v8-master-link1">
                            <span class="header-v8-text02">
                                <mat-icon>calendar_month</mat-icon>
                                <a mat-list-item [routerLinkActive]="['list-item-active']"  *ngIf="profile"
                                routerLink="/calendar"> Rendez-vous</a>
                            </span>
                        </div>
                    </div>
                    <div class="header-v8-link1">
                        <div class="header-v8-master-link1" *ngIf="isAuthenticated && isUserAdmin">
                            <span class="header-v8-text02"><mat-icon>admin_panel_settings</mat-icon>
                                <a mat-list-item 
                                routerLink="/admin" matTooltip="Administration">Administration</a>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="header-v8-navright">
                    <!--div class="header-v8-btniconrounded">
                        <div class="header-v8-frame103">
                            <span class="header-v8-text06 " (click)="openNotificationDialog()" *ngIf="isAuthenticated">
                                <mat-icon matBadge="{{notificationCounter}}" matBadgeColor="warn" matTooltip="Notifications">notifications</mat-icon>
                            </span>
                        </div>
                    </div-->
                    <!--div class="header-v8-btniconrounded1" *ngIf="env === 'dev'">
                        <div class="header-v8-frame1031" [matMenuTriggerFor]="helpMenu">
                            <span class="header-v8-text08 Icon16">
                                <mat-icon>tune</mat-icon>
                            </span>
                            <mat-menu #helpMenu="matMenu">
                                <div *ngFor="let p of current_user?.permissions">
                                    <li>{{ p?.reference}}</li>
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                    <div class="header-v8-btniconrounded1" *ngIf="env === 'dev'">
                        <div class="header-v8-frame1031" [matMenuTriggerFor]="helpMenu">
                            <span class="header-v8-text08 Icon16">
                                <mat-icon>settings</mat-icon>
                            </span>
                            <mat-menu #helpMenu="matMenu">
                                <div *ngFor="let role of current_user?.role">
                                    <li>{{ role}}</li>
                                </div>
                            </mat-menu>
                        </div>
                    </div-->
                    <div class="header-v8-btniconrounded2">
                        <div class="header-v8-frame1032" [matMenuTriggerFor]="menu">
                            <span class="header-v8-text10 " >
                                <mat-icon>person_outline</mat-icon>
                                {{current_user?.firstName}} {{current_user?.lastName}} 
                            </span>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="editProfile($event)">
                                    <mat-icon>edit</mat-icon>
                                    Modifier profil
                                </button>
                                <button mat-menu-item (click)="signout($event)">
                                    <mat-icon>exit_to_app</mat-icon>
                                    Se déconnecter
                                </button>
                            </mat-menu>
                        </div>
                    </div>

                    <div class="header-v8-frame103 bug-style">
                        <span class="header-v8-text06 " *ngIf="isAuthenticated">
                            <a href="https://easyredmine.alphalink.tech/projects/{{refPrj}}/issues/new" target="_blank"> <mat-icon matTooltip="Signaler un bug">bug_report</mat-icon></a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from 'src/app/core/services/order.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
import { Notification } from 'src/app/core/interfaces/notification';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { AgenceService } from 'src/app/core/services/agence.service';
import { CompanyService } from 'src/app/core/services/company.service';


@Component({
  selector: 'app-assignment-form',
  templateUrl: './assignment-form.component.html',
  styleUrls: ['./assignment-form.component.scss']
})
export class AssignmentFormComponent implements OnInit {
  supervisorSelect: boolean = false;
  subcontractors: any[] = [];
  supervisors: any = [];
  engineeringofficers: any = [];
  providers: any = [];
  agences: any[] = [];
  engineeringofficerSelect: boolean = false;
  providerSelect: boolean = false;
  companySelect: boolean = false;
  selectedSupervisor = '';
  selectedProvider = '';
  selectedEngineeringOfficer = '';
  selectedSubcontractor= '';
  selectedAgency = '';
  command_id!: string;
  current_user!: any;
  public field!: string;
  constructor(private userService: UserService, private tokenService: TokenStorageService,private orderService: OrderService, @Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<any>, private toastr: ToastrService, private notificationService: NotificationService, private agenceService: AgenceService ,  private companyService: CompanyService,) { }

  ngOnInit(): void {
    this.field = this.data.field;
    this.selectedSubcontractor = this.data?.command?.agencyId?.companyId?._id;
    this.selectedAgency = this.data?.command?.agencyId?._id;
    this.selectedSupervisor = this.data?.command?.supervisorId?._id;
    this.selectedEngineeringOfficer = this.data?.command?.engineeringOfficerId?._id;
    this.selectedProvider = this.data?.command?.providerId?._id;
    this.userService.getUsers().then((response) => {
      this.supervisors = response.data.data.filter((u: any) => {return u?.rolesIds?.filter((r: any) => r.reference == "RACCO_OPC_INTERNE").length});
      this.engineeringofficers = response.data.data.filter((u: any) => {return u?.rolesIds?.filter((r: any) => r.reference == "RACCO_BE_INTERNE").length});
      this.providers = response.data.data.filter((u: any) => {return u?.rolesIds?.filter((r: any) => r.reference == "RACCO_OPC_EXTERNE").length});
    })
    this.companyService.getCompanies().then((response) => {
      this.subcontractors = response.data.data.filter((item: any) => item.type === "SUBCONTRACTOR");
    });
    
    if (this.data?.command?.agencyId?.companyId?._id) {
      this.agenceService.getAgencesByCompany(this.data?.command?.agencyId?.companyId?._id).then((response) => {
        this.agences = response.data.data;
        this.userService.getUsersByAgency(this.data?.command?.agencyId?._id).then((response) => {
          this.providers = response.data.data.filter((u: any) => {return u?.rolesIds?.filter((r: any) => r.reference == "RACCO_OPC_EXTERNE").length});
        });
      }).catch(err => {
        console.log(err)
      });
    }
    else {
      this.agenceService.getAgences().then((response) => {
        this.agences = response.data.data;
      }).catch(err => {
        console.log(err)
      });
    }
  }

 
  cancelUpdate(){
    this.selectedSupervisor = '';
    this.selectedProvider = '';
    this.selectedEngineeringOfficer = '';
    this.dialogRef.close();
    
  }
  updateAssignation(field: any){
    this.current_user = this.tokenService.getUser();
    if (field === 'engineeringOfficer'){
      let recpientId = this.selectedEngineeringOfficer;
      this.orderService.UpdateEngineeringOfficer(this.data?.command?._id, this.current_user?.userId, this.selectedEngineeringOfficer).then((response) =>{
        if (response.data.error){
          this.toastr.error("Erreur de mise à jour de BE")
        }else
        {
          this.toastr.success("" , "Mise à jour de BE réussie", {
            positionClass: 'toast-top-center'
          });
          this.orderService.triggerOrderUpdated();
        }
      })
    }
    else if (field === 'provider'){
      let recpientId = this.selectedProvider;
      this.orderService.UpdateProvider(this.data?.command?._id,this.current_user?.userId, this.selectedProvider).then((response) =>{
        if (response.data.error){
          this.toastr.error("Erreur de mise à jour d'intervenant ")
        }else
        {
          
          this.toastr.success("", "Mise à jour de pilote EXT réussie", {
            positionClass: 'toast-top-center'
          });
          this.orderService.triggerOrderUpdated();
        }
      })
    }
    else if (field === 'supervisor') {
      let recpientId = this.selectedSupervisor;
      this.orderService.UpdateSupervisor(this.data?.command?._id,this.current_user?.userId, this.selectedSupervisor).then((response) =>{
        if (response.data.error){
          this.toastr.error("Erreur de mise à jour de responsable");
        }else
        {
          
          this.toastr.success('', "Mise à jour de pilote ADV réussie", {
            positionClass: 'toast-top-center'
          });
          this.orderService.triggerOrderUpdated();
        }
      })
    }
    else if (field === 'agency') {
      this.orderService.UpdateAgency(this.data?.command?._id,this.current_user?.userId, this.selectedAgency).then((response) =>{
        if (response.data.error){
          this.toastr.error("Erreur de mise à jour d'agence ");
        }
        else
        {
          this.toastr.success('', "Mise à jour d'agence réussie", {
            positionClass: 'toast-top-center'
          });
          this.orderService.triggerOrderUpdated();
        }
      })
    }
    else {
      this.orderService.triggerOrderUpdated();
    }
    this.cancelUpdate();

  }

  onChangeSubcontractor(id: any) {
    this.companyService.getCompany(id?.value).then((response) => {
      this.data.subcontractor = response.data.data.name;
      this.agenceService.getAgencesByCompany(response.data.data._id).then((response) => {
        this.agences = response.data.data;
      }).catch(err => {
        console.log(err)
      });
    });
  }


  sendNotification(recepientId : string){
    let notification = {
            message: "Une nouvelle commande (" + this.data?.command?._id  + ")  à traiter",
            orderId: this.data?.command?._id,
            recepientId: recepientId
          } as Notification;
    this.notificationService.addNewNotification(notification).catch(error=>{
      console.log(error)
    })
  }
}

<div class="row">
    <div class="col-xl-12">
        <mat-icon color="primary">group</mat-icon>
        <span class="title" ><span>Contacts</span></span>
    </div>
    <div class="col-xl-12">
        <div class="contactBox">
            <div class="Subtitlemedium topContact">Pilote ADV</div>
            <span class="colxl12-text128 Body">
                <span>
                    <div class="contactInfo">{{command?.supervisorId?.contactId?.firstName}} {{command?.supervisorId?.contactId?.lastName}} </div>
                    <div class="contactInfo">{{command?.supervisorId?.contactId?.phone1}}</div>
                    <div class="contactInfo">{{command?.supervisorId?.contactId?.emailAddress}}</div>
                </span>
            </span>
        </div>
        <div class="contactBox">
            <div class="Subtitlemedium">Pilote EXT</div>
            <span class="colxl12-text135 Body">
                <span>
                    <div class="contactInfo">{{command?.providerId?.contactId?.firstName}} {{command?.providerId?.contactId?.lastName}} </div>
                    <div class="contactInfo">{{command?.providerId?.contactId?.phone1}} </div>
                    <div class="contactInfo">{{command?.providerId?.contactId?.emailAddress}} </div>
                </span>
            </span>
        </div>
        <div class="contactBox">
            <div class="Subtitlemedium">Contact administratif</div>
            <span class="colxl12-text142 Body">
                <span>
                    <div class="contactInfo">{{command?.content?.clientContactId?.firstName}} {{command?.content?.clientContactId?.lastName}}</div>
                    <div class="contactInfo">{{command?.content?.clientContactId?.phone1}}</div>
                    <div class="contactInfo">{{command?.content?.clientContactId?.emailAddress}}</div>
                </span>
            </span>
        </div>
        <div class="contactBox">
            <div class="Subtitlemedium">Contact de site de livraison</div>
            <span class="colxl12-text149 Body">
                <span>
                    <div class="contactInfo">{{command?.content?.siteContactId?.firstName}} {{command?.content?.siteContactId?.lastName}}</div>
                    <div class="contactInfo">{{command?.content?.siteContactId?.phone1}} </div>
                    <div class="contactInfo">{{command?.content?.siteContactId?.emailAddress}}</div>
                </span>
            </span>
        </div>
    </div>
</div>
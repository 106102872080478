import { Component, OnInit } from '@angular/core';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { RdvService } from 'src/app/core/services/rdv.service';
import frLocale from '@fullcalendar/core/locales/fr';
import { MatDialog } from '@angular/material/dialog';
import { RdvViewComponent } from './components/rdv-view/rdv-view.component';
import { RdvViewModalComponent } from './components/rdv-view-modal/rdv-view-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FilterService } from 'src/app/core/services/filter.service';
import { AgenceService } from 'src/app/core/services/agence.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
@Component({
  selector: 'app-rdv-calendar',
  templateUrl: './rdv-calendar.component.html',
  styleUrls: ['./rdv-calendar.component.scss']
})
export class RdvCalendarComponent implements OnInit {

  filterRdvGP!: FormGroup;
  personalFilter!: boolean;
  currentUser!: any;
  calendarOptions: CalendarOptions = {};
  rdvEvents: any[] = [];
  subcontractor: any[] = [];
  subcontractorAgency: any[] = [];
  types: any[] = [];
  isAdmin: boolean = false;

  rdvStatusMapping: any[] = []
  constructor(private tokenService: TokenStorageService, private rdvService: RdvService, private agenceService: AgenceService, private filterService: FilterService, public dialog: MatDialog, private _formBuilder: FormBuilder) { }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.tokenService.getUser();
    this.isAdmin = this.currentUser?.role?.includes('RACCO_ADMIN');
    
    this.initForm();
    this.loadData();
    this.setupFormChangesListener();
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      weekends: true,
      events: this.rdvEvents,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      eventClick: this.eventClick.bind(this),
      locale: frLocale,
      eventContent: function(info) {
        var eventTitle = info.event.title;
        var eventElement = document.createElement('div');
        eventElement.innerHTML = '<span style="background-color: white;color:'+ info?.event?._def?.ui?.backgroundColor +';font-size:10px;"> <i class="material-icons" style="font-size:18px;">' +info?.event?._def?.ui?.classNames[0]+'</i>' + eventTitle +'</span>';
        return {
          domNodes: [eventElement]
        };
      }
    };
  }

  async loadData() {
    this.search(this.filterRdvGP.value).then((response: any) => {
      let rdvs = response?.data;
    
    this.rdvEvents = rdvs.map((event: any) => {
      let eventColor = '#0000FF'; // Default color
      let classNames =[];
      // Set colors based on event status
      switch (event.status) {
        case 'ACCEPTED':
          eventColor = 'green'; // Green color for SUCCESS status
          classNames[0] = 'done';
          break;
        case 'SUCCESS':
          eventColor = 'green'; // Green color for SUCCESS status
          classNames[0] = 'done_all';
          break;
        case 'REJECTED':
          eventColor = 'orange'; // Red color for REJECTED and CANCELED statuses
          classNames[0] = 'block'
          break;
        case 'CANCELED':
          eventColor = 'red'; // Red color for REJECTED and CANCELED statuses
          classNames[0] = 'auto_delete';
          break;
        case 'FAILED':
            eventColor = 'red'; // Red color for REJECTED and CANCELED statuses
            classNames[0] = 'warning';
            break;
        case 'WAITING_VALIDATION':
          eventColor = 'blue'; // Green color for SUCCESS status
          classNames[0] = 'hourglass_bottom';
          break;
        case 'SCHEDULED':
            eventColor = 'blue'; // Green color for SUCCESS status
            classNames[0] = 'hourglass_top';
            break;
        case 'DRAFT':
            eventColor = 'grey';
            classNames[0] = 'question_mark';
            break;
        case 'DELETED':
            eventColor = 'grey';
            classNames[0] = 'delete';
            break;
        default:
          break;
      }

      return {
        title: `[${event?.type}] ${event?.orderId?.agencyId?.companyId.name}
                 [${event?.orderId?.agencyId?.name}]`,
        start: `${event?.dateStart}`,
        end: `${event?.dateEnd}`,
        extendedProps: {
          rdvId: event?._id,
        },
        color: eventColor, // Assign the determined color
        classNames: classNames
      };
    });
    });
    
    

    this.types = [
      { id: 'VT', name: 'VT' }, { id: 'MES', name: 'MES' }
    ];
    this.rdvStatusMapping = [
      { name: 'DRAFT', icon: 'question_mark', color: 'grey', translate: "Brouillon" },
      { name: 'WAITING_VALIDATION', icon: 'hourglass_bottom', color: 'blue', translate: "En attente de validation" },
      { name: 'ACCEPTED', icon: 'done', color: 'green', translate: "Accepté par le partenaire" },
      { name: 'SCHEDULED', icon: 'hourglass_top', color: 'blue', translate: "Programmé (confirmé par l'intervenant" },
      { name: 'REJECTED', icon: 'block', color: 'orange', translate: "Rejetée" },
      { name: 'CANCELED', icon: 'auto_delete', color: 'red', translate: "Annulé" },
      { name: 'FAILED', icon: 'warning', color: 'red', translate: "En échec" },
      { name: 'SUCCESS', icon: 'done_all', color: 'green', translate: "Réalisé" }
    ];

    let subcontractorData = await this.agenceService.getSubcontractors();
    this.subcontractor = subcontractorData?.companies;
    this.subcontractorAgency = subcontractorData?.agencies;


  }

  eventClick(rdv: any) {
    const dialogRef = this.dialog.open(RdvViewModalComponent, {
      data: {
        rdvId: rdv?.event?.extendedProps?.rdvId,
      },
      width: '60%',
      height: '40%',
    });
  }

  initForm() {
    this.filterRdvGP = this._formBuilder.group({
      personalFilter: [this.isAdmin ?  'all' : 'myOrders' ],
      subcontractor: [''],
      subcontractorAgency: [''],
      statusCategory: [''],
      status: [['DRAFT', 'WAITING_VALIDATION', 'ACCEPTED','SCHEDULED', 'SUCCESS'] ],
      type: [''],
      opticalLoops: [''],
    });
    this.filterRdvGP.valueChanges;
  }

  clearForm() {
    this.initForm();
    this.loadData();
    this.setupFormChangesListener();
  }
  applyFilters() {
    const filters = this.filterRdvGP.value;
    this.filterService.setFilter(filters);

  }
  setupFormChangesListener(): void {
    this.filterRdvGP.valueChanges
      .pipe(
        debounceTime(300), // Debounce time to avoid excessive calls
        distinctUntilChanged(), // Emit only distinct values
        switchMap((formValues) => this.search(formValues)) // Call search function
      )
      .subscribe((searchResults) => {
        this.updateCalendarEvents(searchResults?.data);
      });
  }

  updateCalendarEvents(events: any[]): void {
    this.rdvEvents = events.map((event: any) => {
      let eventColor = '#0000FF'; // Default color
      let classNames =[];
      // Set colors based on event status
      switch (event.status) {
        case 'ACCEPTED':
          eventColor = 'green'; // Green color for SUCCESS status
          classNames[0] = 'done';
          break;
        case 'SUCCESS':
          eventColor = 'green'; // Green color for SUCCESS status
          classNames[0] = 'done_all';
          break;
        case 'REJECTED':
          eventColor = 'orange'; // Red color for REJECTED and CANCELED statuses
          classNames[0] = 'block'
          break;
        case 'CANCELED':
          eventColor = 'red'; // Red color for REJECTED and CANCELED statuses
          classNames[0] = 'auto_delete';
          break;
        case 'FAILED':
            eventColor = 'red'; // Red color for REJECTED and CANCELED statuses
            classNames[0] = 'warning';
            break;
        case 'WAITING_VALIDATION':
          eventColor = 'blue'; // Green color for SUCCESS status
          classNames[0] = 'hourglass_bottom';
          break;
        case 'SCHEDULED':
            eventColor = 'blue'; // Green color for SUCCESS status
            classNames[0] = 'hourglass_top';
            break;
        case 'DRAFT':
            eventColor = 'grey';
            classNames[0] = 'question_mark';
            break;
        case 'DELETED':
            eventColor = 'grey';
            classNames[0] = 'delete';
            break;
        default:
          break;
      }

      return {
        title: `[${event?.type}] ${event?.orderId?.agencyId?.companyId.name}
                 [${event?.orderId?.agencyId?.name}]`,
        start: `${event?.dateStart}`,
        end: `${event?.dateEnd}`,
        extendedProps: {
          rdvId: event?._id,
        },
        color: eventColor, // Assign the determined color
        classNames: classNames
      };
    });


    // Update the events in the calendarOptions
    this.calendarOptions.events = this.rdvEvents;
  }

  async onChangeSubcontractor(id: any) {
    this.subcontractorAgency = await this.agenceService.getAgenciesByCompany(id);
  }

  private search(formValues: any) {
    return this.rdvService.searchRdvs(formValues)
  }

  refreshForm() {
    const formValues = this.filterRdvGP.value;
  
    // Call the search function with the current form values
    this.search(formValues).then((response: any) => {
      const rdvs = response?.data;
      
      this.rdvEvents = rdvs.map(async (event: any) => {
        this.search(this.filterRdvGP.value).then((response: any) => {
          let rdvs = response?.data;
        
        this.rdvEvents = rdvs.map((event: any) => {
          let eventColor = '#0000FF'; // Default color
          let classNames =[];
          // Set colors based on event status
          switch (event.status) {
            case 'ACCEPTED':
              eventColor = 'green'; // Green color for SUCCESS status
              classNames[0] = 'done';
              break;
            case 'SUCCESS':
              eventColor = 'green'; // Green color for SUCCESS status
              classNames[0] = 'done_all';
              break;
            case 'REJECTED':
              eventColor = 'orange'; // Red color for REJECTED and CANCELED statuses
              classNames[0] = 'block'
              break;
            case 'CANCELED':
              eventColor = 'red'; // Red color for REJECTED and CANCELED statuses
              classNames[0] = 'auto_delete';
              break;
            case 'FAILED':
                eventColor = 'red'; // Red color for REJECTED and CANCELED statuses
                classNames[0] = 'warning';
                break;
            case 'WAITING_VALIDATION':
              eventColor = 'blue'; // Green color for SUCCESS status
              classNames[0] = 'hourglass_bottom';
              break;
            case 'SCHEDULED':
                eventColor = 'blue'; // Green color for SUCCESS status
                classNames[0] = 'hourglass_top';
                break;
            case 'DRAFT':
                eventColor = 'grey';
                classNames[0] = 'question_mark';
                break;
            case 'DELETED':
                eventColor = 'grey';
                classNames[0] = 'delete';
                break;
            default:
              break;
          }
    
          return {
            title: `[${event?.type}] ${event?.orderId?.agencyId?.companyId.name}
                     [${event?.orderId?.agencyId?.name}]`,
            start: `${event?.dateStart}`,
            end: `${event?.dateEnd}`,
            extendedProps: {
              rdvId: event?._id,
            },
            color: eventColor, // Assign the determined color
            classNames: classNames
          };
        });
        });
        
        
    
        this.types = [
          { id: 'VT', name: 'VT' }, { id: 'MES', name: 'MES' }
        ];
        this.rdvStatusMapping = [
          { name: 'DRAFT', icon: 'question_mark', color: 'grey', translate: "Brouillon" },
          { name: 'WAITING_VALIDATION', icon: 'hourglass_bottom', color: 'blue', translate: "En attente de validation" },
          { name: 'ACCEPTED', icon: 'done', color: 'green', translate: "Accepté par le partenaire" },
          { name: 'SCHEDULED', icon: 'hourglass_top', color: 'blue', translate: "Programmé (confirmé par l'intervenant" },
          { name: 'REJECTED', icon: 'block', color: 'orange', translate: "Rejetée" },
          { name: 'CANCELED', icon: 'auto_delete', color: 'red', translate: "Annulé" },
          { name: 'FAILED', icon: 'warning', color: 'red', translate: "En échec" },
          { name: 'SUCCESS', icon: 'done_all', color: 'green', translate: "Réalisé" }
        ];
    
        //let subcontractorData = await this.agenceService.getSubcontractors();
        //this.subcontractor = subcontractorData?.companies;
        //this.subcontractorAgency = subcontractorData?.agencies;
      });
  
      // Update the events in the calendarOptions
      this.calendarOptions.events = this.rdvEvents;
      this.filterRdvGP.setValue(formValues);
    });
  }
  
  isStatusChecked(status: string): boolean {
    const selectedStatus = this.filterRdvGP.get('status')?.value;
    return selectedStatus && selectedStatus.includes(status);
  }
  toggleStatus(status: string): void {
    const selectedStatus = this.filterRdvGP.get('status')?.value;
    if (!selectedStatus) {
      return;
    }
  
    const index = selectedStatus.indexOf(status);
    if (index === -1) {
      // Add the status to the array if not already present
      selectedStatus.push(status);
    } else {
      // Remove the status from the array if already present
      selectedStatus.splice(index, 1);
    }
  
    // Trigger form control value change
    this.filterRdvGP.get('status')?.setValue([...selectedStatus]);
  }
}

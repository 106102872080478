import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AssignmentFormComponent } from 'src/app/pages/private/order/components/general-infos/assignment/assignment-form/assignment-form.component';
import { OrderService } from 'src/app/core/services/order.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';


@Component({
  selector: 'app-assign-engineering-officer',
  templateUrl: './assign-engineering-officer.component.html',
  styleUrls: ['./assign-engineering-officer.component.scss' , '../assignment.component.scss']
})
export class AssignEngineeringOfficerComponent implements OnInit {

  @Input() command: any;
  command_id!: string;
  current_user!: any;
  hasPermission = false;
  constructor(public permissionService: PermissionService,private tokenService: TokenStorageService, private modalDialog: MatDialog , private route: ActivatedRoute ,private orderService: OrderService) { }

  async ngOnInit():  Promise<void>  {
    this.current_user = this.tokenService.getUser();
    this.permissionService.hasPermission(this.current_user  , 'ORDER_ADMIN');
    this.route.params.subscribe(params => {
      this.command_id = params['id'];
    })

    this.orderService.orderUpdated$.subscribe(()=>{
      this.loadOrder();
    })
  }
  openAssignmentForm(element: any){
    const dialogRef = this.modalDialog.open(AssignmentFormComponent, {
      data: {
        field: element,
        command: this.command
      },
      width: '60%',
      height: '60%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  async loadOrder() {
    this.command = await this.orderService.getOrder(this.command_id);
  }

}

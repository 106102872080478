import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { environment } from 'src/environments/environment';

const CONTACT_API = environment.back_url + '/contacts/';
const config = { withCredentials: true}
@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor() { }
  getContacts(){
    return axios.get(CONTACT_API, config)
  }

  addContact(contact: any){
    return axios.post(CONTACT_API, contact, config)
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PdfViewerComponent } from 'src/app/pages/private/order/components/general-infos/modals/pdf-viewer/pdf-viewer.component';
import { WorkflowDocumentsService } from 'src/app/core/services/workflow-documents.service';

@Component({
  selector: 'app-workflow-documents',
  templateUrl: './workflow-documents.component.html',
  styleUrls: ['./workflow-documents.component.scss']
})
export class WorkflowDocumentsComponent implements OnInit {

  displayedColumns: string[] = [ 'view', 'version', 'name', 'category', 'description', 'fileType' , 'user'];
  dataSource!: MatTableDataSource<any>;
  workflowDocuments: any = [];

  constructor(private worflowDocumentsService: WorkflowDocumentsService,  private modalDialog: MatDialog,) { }

  ngOnInit(): void {
    this.worflowDocumentsService.workflowDocumentsUpdated$.subscribe(() =>{
      this.loadData();
    })
    this.loadData();
  }

  async loadData() {
    this.workflowDocuments = await this.worflowDocumentsService.getWorkflowDocuments();
    this.dataSource = new MatTableDataSource<any>(this.workflowDocuments);
  }

  openPdfViewer(element: any) {
    const dialogRef = this.modalDialog.open(PdfViewerComponent, {
      data: {
        element: element,
        type: "workflow"
      },
      width: '90%',
      height: '90%',
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { PermissionService } from 'src/app/core/services/permission.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-general-infos',
  templateUrl: './general-infos.component.html',
  styleUrls: ['./general-infos.component.scss']
})
export class GeneralInfosComponent implements OnInit {
  @Input() command: any;

  current_user!: any;
  hasPermission = false;
  constructor(public permissionService: PermissionService,private tokenService: TokenStorageService) { }

  ngOnInit(): void {
    this.current_user = this.tokenService.getUser();
    this.permissionService.hasPermission(this.current_user  , 'ORDER_UPDATE');
  }

}

import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Document } from 'src/app/core/interfaces/document';
import { NewDocumentComponent } from 'src/app/shared/new-document/new-document.component';
import { FileValidationFormComponent } from 'src/app/pages/private/order/components/general-infos/modals/file-validation-form/file-validation-form.component';
import { PdfViewerComponent } from 'src/app/pages/private/order/components/general-infos/modals/pdf-viewer/pdf-viewer.component';
import { OrderService } from 'src/app/core/services/order.service';
import { DocumentService } from 'src/app/core/services/document.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-document-manager',
  templateUrl: './document-manager.component.html',
  styleUrl: './document-manager.component.scss'
})
export class DocumentManagerComponent {
  @Input() command: any;

  @Input() documentType!: string ;
  command_id!: string;
  current_user!: any;
  hasPermission = false;
  hasRole = false;
  isActive = false;
  documentTypes: Record<string, string>= {
    'DT_PLAN_PDF':                  'PLAN APS',
    'DT_PLAN_ZIP':                  'APS',
    'DT_OTHER_BE':                  'Autre BE',
    'DT_DOE':                       'DOE',
    'DT_DFT':                       'DFT',
    'VT_DTI_VT':                    'CRVT',
    'VT_FOA':                       'FOA',
    'VT_OTHER_VT':                  'Autre VT',
    'DF_QUOTATION':                 'Devis TS',
    'DF_PURCHASE_ORDER':            'BDC',
    'DF_BDR':                       'BDR',
    'RT_MEASURES':                  'Réflectométrie',
    'RT_AC_REQUEST':                'Demande AC',
    'RT_AC_RESPONSE':               'Réponse AC'
  }
  constructor(public permissionService: PermissionService, private tokenService: TokenStorageService, private documentService: DocumentService, private orderService: OrderService, private route: ActivatedRoute, private modalDialog: MatDialog, private toastr: ToastrService,
    public datepipe: DatePipe) { }

  //public files : any[] = []
  public documents: any[] = [];

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(params => {
      this.command_id = params['id'];
    })
    this.current_user = this.tokenService.getUser();
    this.hasPermission = this.permissionService.hasPermission(this.current_user, 'ORDER_UPDATE');
    this.hasRole = await this.permissionService.hasRole(this.current_user, this.command?.statusId?.assignedRoleId?.reference);
    this.isActive = await this.permissionService.canShowComponent(this.documentType!, this.command_id);
    if (this.hasPermission) {
      this.fetchDocument();
      this.orderService.orderUpdated$.subscribe(() => {
        this.loadOrder();
      })
      this.documentService.documentUpdated$.subscribe(() => {
        this.fetchDocument();
      })
    }

  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.command && changes.command.currentValue.statusId && changes.command.currentValue._id) {
      this.hasRole = await this.permissionService.hasRole(this.current_user, changes.command.currentValue.statusId?.assignedRoleId?.reference);
      // this.isActive = await this.permissionService.canShowComponent('DT_PLAN_PDF', changes.command.currentValue._id);
    }
  }

  async fetchDocument() {
    try {
      if (this.hasPermission) {
        let files = await this.documentService.getDocumentByOrder(this.command._id || this.command_id, [this.documentType!.slice(3)]);
        this.documents = files.filter((item: any) => item.category === this.documentType?.slice(3));
        this.documents.sort((a: Document, b: Document) => new Date(b.date).getTime() - new Date(a.date).getTime());
      }
    } catch (error) {
      //console.error('Error fetching document categories:', error);
      // Handle error appropriately
    }
  }

  openFileImportModal(category: string) {
    this.fileInput?.nativeElement.click();
    /*
    const dialogRef = this.modalDialog.open(NewDocumentComponent, {
      data: {
        command_id: this.command._id,
        category: category
      },
      width: '60%',
      height: '40%',
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
    */
  }

  
  @ViewChild('fileInput') fileInput!: ElementRef;
  handleFileInputChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if ( file.type !== 'application/pdf' && this.documentType!.slice(3) === 'PLAN_PDF') {
        this.clearFileSource(); // Reset the file input
        this.toastr.warning('', "Merci d'importer un fichier PDF pour les documents PLAN APS", {positionClass: 'toast-top-center'})
        return; // Exit the function
      }


      const formData = new FormData();
      formData.append('file', file);
      formData.append('userId', this.current_user.userId);
      formData.append('orderId', this.command?._id);
      formData.append('category', this.documentType!.slice(3));
      formData.append('status', 'WAITING_VALIDATION');
      this.documentService.addDocument(formData).then(async response =>{
        this.toastr.success('Document importé avec succès', 'Infos : ' ,{positionClass: 'toast-top-full-width'});
        this.orderService.triggerOrderUpdated();
        this.documentService.triggerDocumentUpdated();
      }).catch(err=>{
        this.toastr.error('Une Erreur est survenu lors de l\'import de fichier ', 'Infos : ' ,{positionClass: 'toast-top-center'})
      })
    }
  }
  clearFileSource() {
    this.fileInput.nativeElement.value = '';
  }

  openPdfViewer(element: any) {
    const dialogRef = this.modalDialog.open(PdfViewerComponent, {
      data: {
        element: element
      },
      width: '90%',
      height: '90%',
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  openFileValidationForm(element: any) {
    const dialogRef = this.modalDialog.open(FileValidationFormComponent, {
      data: {
        element: element
      },
      width: '40%',
      height: '40%',
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  
  downloadDocument(element: any) {
    this.documentService.DownloadDocument(element.orderId, element?._id).then(response => {
      saveAs(response, element.name);
    }).catch(err => {
      this.toastr.error('une Erreur est survenu lors de téléchargement de fichier ', 'Infos : ', { positionClass: 'toast-top-center' })
    })
  }

  translateDocumentType(documentType: string) {
    return this.documentTypes[documentType];
  }

  async loadOrder() {
    this.command = await this.orderService.getOrder(this.command_id);
  }
}

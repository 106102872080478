import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
const BACK_API = environment.back_url ;
const config = { withCredentials: true}
@Injectable({
  providedIn: 'root'
})
export class EscalationService {

  constructor() { }

  async getEscalation(escalationId: string){
    return (await axios.get(`${BACK_API}/escalations/${escalationId}`, config)).data.data;
  }

  async endEscalation(escalationId: string){
    return (await axios.put(`${BACK_API}/escalations/${escalationId}/end`, config)).data.data;
  }
}

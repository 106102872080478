<h1 mat-dialog-title><span>Liste des RDVs : {{data?.type}} </span></h1>
<mat-dialog-content class="mat-typography">
    <table class="table">
        <tr>
            <td>Date Debut de RDV</td>
            <td>Date Fin de RDV</td>
        </tr>
        <tr *ngFor="let rdv of rdvs" class="alert alert-warning">
            <td>
                {{datepipe.transform(rdv.dateStart, 'dd/MM/yyyy HH:mm')}}
            </td>
            <td>
                {{datepipe.transform(rdv.dateEnd, 'dd/MM/yyyy HH:mm')}}
            </td>
        </tr>
    </table>
</mat-dialog-content>
<br>
<mat-dialog-actions class="">
    <button mat-raised-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>
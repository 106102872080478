<div class="content" *ngIf="this.permissionService.hasPermission(current_user  , 'ORDER_ASSIGN');">
    <div class="row col-xl-12">
        <span matTooltip="Modifier l'intervenant" >Pilote EXT</span>
    </div>
    <div class="row col-xl-12">
        <mat-form-field class="providerName" appearance="fill" matTooltip="Modifier l'intervenant" (click)="openAssignmentForm('provider')">
            <mat-label>{{ command?.providerId?.contactId?.firstName}} {{ command?.providerId?.contactId?.lastName}}
            </mat-label>
            <input matInput placeholder="" readonly='true' />
        </mat-form-field>
    </div>
</div>
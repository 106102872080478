import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quotation-status',
  templateUrl: './quotation-status.component.html',
  styleUrls: ['./quotation-status.component.scss', '../df.component.scss']
})
export class QuotationStatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<h1 mat-dialog-title>
    <span>Rôle - Détails</span>
    <span class="close">
        <mat-icon (click)="exit()">close</mat-icon>
    </span>
</h1>
<div class="row">
    <table class="table bordered">
        <tr>
            <th>Nom :</th>
            <td> {{data?.role?.name}} </td>

        </tr>
        <tr>
            <th>Référence : </th>
            <td>
                {{data?.role?.reference}}
            </td>
        </tr>
        <tr>
            <th>Description : </th>
            <td>
                {{ data?.role?.description }}
            </td>
        </tr>
        <tr>
            <th>Catégories : </th>
            <td>

                <div *ngFor="let category of data?.role?.listCategories">
                    <li>{{category}}</li>
                </div>
            </td>
        </tr>
        <tr>
            <th>Accès aux commandes : </th>
            <td>
                {{data?.role?.viewOrder}}
            </td>
        </tr>

        <tr>
            <th>Permissions : </th>
            <td>

                <div *ngFor="let permission of permissions">
                    <li>{{permission?.reference}}</li>
                </div>

            </td>
        </tr>
        <tr>
            <th>Utilisateurs : </th>
            <td>
                <tr>
                    <th>Utilisateur </th>
                    <th>Entreprise - Agence</th>
                    <th>Username</th>
                    <th>Voir</th>
                </tr>
                <tr *ngFor="let user of users">
                    <td>{{ user?.contactId?.firstName }} {{ user?.contactId?.lastName }}</td>
                    <td >[ {{ user?.agencyId?.companyId?.name || '-' }}  ] {{ user?.agencyId?.name || '-' }}</td>
                    <td >{{ user?.username  }}</td>
                    <td >
                        <mat-icon routerLink="/user/edit/{{user?._id}}" (click)="exit()" >visibility</mat-icon>
                    </td>
                </tr>
            </td>
        </tr>
    </table>
</div>
import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const TRANSITIONSLOGS_API = environment.back_url + '/transitionsLogs/';
const TRANSITIONS_API = environment.back_url + '/transitions/';
const ORDERS_API = environment.back_url + '/orders/';
const config = { withCredentials: true}
@Injectable({
  providedIn: 'root'
})
export class TransitionsLogsService {

  private transitionsUpdatedSubject = new Subject<void>();
  transitionsUpdated$: Observable<void> = this.transitionsUpdatedSubject.asObservable();
  constructor() { }


  async getTransitionsLogs(orderId: string){
    return (await axios.get(`${ORDERS_API}${orderId}/transitionsLogs`, config)).data.data;
  }

  async getLastTransitionsLog(orderId: string){
    return (await axios.get(`${ORDERS_API}${orderId}/transitionsLogs/last`, config)).data.data;
  }  

  async getTransitions(){
    return (await axios.get(`${TRANSITIONS_API}`, config)).data.data;
  }
  addTransition(transition: any): Promise<any>{
    return  axios.post(TRANSITIONS_API, transition, config);
  }
  updateTransition(transition: any){
    return  axios.put(`${TRANSITIONS_API}${transition?.id}`, transition, config);
  }

  triggerTransitionsUpdated() {
    this.transitionsUpdatedSubject.next();
  }
}

import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { environment } from 'src/environments/environment';
const QUOTATION_API = environment.back_url + '/quotations/';
const config = { withCredentials: true}

@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  constructor() { }

  getQuotations(){
    return axios.get(QUOTATION_API, config)
  }

  addQuotation(site: any){
    return axios.post(QUOTATION_API, site, config)
  }

  getQuotation(id: string){
    return  axios.get(QUOTATION_API + id, config);
  }

}

<mat-form-field>
  <mat-label>Recherche</mat-label>
  <input matInput placeholder="...." (keyup)="applySearch($event)">
</mat-form-field>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions    </th>
    <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openAgencyModal(element?._id)">
                <mat-icon>assignment_ind</mat-icon>
                <span>Voir</span>
              </button>
            <button mat-menu-item (click)="openAgencyUpdateForm(element)">
              <mat-icon>edit</mat-icon>
              <span>Modifier</span>
            </button>
          </mat-menu>
    </td>
  </ng-container>
  <ng-container matColumnDef="company">
    <th mat-header-cell *matHeaderCellDef> Entreprise  </th>
    <td mat-cell *matCellDef="let element"> {{ element?.companyId?.name }} </td>
  </ng-container>
  <ng-container matColumnDef="nom">
    <th mat-header-cell *matHeaderCellDef> Nom </th>
    <td mat-cell *matCellDef="let element"> {{ element?.name }} </td>
  </ng-container>
  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef> Adresse </th>
    <td mat-cell *matCellDef="let element"> {{ element?.address?.streetNum }} {{ element?.address?.streetName }} {{ element?.address?.cityName }}  {{ element?.address?.cityZip }}</td>
  </ng-container>
  <ng-container matColumnDef="isDefault">
    <th mat-header-cell *matHeaderCellDef> Par défaut </th>
    <td mat-cell *matCellDef="let element"> 
      <span *ngIf="element?.isDefault"> <mat-icon color="primary">done</mat-icon></span>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleService } from 'src/app/core/services/role.service';
import { StatusService } from 'src/app/core/services/status.service';
import { TransitionsLogsService } from 'src/app/core/services/transitions-logs.service';

@Component({
  selector: 'app-transition-modal',
  templateUrl: './transition-modal.component.html',
  styleUrls: ['./transition-modal.component.scss']
})
export class TransitionModalComponent implements OnInit {

  transitionFG!: FormGroup;
  status: any[] = [];
  roles: any[] = [];
  public onlyReadInput =  true;
  constructor(private statusService: StatusService,
    private transitionService: TransitionsLogsService,
    private roleService: RoleService ,
    private _formBuilder: FormBuilder, 
    private dialogRef: MatDialogRef<any>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar ) { }

  async ngOnInit(): Promise<void> {
    this.transitionFG = this._formBuilder.group({
      reference: new FormControl(null, Validators.required),
      name: [null, Validators.required],
      description: [null],
      statusId: ['', Validators.required],
      nextStatusId: ['', Validators.required]
    });

    this.roles = await this.roleService.getRoles();
    this.status = await this.statusService.getStatus();
    this.status.sort((a: any, b: any) => a.name.localeCompare(b.name));
  }

  onChangeNextStatus(event: any) {
    this.updateReference();
  }

  onChangeStatus(event: any) {
    this.updateReference();
  }

  updateReference() {
    const statusName = this.transitionFG.value.statusId?.reference;
    const nextStatusName = this.transitionFG.value.nextStatusId?.reference;
    this.transitionFG.patchValue({
      reference: statusName && nextStatusName ? `${statusName} -> ${nextStatusName}` : null
    });
  }
  onSubmit(){
    let transition = {
      reference: this.transitionFG.value.reference,
      name: this.transitionFG.value.name,
      description: this.transitionFG.value.description,
      statusId: this.transitionFG.value.statusId?._id,
      nextStatusId: this.transitionFG.value.nextStatusId?._id
    }
    if (this.transitionFG.valid) {
      this.transitionService.addTransition(transition).then((response)  => {
        this._snackBar.open('nouvelle transition créée avec succès', 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }).catch(err => {
        this._snackBar.open('Erreur lors de la création de la nouvelle transition', 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        })
      }).finally(() => {
          this.transitionService.triggerTransitionsUpdated();
          this.exit();
      });
    }
  }

  exit(){
    this.dialogRef.close();
  }

}

<div class="order" *ngIf="hasAccess">
    <div class="col-xl-12 general">
        <app-command-infos [command]="command"></app-command-infos>
    </div>
    <br>
    <!-- Menu -->
    <div class="col-xl-12">
        <app-command-menu (componentChange)="changeComponent($event)"></app-command-menu>
    </div>
    
    <div class="col-xl-12 content">
        <ng-container *ngIf="activeComponent === 'GeneralInfos'" class='general-infos'>
            <app-general-infos [command]="command" ></app-general-infos>
        </ng-container>
        <ng-container *ngIf="activeComponent === 'Messages'">
            <app-messages [command]="command"></app-messages>
        </ng-container>
        <ng-container *ngIf="activeComponent === 'Facturation'">
            <app-facturation [command]="command"></app-facturation>
        </ng-container>
        <ng-container *ngIf="activeComponent === 'Historique'">
            <app-historique [command]="command"></app-historique>
        </ng-container>
        <ng-container *ngIf="activeComponent === 'Followers'">
            <app-followers [command]="command"></app-followers>
        </ng-container>
    </div>
</div>
<div class="not-available" *ngIf="!hasAccess">
    <app-not-available-page></app-not-available-page>
</div>
import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const WORFKLOW_API = environment.back_url + '/workflowDocuments/';
const config = { withCredentials: true}
@Injectable({
  providedIn: 'root'
})
export class WorkflowDocumentsService {

  private workflowDocumentsUpdatedSubject = new Subject<void>();
  workflowDocumentsUpdated$: Observable<void> = this.workflowDocumentsUpdatedSubject.asObservable();
  constructor() { }

  async getWorkflowDocuments(){
    return (await axios.get(`${WORFKLOW_API}`, config)).data.data;
  }
  addWorkflowDocument(workflowDocument: any): Promise<any>{
    return  axios.post(WORFKLOW_API, workflowDocument, config);
  }
  updateWorkflowDocument(workflowDocument: any){
    return  axios.put(`${WORFKLOW_API}${workflowDocument?.id}`, workflowDocument, config);
  }

  getLastWorkflow(){
    return `${WORFKLOW_API}last`;
  }
  getWorkflowUri(documentId: string) : string {
    return `${WORFKLOW_API}uploads/${documentId}`;
  }

  triggerWorkflowDocumentsUpdated() {
    this.workflowDocumentsUpdatedSubject.next();
  }
}

<mat-form-field>
  <mat-label>Recherche</mat-label>
  <input matInput placeholder="...." (keyup)="applySearch($event)">
</mat-form-field>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openCompanyInfo(element?._id)">
                <mat-icon>assignment_ind</mat-icon>
                <span>Voir</span>
              </button>
            <button mat-menu-item (click)="openCompanyUpdateForm(element)">
              <mat-icon>edit</mat-icon>
              <span>Modifier</span>
            </button>
          </mat-menu>
    </td>
  </ng-container>
  <ng-container matColumnDef="nom">
    <th mat-header-cell *matHeaderCellDef> Nom </th>
    <td mat-cell *matCellDef="let element"> {{element?.name}} </td>
  </ng-container>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef> Type </th>
    <td mat-cell *matCellDef="let element"> {{ getType(element?.type )}} </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
</table>

import { Injectable } from '@angular/core';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { environment } from 'src/environments/environment';
import { Mail } from 'src/app/core/interfaces/mail';

const MAILS_API: string = environment.back_url;
const config: { withCredentials: boolean } = { withCredentials: true };

@Injectable({ providedIn: 'root' })

export class MailService {
  /**
   * GET ORDER MAILS
   * Used to get mails by order id
   * @param {string} orderId
  **/
  async getAllMails(orderId: string): Promise<Mail[]> {
    return (await axios.get(`${MAILS_API}/orders/${orderId}/mails/all`, config)).data.data;
  };

  async getMailsByStatus(orderId: string, statusId: string){
    return (await axios.get(`${MAILS_API}/orders/${orderId}/mails/few/${statusId}`, config)).data.data;
  }

  /**
   * TOGGLE MESSAGE
   * Used to show or hide the body of a mail
   * @param {boolean} show
  **/
  toggleMessage(show: boolean): boolean {
    if (show) {
      return false;
    }
    else {
      return true;
    }
  };
};
/************************************************************************/
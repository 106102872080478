import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import {  DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RdvService } from 'src/app/core/services/rdv.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import 'moment/locale/fr';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';

@Component({
  selector: 'app-rdv-form',
  templateUrl: './rdv-form.component.html',
  styleUrls: ['./rdv-form.component.scss'],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class RdvFormComponent implements OnInit {

  hours: string[] = Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`);
  rdvForm!: FormGroup;
  current_user: any = null;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _formBuilder: FormBuilder,private rdvService: RdvService,
  private dialogRef: MatDialogRef<RdvFormComponent>, private tokenService: TokenStorageService, 
  ) {
   }

  ngOnInit(): void {
    this.current_user = this.tokenService.getUser();
    this.rdvForm = this._formBuilder.group({
      dateStart:['', Validators.required],
      startTime: ['09:00', Validators.required],
      endTime: ['16:00', [Validators.required, this.validateEndTime.bind(this)]]
    });
  }

  validateEndTime(control: AbstractControl): { [key: string]: boolean } | null {
    const startTime = this.rdvForm?.get('startTime')?.value;
    const endTime = control.value;

    if (startTime && endTime) {
      const startMinutes = Number(startTime.split(':')[0]) * 60 + Number(startTime.split(':')[1]);
      const endMinutes = Number(endTime.split(':')[0]) * 60 + Number(endTime.split(':')[1]);

      if (endMinutes <= startMinutes) {
        return { 'invalidEndTime': true };
      }
    }

    return null;
  }

  addRdv() {
    if (this.rdvForm.valid) {
      const dateStart = new Date(this.rdvForm.value.dateStart);
      const startTime = this.rdvForm.value.startTime.split(':');
      const endTime = this.rdvForm.value.endTime.split(':');

      dateStart.setHours(Number(startTime[0]), Number(startTime[1]));

      const dateEnd = new Date(this.rdvForm.value.dateStart);
      dateEnd.setHours(Number(endTime[0]), Number(endTime[1]));

      const rdv = {
        dateStart: dateStart,
        dateEnd: dateEnd,
        type: this.data?.type,
        reference: "", // TO DO
        orderId: this.data?.element,
        userId: this.current_user.userId
      };

      this.rdvService.addRdv(rdv).then(response => {
        if (response.data.data) {
          this.rdvService.triggerRdvUpdated();
          this.dialogRef.close();
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }

}

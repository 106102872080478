<div class='header'>
    <div class="row">
        <div class="col-xl-6">
            <span class="dashboardTitle">Dashboard</span>
        </div>
        <div class="col-xl-6">
            <div class="row">
                <div class="col-xl-3 raccordement" matTooltip="Cliquer pour visionner les commandes actives" (click)="navigateToCommandTable()" >
                    <div class="card border-0">
                        <div class="card-title">
                            <mat-icon class='south_east'>south_east</mat-icon> Raccordement
                            <br>
                            <div class="XY" >
                                <span matTooltip="Commandes actives "> {{ raccordementXY.actif }} </span> / <span
                                    matTooltip="Toutes les Commandes"> {{ raccordementXY.total }} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 maintenance">
                    <div class="card border-0">
                        <div class="card-title">
                            <mat-icon class='south_east'>south_east</mat-icon> Maintenance
                        </div>
                        <br>
                        <div class="XY">
                            <span matTooltip="Commandes actives "> XX </span> / <span
                                matTooltip="Toutes les Commandes"> YY </span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 devoiement">
                    <div class="card border-0">
                        <div class="card-title">
                            <mat-icon class='north_west'>north_west</mat-icon> Dévoiement
                        </div>
                        <br>
                        <div class="XY">
                            <span matTooltip="Commandes actives "> XX </span> / <span
                                matTooltip="Toutes les Commandes"> YY </span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 deploiement">
                    <div class="card border-0">
                        <div class="card-title">
                            <mat-icon class='north_west'>north_west</mat-icon> Déploiement
                        </div>
                        <br>
                        <div class="XY">
                            <span matTooltip="Commandes actives "> XX </span> / <span
                                matTooltip="Toutes les Commandes"> YY </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-xl-6">
            <mat-form-field>
                <mat-label>États</mat-label>
                <mat-select [formControl]="stateControl" multiple>

                    <mat-select-trigger>
                        <mat-chip-list>
                            <mat-chip *ngFor="let state of stateControl.value" [removable]="true"
                                (removed)="onStateRemoved(state)">
                                {{ state?.name }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                        </mat-chip-list>
                    </mat-select-trigger>

                    <mat-option *ngFor="let state of stateList" [value]="state">{{state?.name}}</mat-option>

                </mat-select>
            </mat-form-field>
            <app-widget-bystate [selectedStates]='stateControl.value'></app-widget-bystate>
        </div>
        <div class="col-xl-6">

        </div>
    </div>

</div>


<!--div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card border-0s">
    <mat-card border-0 fxFlex="25">
        <app-widget-card border-0 label="Traitements en Initialisation" total="12k" percentage="50" [data]="card border-0s"></app-widget-card border-0>
    </mat-card border-0>
    <mat-card border-0 fxFlex="25">
        <app-widget-card border-0 label="Traitements Plannifiés" total="10k" percentage="40" [data]="card border-0s"></app-widget-card border-0>
    </mat-card border-0>
    <mat-card border-0 fxFlex="25">
        <app-widget-card border-0 label="Traitements en Travaux" total="8k" percentage="30" [data]="card border-0s"></app-widget-card border-0>
    </mat-card border-0>
    <mat-card border-0 fxFlex="25">
        <app-widget-card border-0 label="Traitements en Raccordement" total="6k" percentage="20" [data]="card border-0s"></app-widget-card border-0>
    </mat-card border-0>
</div> <br>
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-card border-0s" >
    <mat-card border-0 fxFlex="25">
        <app-widget-card border-0 label="Traitements en Finalisation" total="6k" percentage="20" [data]="card border-0s"></app-widget-card border-0>
    </mat-card border-0>
    <mat-card border-0 fxFlex="50">
        <app-widget-card border-0 label="Traitements Livrés" total="6k" percentage="20" [data]="card border-0s"></app-widget-card border-0>
    </mat-card border-0>
    <mat-card border-0 fxFlex="25">
        <app-widget-card border-0 label="Traitements Annulés" total="6k" percentage="20" [data]="card border-0s"></app-widget-card border-0>
    </mat-card border-0>
</div-->

<br>

<mat-divider></mat-divider>

<br>



<br>
<br>
<br>
<div class="colxl12-boxmargin1">
    <div class="colxl12-box01">
        <div class="colxl12-row05">
            <div class="colxl12-colxl1203">
                <div class="colxl12-frame100000231601">
                    <div class="colxl12-activities-medal1">
                        <span class="colxl12-text043">
                            <mat-icon>location_on</mat-icon>
                        </span>
                    </div>
                </div>
                <div class="colxl12-title01">
                    <span class="colxl12-text045">
                        <span>Point de livraison</span>
                    </span>
                </div>
            </div>
        </div>
        <div class="colxl12-row06">
            <div class="colxl12-colxl6">
                <div class="colxl12-codeclient">
                    <span class="colxl12-text047 Subtitlemedium">
                        <span>N° cde EMA</span>
                    </span>
                    <span class="colxl12-text049 Body">
                        <span>{{ command?.content?.ema?.idProductOrderDetail }}</span>
                        <mat-icon  matTooltip="Cliquer pour copier le lien vers les commandes avec cette référence "  
                            (click)="copyReferenceToClipboard(command?.content?.ema?.idProductOrderDetail, 'ema')">content_copy</mat-icon>
                    </span>
                </div>
                <div class="colxl12-codeclient01">
                    <span class="colxl12-text051 Subtitlemedium">
                        <span>Matériel</span>
                    </span>
                    <span class="colxl12-text053 Body">
                        <span> {{ command.content?.productId?.hardware }}</span>
                    </span>
                </div>
                <div class="colxl12-codeclient">
                    <span class="colxl12-text047 Subtitlemedium">
                        <span>N° cde MAE</span>
                    </span>
                    <span class="colxl12-text049 Body">
                        <span>{{ command?.reference}}</span>
                        <mat-icon  matTooltip="Cliquer pour copier le lien vers cette commande "
                            (click)="copyReferenceToClipboard(command?.reference, 'mae')">content_copy</mat-icon>
                    </span>
                </div>
                <div class="colxl12-codeclient01">
                    <span class="colxl12-text051 Subtitlemedium">
                        <span>Partenaire</span>
                    </span>
                    <span class="colxl12-text053 Body">
                        <span>{{ command?.content?.clientId?.name }}</span>
                    </span>
                </div>
                <div class="colxl12-codeclient01">
                    <span class="colxl12-text051 Subtitlemedium">
                        <span>Site de livraison</span>
                    </span>
                    <span class="colxl12-text053 Body">
                        <span>{{ command?.content?.siteId?.name }}</span>
                    </span>
                </div>
                
                <div class="colxl12-codeclient02">
                    <span class="colxl12-text055 Subtitlemedium">
                        <span>Adresse de livraison</span>
                    </span>
                    <span class="colxl12-text057 Body">
                        <span>
                            <span>{{ command.content?.siteId?.address?.streetNum }} {{
                                command.content?.siteId?.address?.streetName }}
                            </span>
                            <br />
                            <span>{{ command.content?.siteId?.address?.cityZip }} {{
                                command.content?.siteId?.address?.cityName }} - France</span><br>
                               <span *ngIf="command.content?.siteId?.address?.floor"> Etage : {{ command.content?.siteId?.address?.floor }}</span> 
                        </span>
                    </span>
                </div>
            </div>
            <div class="colxl12-frame10004">
                <div class="colxl12-colxl61">
                    <div class="colxl12-codeclient03">
                        <span class="colxl12-text062 Subtitlemedium">
                            <span class="Subtitlemedium">Boucle : 
                                <span class="colxl12-text064 Body">{{ command?.content?.opticalLoop }}</span>
                            </span>
                        </span>
                        
                    </div>
                    <div class="colxl12-codeclient04">
                        <span class="colxl12-text068 Body">
                            <span>
                                <span>
                                    <span class="Subtitlemedium">GPS : </span>
                                    <span *ngIf="command.content?.siteId?.address?.coordinateX"><a target=”_blank”
                                        href="https://www.google.com/maps/dir//{{command.content?.siteId?.address?.coordinateY}},{{command.content?.siteId?.address?.coordinateX}}">
                                       {{command.content?.siteId?.address?.coordinateY?.slice(0, 8) }},{{command.content?.siteId?.address?.coordinateX?.slice(0, 7) }} </a>
                                        <mat-icon (click)="copyGPSToClipboard()" matTooltip="Cliquer pour copier les coordonnées GPS">content_copy</mat-icon>
                                        
                                    </span>
                                    
                                </span>
                                <br />
                                
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
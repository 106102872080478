<h1 mat-dialog-title><span>Entreprise - Mise à jour</span></h1>
<div class="col-xl-12 justify-content-center align-items-center">
    <div class="row">
         <mat-form-field appearance="fill" class="standard">
            <mat-label>{{data?.company.name}}</mat-label>
            <input matInput placeholder="{{data?.company.name}}" >
        </mat-form-field>
    </div>
   <div class="row">
    <mat-form-field appearance="fill" class="type">
        <mat-label>Sous traitant</mat-label>
        <mat-select name="type" [(ngModel)]="selectedCompanyType"  required>
            <mat-option *ngFor="let type of types" value="{{type.id}}">
                {{type.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
   </div>
   <div class="row">
        <td><button mat-raised-button color="primary" (click)="updateCompany()">Sauvegarder</button></td>
        <td><button mat-raised-button color="warn" (click)="cancelUpdate()">Annuler</button></td>
   </div>
    
</div>
import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { Data } from '@syncfusion/ej2-heatmap';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

const ORDER_API = environment.back_url + '/orders/raccordement/';
const config = { withCredentials: true}
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getOrdersByState(ids: any){
    return axios.post(`${ORDER_API}kpi/byState`, {ids:ids}, config)
  }

  async getOrdersByXY(){
    return (await axios.get(`${ORDER_API}kpi/XY`, config)).data.data;
  }

  cards() {
    return [71, 78, 39, 66];
  }

  
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DefaultComponent } from './default.component';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ToastrModule } from 'ngx-toastr';
import { MatChipsModule } from '@angular/material/chips';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { GeneralInfosComponent } from 'src/app/pages/private/order/components/general-infos/general-infos.component';
import { HeaderV2Component } from 'src/app/pages/private/components/header-v2/header-v2.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommandMenuComponent } from 'src/app/pages/private/order/components/command-menu/command-menu.component'
import { DeliverySiteComponent } from 'src/app/pages/private/order/components/general-infos/delivery-site/delivery-site.component';
import { StatusManagementComponent } from 'src/app/pages/private/order/components/general-infos/status-management/status-management.component';
import { ContactsComponent } from 'src/app/pages/private/order/components/general-infos/contacts/contacts.component';
import { LastMessageComponent } from 'src/app/pages/private/order/components/general-infos/last-message/last-message.component';
import { CommandInfosComponent } from 'src/app/pages/private/order/components/command-infos/command-infos.component';
import { CommandContainerComponent } from 'src/app/pages/private/order/command-container.component';
import { MessagesComponent } from 'src/app/pages/private/order/components/messages/messages.component';
import { FacturationComponent } from 'src/app/pages/private/order/components/facturation/facturation.component';
import { HistoriqueComponent } from 'src/app/pages/private/order/components/historique/historique.component';
import { FollowersComponent } from 'src/app/pages/private/order/components/followers/followers.component';
import { OrderRedirectComponentComponent } from 'src/app/pages/private/search/order-redirect-component.component';
import { DTComponent } from 'src/app/pages/private/order/components/general-infos/dt/dt.component';
import { DFComponent } from 'src/app/pages/private/order/components/general-infos/df/df.component';
import { VTComponent } from 'src/app/pages/private/order/components/general-infos/vt/vt.component';
import { RTComponent } from 'src/app/pages/private/order/components/general-infos/rt/rt.component';

import { AssignmentComponent } from 'src/app/pages/private/order/components/general-infos/assignment/assignment.component';

import { BDCComponent } from 'src/app/pages/private/order/components/facturation/bdc/bdc.component';

// DT Components
import { PlanPdfComponent } from 'src/app/pages/private/order/components/general-infos/dt/plan-pdf/plan-pdf.component';
import { PlanZipComponent } from 'src/app/pages/private/order/components/general-infos/dt/plan-zip/plan-zip.component';
import { OtherBeComponent } from 'src/app/pages/private/order/components/general-infos/dt/other-be/other-be.component';
import { DoeComponent } from 'src/app/pages/private/order/components/general-infos/dt/doe/doe.component';
import { DftComponent } from 'src/app/pages/private/order/components/general-infos/dt/dft/dft.component';
import { FciRefComponent } from 'src/app/pages/private/order/components/general-infos/dt/fci-ref/fci-ref.component';
// VT Components
import { RdvStatusComponent } from 'src/app/pages/private/order/components/general-infos/vt/rdv-status/rdv-status.component';
import { RdvDateComponent } from 'src/app/pages/private/order/components/general-infos/vt/rdv-date/rdv-date.component';
import { FailureReasonComponent } from 'src/app/pages/private/order/components/general-infos/vt/failure-reason/failure-reason.component';
import { DtiVtComponent } from 'src/app/pages/private/order/components/general-infos/vt/dti-vt/dti-vt.component';
import { FoaComponent } from 'src/app/pages/private/order/components/general-infos/vt/foa/foa.component';
import { OtherVtComponent } from 'src/app/pages/private/order/components/general-infos/vt/other-vt/other-vt.component';
import { RdvDescriptionComponent } from 'src/app/pages/private/order/components/general-infos/vt/rdv-description/rdv-description.component';
// DF Components
import { QuotationComponent } from 'src/app/pages/private/order/components/general-infos/df/quotation/quotation.component';
import { PurchaseOrderComponent } from 'src/app/pages/private/order/components/general-infos/df/purchase-order/purchase-order.component';
import { BdrComponent } from 'src/app/pages/private/order/components/general-infos/df/bdr/bdr.component';
import { QuotationStatusComponent } from 'src/app/pages/private/order/components/general-infos/df/quotation-status/quotation-status.component';
// RT Components
import { StateComponent } from 'src/app/pages/private/order/components/general-infos/rt/state/state.component';
import { MeasuresComponent } from 'src/app/pages/private/order/components/general-infos/rt/measures/measures.component';
import { AcRequestComponent } from 'src/app/pages/private/order/components/general-infos/rt/ac-request/ac-request.component';
import { AcResponseComponent } from 'src/app/pages/private/order/components/general-infos/rt/ac-response/ac-response.component';
import { RtFailureReasonComponent } from 'src/app/pages/private/order/components/general-infos/rt/rt-failure-reason/rt-failure-reason.component';
import { RdvDateMesComponent } from 'src/app/pages/private/order/components/general-infos/rt/rdv-date-mes/rdv-date-mes.component';
import { RdvStatusMesComponent } from 'src/app/pages/private/order/components/general-infos/rt/rdv-status-mes/rdv-status-mes.component';
import { AssignAgencyComponent } from 'src/app/pages/private/order/components/general-infos/assignment/assign-agency/assign-agency.component';
import { AssignSupervisorComponent } from 'src/app/pages/private/order/components/general-infos/assignment/assign-supervisor/assign-supervisor.component';
import { AssignEngineeringOfficerComponent } from 'src/app/pages/private/order/components/general-infos/assignment/assign-engineering-officer/assign-engineering-officer.component';
import { AssignProviderComponent } from 'src/app/pages/private/order/components/general-infos/assignment/assign-provider/assign-provider.component';
import { ForceStatusComponent } from 'src/app/pages/private/order/components/general-infos/assignment/force-status/force-status.component';

// Modals 
import { PdfViewerComponent } from 'src/app/pages/private/order/components/general-infos/modals/pdf-viewer/pdf-viewer.component';
import { AssignmentFormComponent } from 'src/app/pages/private/order/components/general-infos/assignment/assignment-form/assignment-form.component';
import { FileValidationFormComponent } from 'src/app/pages/private/order/components/general-infos/modals/file-validation-form/file-validation-form.component';
import { CompanyUpdateFormComponent } from 'src/app/pages/private/admin/entreprises/components/company-update-form/company-update-form.component';
import { AgenceModalComponent } from 'src/app/pages/private/admin/components/agences/agence-modal/agence-modal.component';
import { CompanyModalComponent } from 'src/app/pages/private/admin/entreprises/components/company-modal/company-modal.component';
import { RdvViewComponent } from 'src/app/pages/private/calendar/components/rdv-view/rdv-view.component';
import { RdvFormComponent } from 'src/app/pages/private/calendar/components/rdv-form/rdv-form.component';
import { RdvUpdateComponent } from 'src/app/pages/private/calendar/components/rdv-update/rdv-update.component';
import { RoleUpdateFormComponent } from 'src/app/pages/private/admin/components/roles/role-update-form/role-update-form.component';
import { RoleModalComponent } from 'src/app/pages/private/admin/components/roles/role-modal/role-modal.component';
import { AgencyUpdateFormComponent } from 'src/app/pages/private/admin/components/agences/agency-update-form/agency-update-form.component';
import { EscalationCancelConfirmDialgComponent } from 'src/app/pages/private/order/components/messages/escalation-cancel-confirm-dialg/escalation-cancel-confirm-dialg.component';
import { AddFollowerModalComponent } from 'src/app/pages/private/order/components/followers/add-follower-modal/add-follower-modal.component';
import { RdvViewModalComponent } from 'src/app/pages/private/calendar/components/rdv-view-modal/rdv-view-modal.component';
import { ProductUpdateFormComponent } from 'src/app/pages/private/admin/components/products/product-update-form/product-update-form.component';
import { ProductModalComponent } from 'src/app/pages/private/admin/components/products/product-modal/product-modal.component';
import { StatusModalComponent } from 'src/app/pages/private/admin/components/status/status-modal/status-modal.component';
import { StatusUpdateFormComponent } from 'src/app/pages/private/admin/components/status/status-update-form/status-update-form.component';
import { TransitionModalComponent } from 'src/app/pages/private/admin/components/transitions/transition-modal/transition-modal.component';
import { TransitionUpdateFormComponent } from 'src/app/pages/private/admin/components/transitions/transition-update-form/transition-update-form.component';
import { StatusCategoriesModalComponent } from 'src/app/pages/private/admin/components/status-categories/status-categories-modal/status-categories-modal.component';
import { StatusCategoriesUpdateFormComponent } from 'src/app/pages/private/admin/components/status-categories/status-categories-update-form/status-categories-update-form.component';
import { StatusCategoriesAssociatedFormComponent } from 'src/app/pages/private/admin/components/status-categories/status-categories-associated-form/status-categories-associated-form.component';
import { SaveFilterDialogComponent } from 'src/app/pages/private/orders/components/table-filters/save-filter-dialog/save-filter-dialog.component';

import { FullCalendarModule } from '@fullcalendar/angular'; // Import the FullCalendarModule
// Public
import { RdvChoiceComponent } from 'src/app/pages/public/order/rdvChoice/rdv-choice.component';
import { ConfirmationDialogComponent } from 'src/app/pages/public/order/rdvChoice/components/confirmation-dialog/confirmation-dialog.component';
import { NotAvailablePageComponent } from 'src/app/pages/public/not-available-page/not-available-page.component';
import { MatTableModule } from '@angular/material/table';
import { SafePipe } from 'src/app/core/pipes/safe.pipe';
import { LOCALE_ID } from '@angular/core';
import { ColorPickerComponent } from 'src/app/pages/private/orders/components/table-filters/save-filter-dialog/color-picker/color-picker.component';
import { AdministrationComponent } from 'src/app/pages/private/admin/administration.component';
import { AgencesComponent } from 'src/app/pages/private/admin/components/agences/agences.component';
import { ContactsAdministrationComponent } from 'src/app/pages/private/admin/components/contacts-administration/contacts-administration.component';
import { EntreprisesComponent } from 'src/app/pages/private/admin/entreprises/entreprises.component';
import { ProductsComponent } from 'src/app/pages/private/admin/components/products/products.component';
import { RolesComponent } from 'src/app/pages/private/admin/components/roles/roles.component';
import { StatusCategoriesComponent } from 'src/app/pages/private/admin/components/status-categories/status-categories.component';
import { StatusComponent } from 'src/app/pages/private/admin/components/status/status.component';
import { TransitionsComponent } from 'src/app/pages/private/admin/components/transitions/transitions.component';
import { EditProfileComponent } from 'src/app/pages/private/user/edit_profile/edit-profile.component';
import { EditUserComponent } from 'src/app/pages/private/admin/users/edit/edit-user.component';
import { UserComponent } from 'src/app/pages/private/admin/users/show/user.component';
import { UsersComponent } from 'src/app/pages/private/admin/users/users.component';
import { WorkflowDocumentsComponent } from 'src/app/pages/private/admin/components/workflow-documents/workflow-documents.component';
import { DashboardComponent } from 'src/app/pages/private/dashboard/dashboard.component'
import { ByStateComponent } from 'src/app/pages/private/dashboard/components/byState/bystate.component';
import { CommandTableComponent } from 'src/app/pages/private/orders/command-table.component';
import { TableFiltersComponent } from 'src/app/pages/private/orders/components/table-filters/table-filters.component';
import { RdvCalendarComponent } from 'src/app/pages/private/calendar/rdv-calendar.component';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ProductService } from 'src/app/core/services/product.service';
import { NewDocumentComponent } from '../../shared/new-document/new-document.component';
import { NewRoleComponent } from './admin/roles/new/new-role.component';
import { NewAgencyComponent } from './admin/agencies/new/new-agency.component';
import { NewentrepriseComponent } from './admin/entreprises/new/newentreprise.component';
import { NewCommandComponent } from './orders/new/newtreatment.component';
import { NewuserComponent } from './admin/users/new/newuser.component';
import { FooterComponent } from '../public/login/footer/footer.component';
import { HeaderComponent } from '../public/login/header/header.component';
import { LoginComponent } from '../public/login/login.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HighchartsChartModule } from 'highcharts-angular';
import { DocumentManagerComponent } from './order/components/general-infos/document-manager/document-manager.component';


@NgModule({
  declarations: [
    DefaultComponent,
    DashboardComponent,
    ByStateComponent,
    CommandTableComponent,
    TableFiltersComponent,
    AdministrationComponent,
    RdvCalendarComponent,
    UsersComponent,
    EntreprisesComponent,
    EditUserComponent,
    EditProfileComponent,
    UserComponent,
    AgencesComponent,
    ContactsAdministrationComponent,
    RolesComponent,
    ProductsComponent,
    StatusComponent,
    TransitionsComponent,
    StatusCategoriesComponent,
    WorkflowDocumentsComponent,
    SafePipe,
    HeaderV2Component,
    GeneralInfosComponent,
    CommandMenuComponent,
    DeliverySiteComponent,
    StatusManagementComponent,
    ContactsComponent,
    LastMessageComponent,
    CommandInfosComponent,
    CommandContainerComponent,
    OrderRedirectComponentComponent,
    MessagesComponent,
    FacturationComponent,
    HistoriqueComponent,
    FollowersComponent,
    DTComponent,
    DFComponent,
    VTComponent,
    RTComponent,
    AssignmentComponent,
    BDCComponent,
    DocumentManagerComponent,
    // DT
    PlanPdfComponent,
    PlanZipComponent,
    OtherBeComponent,
    DoeComponent,
    DftComponent,
    FciRefComponent,
    // VT
    RdvStatusComponent,
    RdvDateComponent,
    FailureReasonComponent,
    DtiVtComponent,
    FoaComponent,
    OtherVtComponent,
    RdvDescriptionComponent,
    // DF
    QuotationComponent,
    PurchaseOrderComponent,
    BdrComponent,
    QuotationStatusComponent,
    // RT
    StateComponent,
    RtFailureReasonComponent,
    RdvDateMesComponent,
    RdvStatusMesComponent,
    MeasuresComponent,
    AcRequestComponent,
    AcResponseComponent,
    // ASSIGN
    AssignAgencyComponent,
    AssignSupervisorComponent,
    AssignEngineeringOfficerComponent,
    AssignProviderComponent,
    ForceStatusComponent,
    // Modals
    PdfViewerComponent,
    AssignmentFormComponent,
    FileValidationFormComponent,
    CompanyUpdateFormComponent,
    AgenceModalComponent,
    CompanyModalComponent,
    RdvViewComponent,
    RdvFormComponent,
    RdvUpdateComponent,
    RoleUpdateFormComponent,
    RoleModalComponent,
    AgencyUpdateFormComponent,
    EscalationCancelConfirmDialgComponent,
    AddFollowerModalComponent,
    RdvViewModalComponent,
    ProductUpdateFormComponent,
    ProductModalComponent,
    StatusModalComponent,
    StatusUpdateFormComponent,
    TransitionModalComponent,
    TransitionUpdateFormComponent,
    StatusCategoriesModalComponent,
    StatusCategoriesUpdateFormComponent,
    StatusCategoriesAssociatedFormComponent,
    SaveFilterDialogComponent,
    // public
    RdvChoiceComponent,
    ConfirmationDialogComponent,
    ColorPickerComponent,
    FooterComponent,
    NewuserComponent,
    NewentrepriseComponent,
    NewCommandComponent,
    LoginComponent,
    NewAgencyComponent,
    NewDocumentComponent,
    HeaderComponent,
    NewRoleComponent,
    NotAvailablePageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatDividerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatTabsModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    MatChipsModule,
    MatGridListModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatSliderModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatToolbarModule,
    HighchartsChartModule,
    FullCalendarModule
  ],
  providers: [
    DashboardService,
    OrderService,
    ProductService,
    HeaderComponent,
    FooterComponent,
    NewuserComponent,
    { provide: LOCALE_ID, useValue: 'fr' },
    DatePipe,
    UsersComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DefaultModule { }
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RdvService } from 'src/app/core/services/rdv.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-rdv-description',
  templateUrl: './rdv-description.component.html',
  styleUrls: ['./rdv-description.component.scss']
})
export class RdvDescriptionComponent implements OnInit {
  @Input() command: any;
  description = '';
  rdvType: string = '';
  current_user!: any;
  constructor(private rdvService: RdvService , private tokenService: TokenStorageService) { }

  ngOnInit(): void {
    this.current_user = this.tokenService.getUser();
  }

  saveDescription(){
    if (this.command?.statusId?.reference === 'EC_PREPARE_RDV_VT') {
      this.rdvType = 'VT';
    }
    else {
      this.rdvType = 'MES';
    }
    this.rdvService.updateRdvsDescription( this.command?._id, this.current_user?.userId, this.rdvType, this.description).then(async response =>{
      this.rdvService.triggerRdvUpdated();
    }).catch(err => console.error(err))
  }

}

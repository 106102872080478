import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/pages/public/order/rdvChoice/components/confirmation-dialog/confirmation-dialog.component';
import { OrderService } from 'src/app/core/services/order.service';
import { RdvService } from 'src/app/core/services/rdv.service';

interface GroupedRdvs {
  date: string;
  slots: any[]; // Replace 'any' with the actual type of your slots
}

@Component({
  selector: 'app-rdv-choice',
  templateUrl: './rdv-choice.component.html',
  styleUrls: ['./rdv-choice.component.scss']
})
export class RdvChoiceComponent implements OnInit {

  sliderValues: number[] = [];
  private id!: string;
  type !: string;
  groupedRdvs: GroupedRdvs[] = [];
  description: string = "";
  selectedSlot: any | null = null;
  comment!: string;
  command !: any;
  acceptedRdv: any[] = [];

  constructor(private rdvService: RdvService, public datepipe: DatePipe, private modalDialog: MatDialog,
    private _Activatedroute: ActivatedRoute, private toastr: ToastrService, private orderService: OrderService) {
  }

  async ngOnInit(): Promise<void> {
    this._Activatedroute.paramMap.subscribe((params: any) => {
      this.id = String(params.get('id'));
      this.type = String(params.get('type'));
    });
  
    try {
      this.command = await this.orderService.getOrderPublic(this.id);
      let rdvs = await this.rdvService.getRdvsByOrderStatusPublic(this.id, 'WAITING_VALIDATION', this.type);
  
      if (rdvs.length > 0) {
        for (let element of rdvs) {
          if (element?.description) {
            this.description = element?.description;
            break;
          }
        }
  
        // Create a Map to group slots by date
        const groupedRdvsMap = new Map<string, any[]>();
        rdvs.forEach((rdv: any) => {
          const dateKey = this.datepipe.transform(rdv.dateStart, 'yyyy-MM-dd') || '';
          if (!groupedRdvsMap.has(dateKey)) {
            groupedRdvsMap.set(dateKey, []);
          }
          groupedRdvsMap.get(dateKey)?.push(rdv);
        });
  
        // Convert the Map to an array of GroupedRdvs objects
        this.groupedRdvs = Array.from(groupedRdvsMap).map(([date, slots]) => ({
          date,
          slots,
        }));
        // Sort the groupedRdvs array by dateStart
        this.groupedRdvs.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA.getTime() - dateB.getTime();
        });
      }
    } catch (error) {
      console.error('Error fetching WAITING_VALIDATION RDVs:');
    }
  
    try {
      this.acceptedRdv = await this.rdvService.getRdvsByOrderStatusPublic(this.id, 'ACCEPTED', this.type);
    } catch (error) {
      console.error('Error fetching ACCEPTED RDVs');
    }
  
    this.rdvService.rdvUpdated$.subscribe(() => {
      // Refresh your RDV data here
      this.loadData();
    });
  }
  

  selectSlot(rdv: any) {
    this.selectedSlot = rdv;
  }

  bookSlot(text: string) {
    if (this.selectedSlot) {
      this.rdvService.selectRdv(this.id, this.selectedSlot?._id, text)
        .then(() => {
          this.loadData();
        })
        .catch(error => {
          console.error('Error booking slot:', error);
          this.toastr.error('An error occurred while booking the slot.', 'Error');
        });
    } else {
      this.toastr.warning('Merci de choisir un créneau', 'Infos :', { positionClass: 'toast-top-right' });
    }
  }

  cancelSlots(comment: string) {
    const dialogRef = this.modalDialog.open(ConfirmationDialogComponent , {
      disableClose: false,
      data: {
        orderId: this.id,
        type: this.type,
        comment: comment
      },
      width: '40%',
      height: '30%',
    });


    dialogRef.afterClosed().subscribe((result: any) => {
      this.rdvService.triggerRdvUpdated();
    });
  }

  async loadData() {
    try {
      let rdvs = await this.rdvService.getRdvsByOrderStatusPublic(this.id, 'WAITING_VALIDATION', this.type);
  
      // Create a Map to group slots by date
      const groupedRdvsMap = new Map<string, any[]>();
      rdvs.forEach((rdv: any) => {
        const dateKey = this.datepipe.transform(rdv.dateStart, 'yyyy-MM-dd') || '';
        if (!groupedRdvsMap.has(dateKey)) {
          groupedRdvsMap.set(dateKey, []);
        }
        groupedRdvsMap.get(dateKey)?.push(rdv);
      });
  
      // Convert the Map to an array of GroupedRdvs objects
      this.groupedRdvs = Array.from(groupedRdvsMap).map(([date, slots]) => ({
        date,
        slots,
      }));
      // Sort the groupedRdvs array by dateStart
      this.groupedRdvs.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      });
    } catch (error) {
      console.error('Error fetching WAITING_VALIDATION RDVs:');
    }
  
    try {
      this.acceptedRdv = await this.rdvService.getRdvsByOrderStatusPublic(this.id, 'ACCEPTED', this.type);
    } catch (error) {
      console.error('Error fetching ACCEPTED RDVs ');
    }
  }
  

}

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { Status } from 'src/app/core/interfaces/status';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { StatusService } from 'src/app/core/services/status.service';


@Component({
  selector: 'app-widget-bystate',
  templateUrl: './bystate.component.html',
  styleUrls: ['./bystate.component.scss']
})
export class ByStateComponent implements OnInit, OnChanges {

  @Input() selectedStates: any = [];
  chartOptions: any = {};
  data: any = [];
  Highcharts = Highcharts;
  stateList: any;

  constructor(private dashboardService: DashboardService, private statusService: StatusService) { 
    
  }

  async ngOnInit() {
    this.chartOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Traitements par états'
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        crosshair: true
      },

      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0
        }
      },
      tooltip: {
        split: true,
        valueSuffix: ' traitements'
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: true,
      }
    };
    this.stateList = await this.statusService.getStatus();
    
    this.dashboardService.getOrdersByState(this.stateList.map(  (state: { _id: any; }) => state._id)).then(response =>{
      this.data =  this.CountByState(response.data.data);
      this.chartOptions.series = [];
      this.chartOptions.xAxis.categories = [];

      for (let i = 0;   i < this.data.length ; i++ ){
        this.chartOptions.xAxis.categories.push(this.data[i].name)
        this.chartOptions.series.push({
          data : [this.data[i].count],
          name : this.data[i].name
        })
      }
    
      Highcharts.chart('container', this.chartOptions);
    }).catch( error =>{
      console.log(error)
    }) 
  

    //HC_exporting(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

  async ngOnChanges(changes: any) {

    let states = []
    console.log(changes.selectedStates.currentValue)
    if( changes.selectedStates.currentValue.length) {
      states = changes.selectedStates.currentValue.map((s: Status) => s._id);
    }
    else {
      this.stateList = await this.statusService.getStatus();
      states = this.stateList.map(  (state: { _id: any; }) => state._id);
    }
    
    this.dashboardService.getOrdersByState(states).then(response =>{
      this.data =  this.CountByState(response.data.data);
      this.chartOptions.series = [];
      this.chartOptions.xAxis.categories = [];

      for (let i = 0;   i < this.data.length ; i++ ){
        this.chartOptions.xAxis.categories.push(this.data[i].name)
        this.chartOptions.series.push({
          data : [this.data[i].count],
          name : this.data[i].name
        })
      }
    
      Highcharts.chart('container', this.chartOptions);
    }).catch( error =>{
      console.log(error)
    }) 
  }

  CountByState(data : any){
    let counts  =   data.reduce((p: any, c:any) => {
      var name = c.statusId.name;
      if (!p.hasOwnProperty(name)) {
        p[name] = 0;
      }
      p[name]++;
      return p;
    }, {});
    return Object.keys(counts).map(k => {
      return {name: k, count: counts[k]}; });
  }

}

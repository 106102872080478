import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { environment } from 'src/environments/environment';
const COMPANY_API = environment.back_url + '/companies/';
const config = { withCredentials: true}
@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor() { }
  getCompanies(){
    return axios.get(COMPANY_API , config)
  }
  getCompany(id: string){
    return axios.get(COMPANY_API + id, config);
  }

  async getSubcontractors(){
    return (await axios.get(`${COMPANY_API}subcontractors`, config)).data.data;
  }

  addCompany(company: any){
    return  axios.post(COMPANY_API, company, config);
  }
  updateCompanyType(id: string , data: any){
    return  axios.put(COMPANY_API + 'type/' + id, data, config);
  }
}

import { Injectable } from '@angular/core';

import axios from 'axios';
import { Notification } from 'src/app/core/interfaces/notification';
import { environment } from 'src/environments/environment';
const NotificationAPI = environment.back_url + '/notifications/';
const config = { withCredentials: true}
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }
  getNotifications(){
    return  axios.get(NotificationAPI);
  }

  
  getNotificationsByOrderId(id: string){
    return  axios.get(NotificationAPI + 'order/' + id , config);
  }
  getNotificationByRecepientId(id: string){

    return  axios.get(NotificationAPI +  id, config);
  }

  getNotificationsCount(id: string){
    axios.defaults.withCredentials = true;
    return  axios.get(NotificationAPI + id + '/counter',  config);
  }

  addNewNotification(notification: Notification){
    return  axios.post(NotificationAPI, notification, config);
  }

  notificationSeen(notification: Notification){
    return axios.put(NotificationAPI , {id: notification._id } , config)
  }
  
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { RoleService } from 'src/app/core/services/role.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AgenceService } from 'src/app/core/services/agence.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';


@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  updateForm!: FormGroup;
  user: any = {};
  roles: any = []
  managers: any = []
  agencies: any = []
  companies: any[] = [];
  hide = true;
  isManager = false;
  emailAddresses: string[] = [];
	emailCCAuto: any = [];
	emailFollower: any = []
  updatePassword!: FormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(public datepipe: DatePipe, private _snackBar: MatSnackBar , private userService: UserService, private _Activatedroute: ActivatedRoute, private toastrService: ToastrService,
    private roleService: RoleService, private companyService: CompanyService, private _formBuilder: FormBuilder, private router: Router, private agenceService: AgenceService) { 

      this.updatePassword = this._formBuilder.group({
        old_password: ['', Validators.required],
        new_password: ['', [
          Validators.required,
          Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
        ]],
        new_password_confirmation: ['', [
          Validators.required,
          Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
        ]]
  
      });
    }

  async ngOnInit(): Promise<void> {
    let id = ""
    this._Activatedroute.paramMap.subscribe(params => {
      id = String(params.get('id'));
    });
    this.updateForm = this._formBuilder.group({
      contactLastName: ['', Validators.required],
      contactFirstName: ['', Validators.required],
      contactEmailAddress: ['', Validators.required],
      username: ['', Validators.required],
      roles: ['', Validators.required],
      manager: ['', Validators.required],
      company: [''],
      agency: ['', Validators.required],
      isActive: ['', Validators.required],
      contactPhone1: ['', Validators.required],
      contactPhone2: ['', Validators.required],
      emailCCAddresses:  [''],
      emailCCAuto: [''],
      emailFollower:  [''],

    });
    this.user = await  this.userService.getUser(id);
    this.emailAddresses = this.user?.emailCCAddresses || [];
    this.updateForm.patchValue({ company: this.user.agencyId?.companyId })
    this.updateForm.patchValue({ agency: this.user?.agencyId ? this.user.agencyId._id : null })
    this.updateForm.patchValue({ roles: this.user?.rolesIds?.map((role: { _id: any; }) => role._id) })
    this.updateForm.patchValue({ manager: this.user.managerId ? this.user.managerId._id : null })
    this.updateForm.patchValue({ contactLastName: this.user.contactId ? this.user.contactId.lastName : null })
    this.updateForm.patchValue({ contactFirstName: this.user.contactId ? this.user.contactId.firstName : null })
    this.updateForm.patchValue({ contactEmailAddress: this.user.contactId ? this.user.contactId.emailAddress : null })
    this.updateForm.patchValue({ contactPhone1: this.user.contactId ? this.user.contactId.phone1 : null })
    this.updateForm.patchValue({ contactPhone2: this.user.contactId ? this.user.contactId.phone2 : null })
    this.updateForm.patchValue({ isActive: this.user.isActive })
    this.updateForm.patchValue({ username: this.user.username })
    this.updateForm.patchValue({ emailCCAddresses: this.emailAddresses });
    this.updateForm.patchValue({ emailCCAuto: this.user.emailCCAuto });
    this.updateForm.patchValue({ emailFollower: this.user.emailFollower });

    this.updatePassword = this._formBuilder.group({
      old_password: ['', Validators.required],
      new_password: ['', [
        Validators.required,
        Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
      ]],
      new_password_confirmation: ['', [
        Validators.required,
        Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
      ]]

    });


    //
    this.roles = await this.roleService.getRoles()

    this.managers = await this.userService.getColleagues(this.user?.agencyId?.companyId);
    this.managers = await this.managers.filter((u: any) => { return u._id !== this.user?._id?._id})
    this.isManager = this.managers.some((m: any) => m._id === this.user?._id?._id)
    this.companyService.getCompanies().then((response) => {
      this.companies = response.data.data.filter(
        (item: any) => item.type === "SUBCONTRACTOR"
      );
    });

    this.emailCCAuto = [
      {id: 'NO' , name: 'Je ne souhaite pas être en copie de mails'},
      {id: 'TEAM' , name: 'Je souhaite être en copie des mails envoyé à mon équipe'}, 
      {id: 'AGENCY' , name: 'Je souhaite être en copie des mails envoyé aux utilisateurs de mon agence'} , 
      {id: 'COMPANY' , name: 'Je souhaite être en copie des mails envoyé aux utilisateurs de mon entreprise'}
    ]
    this.emailFollower = [
      {id: 'NO' , name: "Ne pas m'envoyer d'email, meme lorsque je suis follower d'une commande"},
      {id: 'ACTIVE_ESCALATION_ONLY' , name: "M'envoyer un email, seulement lorsque je suis follower d'une commande et qu'elle est en escalade (recommandé)"}, 
      {id: 'SINCE_ESCALATION' , name: "M'envoyer un email, seulement lorsque je suis follower d'une commande et qu'elle a été en escalade"} , 
      {id: 'ALLWAYS' , name: "M'envoyer un email, seulement lorsque je suis follower d'une commande, peu importe son état d'escalade. (déconseillé)"}
    ]

  }

  updateUser() {
    let data = {
      lastName: this.updateForm.value.contactLastName,
      firstName: this.updateForm.value.contactFirstName,
      emailAddress: this.updateForm.value.contactEmailAddress,
      username: this.updateForm.value.username,
      rolesIds: this.updateForm.value.roles,
      managerId: this.updateForm.value.manager,
      agencyId: this.updateForm.value.agency,
      isActive: this.updateForm.value.isActive,
      phone1: this.updateForm.value.contactPhone1,
      phone2: this.updateForm.value.contactPhone2,
      emailCCAddresses:  this.emailAddresses,
      emailCCAuto:  this.updateForm.value.emailCCAuto,
      emailFollower:  this.updateForm.value.emailFollower,
      type: 'USERS'
    }

    this.userService.updateUser((this.user?._id?._id || this.user?._id), data).then((response) => {
      if (!response.data.error) {
        this.toastrService.success('', 'Informations d\'tilisateur mises à jour avec succès !', { positionClass: 'toast-top-center' })
        this.router.navigate(['/admin/users/show/' + response.data.data.user._id])
      } else {
        this.toastrService.error('', 'Erreur est surevnue lors de la mise jour d\'utilisateur', { positionClass: 'toast-top-center' })
      }
    })
  }

  addEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      // Regular expression for email validation
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
      if (emailPattern.test(value)) {
        this.emailAddresses.push(value);
        this.updateForm.get('emailCCAddresses')?.setValue(this.emailAddresses);
        event.chipInput!.clear();
      } else {
        this._snackBar.open("Le format de mail n'est pas bon ", 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
      }
    }
  }

  removeEmail(email: string): void {
    const index = this.emailAddresses.indexOf(email);

    if (index !== -1) {
      this.emailAddresses.splice(index, 1);
      this.updateForm.get('emailCCAddresses')?.setValue(this.emailAddresses);
    }
  }

  async onChangeCompany(id: any) {
    if (id) {
      this.managers = await this.userService.getColleagues(id);
      this.managers = await this.managers.filter((u: any) => { return u._id !== this.user?._id?._id})
      this.companyService.getCompany(id).then((response) => {
        this.agenceService.getAgencesByCompany(response.data.data._id).then(async (response) => {
          this.agencies = response.data.data;
        }).catch(err => {
          console.log(err)
        });
      });
    }
  }
  updateUserPassWord() {
    if (this.updatePassword.value.new_password == this.updatePassword.value.new_password_confirmation) {
      let user = {
        password: this.updatePassword.value.new_password,
        admin: true
      }
      this.userService.updateUserPassWord((this.user._id._id || this.user._id), user).then((response) => {
        if (!response.data.error) {
          this.toastrService.success('', 'Informations d\'tilisateur mises à jour avec succès !', { positionClass: 'toast-top-center' })
          this.ngOnInit();
        } else {
          this.toastrService.error('', 'Erreur est surevnue lors de la mise jour d\'utilisateur', { positionClass: 'toast-top-center' })
        }
      }).catch(err => {
        this.toastrService.error('', 'Erreur est surevnue lors de la mise jour de mot de passe ', { positionClass: 'toast-top-center' })
      })
    } else {
      this.toastrService.error('', 'Le nouveau mot de passe et la confirmation ne correspondent pas', { positionClass: 'toast-top-center' })
    }
  }
}

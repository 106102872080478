import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderService } from 'src/app/core/services/order.service';
import { ProductService } from 'src/app/core/services/product.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { AgenceService } from 'src/app/core/services/agence.service';
import { SiteService } from 'src/app/core/services/site.service';
import { ContactService } from 'src/app/core/services/contact.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/core/services/user.service';
import { OpticalLoopService } from 'src/app/core/services/optical-loop.service';

@Component({
  selector: 'app-newtreatment',
  templateUrl: './newtreatment.component.html',
  styleUrls: ['./newtreatment.component.scss']
})
export class NewCommandComponent implements OnInit {
  generalInformationGP!: FormGroup;
  siteInfomationGP!: FormGroup;
  contactClientInformationGP!: FormGroup;
  contactsClient: any = []
  contactsSite: any = []
  sites: any = []
  contactSiteInformationGP!: FormGroup;
  data = { product: '', subcontractor: '', opticalLoop: '', agence: '', client: '' }
  private snapshotId = '';
  public current_user !: any;

  products: any[] = [];
  subcontractors: any[] = [];
  clients: any[] = [];
  agences: any[] = [];
  opticalLoops: any[] = []
  constructor(private _formBuilder: FormBuilder, private router: Router, private userService: UserService, private opticalLoopService: OpticalLoopService,
    private productService: ProductService, private orderService: OrderService,
    private companyService: CompanyService, private siteService: SiteService,
    private contactService: ContactService, private tokenService: TokenStorageService,
    private toastrService: ToastrService, private agenceService: AgenceService,) { }

  async ngOnInit(): Promise<void> {
    this.current_user = this.tokenService.getUser();
    this.data.client = this.current_user.company ? this.current_user.company.name : '';
    this.data.subcontractor = this.current_user.company ? this.current_user.company.name : '';
    this.products = await this.productService.getProducts();

    // Charger tout les paretnaires , sous traitant et sites de livraison
    this.companyService.getCompanies().then((response) => {
      this.subcontractors = response.data.data.filter(
        (item: any) => item.type === "SUBCONTRACTOR"
      );
      this.clients = response.data.data.filter(
        (item: any) => item.type === "CLIENT"
      );
    });

    // Charger tout les sites de livraison
    this.sites = await this.agenceService.getAgences();
    this.sites = this.sites.filter((agency: any) => agency.companyId.type === 'SITE');

    // Charger les boucles
    this.opticalLoops = await this.opticalLoopService.getOpticalLoops();



    this.generalInformationGP = this._formBuilder.group({
      category: ['RACCORDEMENT', Validators.required],
      product: ['', Validators.required],
      externalReference: [''],
      clientId: [''],
      referenceFCI: ['', ''],
      opticalLoop: ['', Validators.required],
      subcontractor: ['', Validators.required],
      agence: ['', Validators.required]
    });
    this.generalInformationGP.patchValue({ subcontractor: this.current_user.company ? this.current_user.company._id : null })
    this.generalInformationGP.patchValue({ clientId: this.current_user.company ? this.current_user.company._id : null })

    /**Charger tout les agences de company de l'utilisateur courant */
    if (this.current_user.company._id) {
      this.companyService.getCompany(this.current_user.company._id).then((response) => {
        this.data.subcontractor = response.data.data.name;
        this.agenceService.getAgencesByCompany(response.data.data._id).then((response) => {
          this.agences = response.data.data;
        }).catch(err => {
          console.log(err)
        });
      });
    }

    this.siteInfomationGP = this._formBuilder.group({
      siteName: new FormControl('TCL', Validators.required),
      city: ['Nantes'],
      postalCode: ['44300'],
      streetNum: ['1'],
      streetName: ['1'],
      streetCode: ['1'],
      building: ['1'], stair: ['1'], floor: ['2'], door: ['14'],
      siret: new FormControl('11223344556677', [Validators.required, Validators.pattern('[0-9]{14}')])
    });

    /**
     * Charger tout les contacts site et client
     */
    this.contactService.getContacts().then((response) => {
      this.contactsClient = response.data.data.filter((contact: any) => contact.type === 'CLIENT');
      this.contactsSite = response.data.data.filter((contact: any) => contact.type === 'SITE');
    })

    this.contactSiteInformationGP = this._formBuilder.group({
      firstNameSite: ['', Validators.required],
      lastNameSite: ['', Validators.required],
      emailSite: ['', Validators.required],
      phoneContactSite: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      phoneSContactSite: [''],
    });
    this.contactClientInformationGP = this._formBuilder.group({
      firstNameClient: ['', Validators.required],
      lastNameClient: ['', Validators.required],
      emailClient: ['', Validators.required],
      phoneContactClient: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      phoneSContactClient: [''],
    });


  }

  onChangeProduct() {
    this.productService.getProduct(this.generalInformationGP.value.product).then((response) => {
      this.data.product = response.data.data.name;
    });

  }

  onChangeClientCompany(id: any) {
    this.companyService.getCompany(id).then((response) => {
      this.data.client = response.data.data.name;
    }).catch(err => {console.error(err)})
  }

  onChangeSubcontractor(id: any) {
    this.companyService.getCompany(id).then((response) => {
      this.data.subcontractor = response.data.data.name;
      this.agenceService.getAgencesByCompany(response.data.data._id).then((response) => {
        this.agences = response.data.data;
      }).catch(err => {
        console.log(err)
      });
    });
  }
  onChangeAgence(id: any) {
    this.agenceService.getAgence(id).then(response => {
      this.data.agence = response.data.data.name;
    }).catch(err => console.error(err))
    
  }

  onChangeLoop(id: any) {
    this.data.opticalLoop = id;
  }


  async onSubmit() {
    // ADD Site
    let site = {
      name: this.siteInfomationGP.value.siteName,
      address: {
        cityCode: this.siteInfomationGP.value.postalCode,
        cityZip: this.siteInfomationGP.value.postalCode,
        cityName: this.siteInfomationGP.value.city,
        streetCode: this.siteInfomationGP.value.streetCode,
        streetName: this.siteInfomationGP.value.streetName,
        streetNum: this.siteInfomationGP.value.streetNum,
        building: this.siteInfomationGP.value.building,
        stair: this.siteInfomationGP.value.stair,
        floor: this.siteInfomationGP.value.floor,
        door: this.siteInfomationGP.value.door,
        hexacle: this.siteInfomationGP.value.streetCode,
      },
      siret: this.siteInfomationGP.value.siret
    }


    // ADD Contact Client
    let contactClient = {
      emailAddress: this.contactClientInformationGP.value.emailClient,
      firstName: this.contactClientInformationGP.value.firstNameClient,
      lastName: this.contactClientInformationGP.value.lastNameClient,
      phone1: this.contactClientInformationGP.value.phoneContactClient,
      phone2: this.contactClientInformationGP.value.phoneSContactClient,
      type: 'CLIENT'
    }


    // ADD Contact Site
    let contactSite = {
      emailAddress: this.contactSiteInformationGP.value.emailSite,
      firstName: this.contactSiteInformationGP.value.firstNameSite,
      lastName: this.contactSiteInformationGP.value.lastNameSite,
      phone1: this.contactSiteInformationGP.value.phoneContactSite,
      phone2: this.contactSiteInformationGP.value.phoneSContactSite,
      type: 'SITE'
    }
    let siteId = null
    if (this.selectedSite) {
      siteId = this.selectedSite._id
    } else {
      const [siteResponse] = await Promise.all([
        this.siteService.addSite(site)
      ]);
      siteId = siteResponse.data.data
    }

    let clientContactId = null
    let siteContactId = null
    if (this.selectedClientContact) {
      clientContactId = this.selectedClientContact._id;
      
    } else {
      const [contactClientId] = await Promise.all([
        this.contactService.addContact(contactClient)
      ]);
      clientContactId = contactClientId.data.data;
      
    }
    if(siteContactId){
      siteContactId = this.selectedSiteContact._id;
    }
    else if(siteContactId) 
    {
      const [contactSiteId] = await Promise.all([
        this.contactService.addContact(contactSite)
      ])
      siteContactId = contactSiteId.data.data;
    } else {
      siteContactId = null;
    }

    const Ids = {
      siteId: siteId,
      clientContactId: clientContactId,
      siteContactId: siteContactId,
      productId: this.generalInformationGP.value.product,
      creatorId: this.current_user.userId,
      opticalLoop: this.generalInformationGP.value.opticalLoop
    }

    // ADD ORDER

    const order = {
      externalReference: this.generalInformationGP.value.externalReference,
      category: this.generalInformationGP.value.category, // Mandatory
      agencyId: this.generalInformationGP.value.agence, // Mandatory
      statusId: '64871f903b4a8a6b28648bf4', // TO DO // Mandatory
      creatorId: this.current_user.userId, // Mandatory
      supervisorId: null,
      providerId: null,
      content: {
        category: this.generalInformationGP.value.category, // Mandatory
        clientId: this.generalInformationGP.value.clientId,
        opticalLoop: this.generalInformationGP.value.opticalLoop,
        siteId: siteId,
        clientContactId: clientContactId,
        siteContactId: siteContactId,
        referenceFCI: this.generalInformationGP.value.referenceFCI, // TO DO
        ema: {
          dateCreation: new Date(), // TO DO
          idproductorder: this.generalInformationGP.value.externalReference, // TO DO
          idproductorder_detail: this.generalInformationGP.value.externalReference + '##' + this.generalInformationGP.value.externalReference,
          productReference: null // TO DO
        },
        totalSellingPrice: 100, // TO DO
        nbDOERefusals: 0,
        productId: this.generalInformationGP.value.product
      }
    }


    this.orderService.addOrder(order).then((response) => {
      if (response.data.error == false) {
        this.toastrService.success('', 'Commande crée avec succès !', { positionClass: 'toast-top-center' })
        this.router.navigate(['/order/' + response?.data?.data?._id])
      } else {
        window.location.reload();
      }

    })


  }

  filterById(jsonObject: any, id: any) { return jsonObject.filter(function (jsonObject: any) { return (jsonObject['_id'] == id); })[0]; }

  getErrorMessage() {

  }

  selectedClientContact!: any;
  selectedSite!: any;
  selectedSiteContact!: any;
  onChangeSiteContact(newObj: any) {
    this.selectedSiteContact = newObj;
    this.contactSiteInformationGP.patchValue({ firstNameSite: newObj ? newObj.firstName : null })
    this.contactSiteInformationGP.patchValue({ lastNameSite: newObj ? newObj.lastName : null })
    this.contactSiteInformationGP.patchValue({ emailSite: newObj ? newObj.emailAddress : null })
    this.contactSiteInformationGP.patchValue({ phoneContactSite: newObj ? newObj.phone1 : null })
    this.contactSiteInformationGP.patchValue({ phoneSContactSite: newObj ? newObj.phone2 : null })

  }

  onChangeClientContact(newObj: any) {
    this.selectedClientContact = newObj;
    this.contactClientInformationGP.patchValue({ firstNameClient: newObj ? newObj.firstName : null })
    this.contactClientInformationGP.patchValue({ lastNameClient: newObj ? newObj.lastName : null })
    this.contactClientInformationGP.patchValue({ emailClient: newObj ? newObj.emailAddress : null })
    this.contactClientInformationGP.patchValue({ phoneContactClient: newObj ? newObj.phone1 : null })
    this.contactClientInformationGP.patchValue({ phoneSContactClient: newObj ? newObj.phone2 : null })


  }

  resetSiteContactForm() {
    this.contactSiteInformationGP.patchValue({ firstNameSite: '' })
    this.contactSiteInformationGP.patchValue({ lastNameSite: '' })
    this.contactSiteInformationGP.patchValue({ emailSite: '' })
    this.contactSiteInformationGP.patchValue({ phoneContactSite: '' })
    this.contactSiteInformationGP.patchValue({ phoneSContactSite: '' })
    this.selectedSiteContact = null;
  }
  resetClientContactForm() {
    this.contactClientInformationGP.patchValue({ firstNameClient: '' })
    this.contactClientInformationGP.patchValue({ lastNameClient: '' })
    this.contactClientInformationGP.patchValue({ emailClient: '' })
    this.contactClientInformationGP.patchValue({ phoneContactClient: '' })
    this.contactClientInformationGP.patchValue({ phoneSContactClient: '' })
    this.selectedClientContact = null;
  }

  resetSiteForm() {
    this.siteInfomationGP.patchValue({ siteName: '' })
    this.siteInfomationGP.patchValue({ postalCode: '' })
    this.siteInfomationGP.patchValue({ city: '' })
    this.siteInfomationGP.patchValue({ cityZip: '' })
    this.siteInfomationGP.patchValue({ streetCode: '' })
    this.siteInfomationGP.patchValue({ streetName: '' })
    this.siteInfomationGP.patchValue({ streetNum: '' })
    this.siteInfomationGP.patchValue({ building: '' })
    this.siteInfomationGP.patchValue({ stair: '' })
    this.siteInfomationGP.patchValue({ floor: '' })
    this.siteInfomationGP.patchValue({ door: '' })
    this.siteInfomationGP.patchValue({ hexacle: '' })
    this.siteInfomationGP.patchValue({ siret: '' })
    this.selectedSite = null;
  }

  onChangeSite(newObj: any) {
    this.selectedSite = newObj;
    this.siteInfomationGP.patchValue({ siteName: newObj ? newObj.name : null })
    this.siteInfomationGP.patchValue({ postalCode: newObj ? newObj?.address?.cityCode : null })
    this.siteInfomationGP.patchValue({ city: newObj ? newObj?.address?.cityName : null })
    this.siteInfomationGP.patchValue({ cityZip: newObj ? newObj?.address?.cityZip : null })
    this.siteInfomationGP.patchValue({ streetCode: newObj ? newObj?.address?.streetCode : null })
    this.siteInfomationGP.patchValue({ streetName: newObj ? newObj?.address?.streetName : null })
    this.siteInfomationGP.patchValue({ streetNum: newObj ? newObj?.address?.streetNum : null })
    this.siteInfomationGP.patchValue({ building: newObj ? newObj?.address?.building : null })
    this.siteInfomationGP.patchValue({ stair: newObj ? newObj?.address?.stair : null })
    this.siteInfomationGP.patchValue({ floor: newObj ? newObj?.address?.floor : null })
    this.siteInfomationGP.patchValue({ door: newObj ? newObj?.address?.door : null })
    this.siteInfomationGP.patchValue({ hexacle: newObj ? newObj?.address?.hexacle : null })
    this.siteInfomationGP.patchValue({ siret: newObj ? newObj.siret : null })


  }
}

<!-- Infos générals-->
<div class="col-xl-12 three-boxes">
    <div class="row">
        <!-- Point de livraison -->
        <div class="col-xl-2 card box">
            <app-delivery-site [command]='command'></app-delivery-site>
        </div>
        <!-- Status -->
        <div class="col-xl-8 card box">
            <app-status-management [command]='command'></app-status-management>
        </div>
        <!--- Contacts -->
        <div class="col-xl-2 card box">
            <app-contacts [command]='command'></app-contacts>
        </div>
    </div>
</div>
<br>
<!-- Dernier Message -->
<div class="col-xl-12 card block">
    <app-last-message [command]='command'></app-last-message>
</div>

<br>
<div class="col-xl-12 twoboxes">
    <div class="row">
        <!-- Données Techniques-->
        <div class="col-xl-6 card box">
            <app-dt [command]='command'></app-dt>
        </div>
        <!-- Données Financières-->
        <div class="col-xl-6 card box">
            <app-df [command]='command'></app-df>
        </div>
    </div>
</div>
<br>


<div class="col-xl-12 twoboxes">
    <div class="row">
        <!-- Visite Technique -->
        <div class="col-xl-6 card box">
            <app-vt [command]='command'></app-vt>
        </div>
        <!-- Raccordement technique-->
        <div class="col-xl-6 card box">
            <app-rt [command]='command'></app-rt>
        </div>
    </div>
</div>
<br>
<div class="col-xl-12 card block" *ngIf="this.permissionService.hasPermission(current_user  , 'ORDER_ASSIGN');">
    <app-assignment [command]='command'></app-assignment>
</div>

<div class="col-xl-12 card block force-status" *ngIf="this.permissionService.hasPermission(current_user  , 'ORDER_ADMIN') && current_user.role.includes('RACCO_ADMIN')">
    <app-force-status [command]='command'></app-force-status>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Contact } from 'src/app/core/interfaces/contact';
import { User } from 'src/app/core/interfaces/user';
import { UserService } from 'src/app/core/services/user.service';
import { CompanyService } from 'src/app/core/services/company.service';
import * as _ from 'lodash';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  displayedColumns: string[] = ['actions','company','agency','lastname', 'firstname', 'username', 'manager', /*'role',*/ 'actif'];
  dataSource!: MatTableDataSource<any>;
  USER_DATA!: any[];
  companies!: any[];
  roles!: string[];
  managers!: string[];
  contactsInfos!: Contact[];
  
  constructor(private companyService: CompanyService, private userService: UserService) { 
  }

  ngOnInit(): void {
    this.userService.getUsers().then((response) => {
      this.USER_DATA = response.data.data;
      this.dataSource = new MatTableDataSource<any>(this.USER_DATA); 
       
      this.dataSource.sortingDataAccessor = (item, header) => {
        switch (header) {
          case 'company':
            return item?.agencyId?.companyId?.name?.toLowerCase() || '';
          case 'agency':
            return item?.agencyId?.name?.toLowerCase() || '';
          case 'lastname':
            return item.contactId?.lastName?.toLowerCase() || '';
          case 'firstname':
            return item.contactId?.firstName?.toLowerCase() || '';
          default:
            return '';
        }
      };
      this.dataSource.sort = this.sort;
    }).catch(err =>{
      console.error(err)
    })
    
      
  }

  applySearch(event: any){
    const searchVaule = (event.target as HTMLInputElement).value;
    this.dataSource.filter = searchVaule.trim().toLowerCase();
  }

}

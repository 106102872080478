<h1 mat-dialog-title><span>  {{ rdv?.orderId?.content?.ema?.idProductOrderDetail}} <span *ngIf="site?.data?.data"> - {{ site?.data?.data?.name}}</span></span>
    <span class="close">
        <mat-icon (click)="cancel()">close</mat-icon>
    </span>
</h1>
<mat-dialog-content class="mat-typography">
    <table class="table">
        <tr>
            <th>Date Debut</th>
            <th>Date Fin</th>
            <th>Type </th>
            <th>Commande </th>
            <th>Status</th>
        </tr>
        <tr>
            <td>
                {{datepipe.transform(rdv?.dateStart, 'dd/MM/yyyy HH:mm')}}
            </td>
            <td>
                {{datepipe.transform(rdv?.dateEnd, 'dd/MM/yyyy HH:mm')}}
            </td>
            <td>{{ rdv?.type }}</td>
            <td><a [routerLink]="['/order',rdv?.orderId?._id]" (click)="cancel()"><mat-icon>link</mat-icon></a> </td>
            <td>{{ rdv?.status}}</td>
        </tr>
    </table>
</mat-dialog-content>

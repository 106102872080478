import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { OrderService } from 'src/app/core/services/order.service';
import { Treatment } from 'src/app/core/interfaces/treatment';
import { Status } from 'src/app/core/interfaces/status';
import { FormControl } from '@angular/forms';
import { StatusService } from 'src/app/core/services/status.service';
import { Router } from '@angular/router';
import { FilterService } from 'src/app/core/services/filter.service';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  cards = []  as  any;
  pieChart = []  as  any;
  @Input() treatments: Treatment[] = [];
  stateControl = new FormControl<any[]>([]);
  stateList?: any [];
  raccordementXY: any = {};
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  constructor(private dashboardService: DashboardService, private orderService: OrderService, private statusService: StatusService, private filterService: FilterService, private router: Router) { }

  ngOnInit() {
    
    this.loadStatus();
    this.cards = this.dashboardService.cards();

  }


  onStateRemoved(state: string) {
    let states = this.stateControl.value as string[];
    
    this.removeFirst(states, state);
    this.stateControl.setValue(states); 
    
  }
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  public async loadStatus(): Promise<void>{
    this.stateList = await this.statusService.getStatus();
    this.raccordementXY = await  this.dashboardService.getOrdersByXY();
  }

  navigateToCommandTable() {
    // Set filter data
    this.filterService.setFormFilter({assignedRole: "true", finishedOrders: "true"})
  
    // Navigate to command-table route
    this.router.navigate(['/orders']);
  }
  

}

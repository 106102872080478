import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CompanyService } from 'src/app/core/services/company.service';

@Component({
  selector: 'app-company-modal',
  templateUrl: './company-modal.component.html',
  styleUrls: ['./company-modal.component.scss']
})
export class CompanyModalComponent implements OnInit {

  company : any;
  constructor(private companyService: CompanyService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.companyService.getCompany(this.data?.companyId).then((response: any) => {
      this.company = response.data.data;
    }).catch(err => {
      console.error(err);
    });
  }

}

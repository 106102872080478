<mat-form-field>
  <mat-label>Recherche</mat-label>
  <input matInput placeholder="...." (keyup)="applySearch($event)">
</mat-form-field>
<table mat-table [dataSource]="dataSource" matSort  class="mat-elevation-z8">
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/admin/users/show/{{element._id}}">
          <mat-icon>assignment_ind</mat-icon>
          <span>Voir</span>
        </button>
        <button mat-menu-item routerLink="/admin/users/edit/{{element._id}}">
          <mat-icon>edit</mat-icon>
          <span>Modifier</span>
        </button>
      </mat-menu>

    </td>
  </ng-container>
  <ng-container matColumnDef="company">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Entreprise </th>
    <td mat-cell *matCellDef="let element"> {{element?.agencyId?.companyId?.name}}</td>
  </ng-container>
  <ng-container matColumnDef="agency">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Agence </th>
    <td mat-cell *matCellDef="let element"> {{element?.agencyId?.name}}</td>
  </ng-container>
  
  <ng-container matColumnDef="lastname">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
    <td mat-cell *matCellDef="let element"> {{element.contactId.lastName}} </td>
  </ng-container>

  <ng-container matColumnDef="firstname">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom</th>
    <td mat-cell *matCellDef="let element"> {{element.contactId.firstName}} </td>
  </ng-container>
  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef> Login </th>
    <td mat-cell *matCellDef="let element"> {{element.username}} </td>
  </ng-container>
  
  <ng-container matColumnDef="manager">
    <th mat-header-cell *matHeaderCellDef> Manager </th>
    <td mat-cell *matCellDef="let element"> <span *ngIf="element.managerId && element.managerId.contactId"> {{
        element?.managerId?.contactId.firstName }} {{ element?.managerId?.contactId?.lastName }}</span></td>
  </ng-container>
  <!--ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef> Rôle </th>
        <td mat-cell *matCellDef="let element">
            [<span *ngFor="let role of element.rolesIds">{{ role.name }} </span>]
        </td>
    </ng-container-->
  <ng-container matColumnDef="actif">
    <th mat-header-cell *matHeaderCellDef> Actif </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox class="example-margin" [checked]='element.isActive' [disabled]="true" color="primary">
      </mat-checkbox>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { RoleService } from 'src/app/core/services/role.service';
import { AgenceService } from 'src/app/core/services/agence.service';

@Component({
  selector: 'app-newuser',
  templateUrl: './newuser.component.html',
  styleUrls: ['./newuser.component.scss']
})
export class NewuserComponent implements OnInit {

  formdata = this.formBuilder.group({
    firstName: [null, [
      Validators.required, 
      Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
    ]],
    lastName: [null, [
      Validators.required, 
      Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
    ]],
    role: [null, [
      Validators.required, 
      Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
    ]],
    agency: [null, [
      Validators.required, 
      Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
    ]],
    manager: [null, [
      Validators.required, 
      Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
    ]],
    email: [null, [
      Validators.required, 
      Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
    ]],
    company: null,
    password: [null, [
      Validators.required, 
      Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
    ]],
    passwordConfirm: '',
    emailCCAddresses: '',
    emailCCAuto: '',
    emailFollower: ''
  });
  companies: any[] = [];
  agencies: any[] = [];
  roles!: any[];
  managers: any[] = [];
  constructor(private formBuilder: FormBuilder, private companyService: CompanyService, private roleService: RoleService,
              private toastr: ToastrService, private router: Router, private userService: UserService, private agenceService: AgenceService ){ }

  async ngOnInit(): Promise<void> {
    this.companyService.getCompanies().then((response) => {
      this.companies = response.data.data.filter(
        (item: any) => item.type === "SUBCONTRACTOR"
      );
    });
    this.roles = await this.roleService.getRoles();

  }

  async onChangeCompany(id: any) {
    this.companyService.getCompany(id).then((response) => {
      this.agenceService.getAgencesByCompany(response.data.data._id).then(async (response) => {
        this.agencies = response.data.data;
      }).catch(err => {
        console.log(err)
      });
    });

    this.managers = await this.userService.getUsersByCompany(id);
  }

  onClickSubmit(data: any) {
    let user: any;
    if (this.formdata.value.password == this.formdata.value.passwordConfirm){
      user = {
        username: ((this.formdata?.value?.firstName as unknown as string).substring(0, 1)+'.'+this.formdata.value.lastName).toLocaleLowerCase(),
        emailAddress: this.formdata.value.email, 
        firstName: this.formdata.value.firstName,
        lastName: this.formdata.value.lastName,
        agencyId: this.formdata.value.agency,
        role: null,
        manager: this.formdata.value.manager? this.formdata.value.manager : null ,
        isActive: true,
        password: this.formdata.value.password,
        emailCCAddresses:  this?.formdata?.value?.emailCCAddresses,
        emailCCAuto:  this?.formdata?.value?.emailCCAuto,
        emailFollower:  this?.formdata?.value?.emailFollower,
      }
      
      
      this.userService.addNewUser(user).then((response) =>{
        if (!response.data.error){
          this.toastr.success('', 'Nouvel utilisateur créé avec succès !', {positionClass: 'toast-top-center'})
          this.router.navigate(['/admin/users/show/'+response.data.data])
        }else
        {
          this.toastr.error('', 'Erreur est surevnue lors de création d\'utilisateur' ,{positionClass: 'toast-top-center'})
        }
      }).catch((err)=>{
        this.toastr.error('', 'Merci de remplir tout les champs obligatoires !' ,{positionClass: 'toast-top-center'})
      })
    }
    
  }
}

<h2 class='title'>
    <mat-icon color="primary">folder_copy</mat-icon> Données techniques
</h2>
<div class="col-xl-12 twoboxes">
    <div class="row">
        <!-- PLAN_PDF -->
        <div class="col-xl-6">
            <app-document-manager [command]="command" documentType = "DT_PLAN_PDF"></app-document-manager>
        </div>
        <!-- PLAN_ZIP -->
        <div class="col-xl-6">
            <app-document-manager [command]="command" documentType = "DT_PLAN_ZIP"></app-document-manager>
        </div>
    </div>
    <div class="row">
        <!-- OTHER_BE -->
        <div class="col-xl-6">
            <app-document-manager [command]="command" documentType = "DT_OTHER_BE"></app-document-manager>
        </div>
        <!-- DOE -->
        <div class="col-xl-6">
            <app-document-manager [command]="command" documentType = "DT_DOE"></app-document-manager>
        </div>
    </div>
    <div class="row">
        <!-- DFT -->
        <div class="col-xl-6">
            <app-document-manager [command]="command" documentType = "DT_DFT"></app-document-manager>
        </div>
        <!-- FCI REF -->
        <div class="col-xl-6">
            <app-fci-ref [command]="command"></app-fci-ref>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { PermissionService } from 'src/app/core/services/permission.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-df',
  templateUrl: './df.component.html',
  styleUrls: ['./df.component.scss']
})
export class DFComponent implements OnInit {
  @Input() command: any;
  hasPermission = false;
  hasRole = false;
  current_user: any = null;
  constructor(public permissionService: PermissionService, private tokenService: TokenStorageService,) { }
 
  async ngOnInit(): Promise<void> {
    this.current_user = this.tokenService.getUser();
    this.hasPermission = this.permissionService.hasPermission(this.current_user  , 'FINANCIAL_VIEW');
    this.hasRole = await this.permissionService.hasRole(this.current_user, this.command?.statusId?.assignedRoleId?.reference);
  }

}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  public isLoggedIn!: boolean;
  current_user: any;
  public notificationCounter!: number; 
  constructor(private tokenService: TokenStorageService, private router: Router, private authService: AuthentificationService,
             private notificationService: NotificationService, public dialog: MatDialog) { }
  
  
  ngOnInit() { 
    if(this.tokenService.isTokenExpired()) {
      this.router.navigate(['/login']);
    }
    else {
      this.current_user = this.tokenService.getUser();
      if (this.current_user.userId != undefined){
        this.authService.isLoggedIn.subscribe(loggedIn => {
          this.isLoggedIn = loggedIn 
        });
      }
    }
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

  signout(event: Event){
    this.tokenService.signOut();
    this.router.navigate(['/login']);
    window.location.reload();
    
  }
  signIn(event: Event){
    this.router.navigate(['/login']);
    
  }

  editProfile(event:Event){
    this.router.navigate(['user/edit_profile/'+ this.current_user.userId]);
  }




}

<h1 mat-dialog-title><span>Affectation</span></h1>
<div class="col-xl-12 justify-content-center align-items-center">
    <div class="agency" *ngIf="field === 'agency'">
        <mat-form-field appearance="fill" class="standard">
            <mat-label>Sous traitant</mat-label>
            <mat-select name="subcontractor" [(ngModel)]="selectedSubcontractor"  [(value)]="selectedSubcontractor" (selectionChange)="onChangeSubcontractor($event)" required>
                <mat-option *ngFor="let subcontractor of subcontractors" value="{{subcontractor._id}}">
                    {{subcontractor.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field appearance="fill">
            <mat-label>Agence</mat-label>
            <mat-select name="agence" [(ngModel)]="selectedAgency" required>
                <mat-option *ngFor="let agence of agences" [value]="agence._id">
                    {{agence?.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-form-field appearance="fill" *ngIf="field === 'supervisor'">
        <mat-label>Responsable</mat-label>
        <mat-select [(ngModel)]="selectedSupervisor">
            <mat-option *ngFor="let s of supervisors" [value]="s._id">{{s.contactId.firstName}}
                {{s.contactId.lastName}} </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="field === 'engineeringOfficer'">
        <mat-label>BE</mat-label>
        <mat-select [(ngModel)]="selectedEngineeringOfficer">
            <mat-option *ngFor="let eo of engineeringofficers" [value]="eo._id">{{eo.contactId.firstName}}
                {{eo.contactId.lastName}} </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="field === 'provider'">
        <mat-label>Intervenant</mat-label>
        <mat-select [(ngModel)]="selectedProvider">
            <mat-option *ngFor="let p of providers" [value]="p._id">{{p.contactId.firstName}}
                {{p.contactId.lastName}} </mat-option>
        </mat-select>
    </mat-form-field>
    <td><button mat-raised-button color="primary" (click)="updateAssignation(field)">Sauvegarder</button></td>
    <td><button mat-raised-button color="warn" (click)="cancelUpdate()">Annuler</button></td>
</div>
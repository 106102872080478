import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleService } from 'src/app/core/services/role.service';
import { StatusService } from 'src/app/core/services/status.service';

@Component({
  selector: 'app-status-categories-update-form',
  templateUrl: './status-categories-update-form.component.html',
  styleUrls: ['./status-categories-update-form.component.scss']
})
export class StatusCategoriesUpdateFormComponent implements OnInit {

  public onlyReadInput =  true;
  statutCategoriesFG!: FormGroup;
  constructor(private statusService: StatusService,
    private _formBuilder: FormBuilder, 
    private dialogRef: MatDialogRef<any>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar ) { }

  async ngOnInit(): Promise<void> {
    this.statutCategoriesFG = this._formBuilder.group({
      reference: new FormControl(this.data?.statusCategory?.reference , Validators.required),
      name: [this.data?.statusCategory?.name , Validators.required],
      description: [this.data?.statusCategory?.description]
    });
  }

  onSubmit(){
    let statusCategory = {
      id: this.data.statusCategory?._id,
      reference: this.statutCategoriesFG.value.reference,
      name: this.statutCategoriesFG.value.name,
      description: this.statutCategoriesFG.value.description
    }

    this.statusService.updateStatusCategory(statusCategory).then((response)  => {
      this._snackBar.open('Mise à jour de statut est effectuée avec succès', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }).catch(err => {
      this._snackBar.open('Erreur lors de la mise à jour de statut', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      })
    }).finally(() => {
        this.statusService.triggerStatusUpdated();
        this.exit();
    });

  }

  exit(){
    this.dialogRef.close();
  }

}

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element"> <mat-icon (click)="openPdfViewer(element)">visibility</mat-icon></td>
    </ng-container>
    <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef> Version </th>
        <td mat-cell *matCellDef="let element"> {{ element?.version }} </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nom </th>
        <td mat-cell *matCellDef="let element"> {{ element?.name }} </td>
    </ng-container>
    <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Catgéorie </th>
        <td mat-cell *matCellDef="let element"> {{ element?.category }} </td>
    </ng-container>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Déscription </th>
        <td mat-cell *matCellDef="let element"> {{ element?.description }} </td>
    </ng-container>
    <ng-container matColumnDef="fileType">
        <th mat-header-cell *matHeaderCellDef> Type de fichier</th>
        <td mat-cell *matCellDef="let element"> {{ element?.fileType }} </td>
    </ng-container>
    <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef> Utilisateur </th>
        <td mat-cell *matCellDef="let element"> {{ element?.userId?.contactId?.firstName }} {{ element?.userId?.contactId?.lastName }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div class="container">
    <div class="up">
      <img src="../../../../assets/images/plugUp.png"  alt="MAE">
    </div>
    <div class="msg">
      <h2 color='warn'>Oups ! Cette page n’est plus disponible </h2>
      <p class="no-right"> Vous n'avez pas les droits d'y accéder </p>
    </div>
    <div class="down">
      <img src="../../../../assets/images/plugDown.png" alt="MAE" >
    </div>
  </div>
  
<h2>
    <mat-icon color="primary">build_circle</mat-icon> Forcer un statut
</h2>
<div class="col-xl-12">
    <div class="status-assignedTo">
        <div class="status" matTooltip='{{ command.statusId?.statusCategoryId?.name }}'><span>Statut actuel : </span>
            <b>{{ command?.statusId?.name }}</b>
        </div>
    </div>
    <div class="status" >
        <mat-form-field appearance="fill" class="standard">
            <mat-label>Status</mat-label>
            <mat-select name="status" [(ngModel)]="selectedStatus"  [(value)]="selectedStatus" required>
                <mat-option *ngFor="let st of status" value="{{st._id}}">
                    {{st.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <td><button mat-raised-button color="primary" (click)="updateStatus()">Enregistrer</button></td>
        <br>
    </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private filterSubject = new BehaviorSubject<any>({});

  setFilter(filter: any) {
    this.filterSubject.next(filter);
  }

  getFilter(): Observable<any> {
    return this.filterSubject.asObservable().pipe(distinctUntilChanged());
  }
  getFilterValue(): any {
    return this.filterSubject.value;
  }
  

  
  finishedOrders: string = 'true';
  activeRoleFilter: string = 'true';
  formFilter: any = {};
  
  setActiveRoleFilter(value: string) {
    this.activeRoleFilter = value;
  }

  getActiveRoleFilter(): string {
    return this.activeRoleFilter;
  }

  setFinishedOrdersFilter(value: string) {
    this.finishedOrders = value;
  }

  getFinishedOrdersFilter(): string {
    return this.finishedOrders;
  }

  setFormFilter(value: any) {
    this.formFilter = value;
  }

  getFormFilter(): any {
    if (!this.formFilter.hasOwnProperty('assignedRole') ) {
      this.formFilter.assignedRole = this.activeRoleFilter;
    }
    if (!this.formFilter.hasOwnProperty('finishedOrders')) {
      this.formFilter.finishedOrders = this.finishedOrders;
    }
    
    return this.formFilter;
  }
}

<div class="container-fluid">
  <mat-stepper [linear]="true" #stepper>
    <!-- General informations -->
    <mat-step [stepControl]="generalInformationGP" [optional]="false">
      <div class="alert alert-warning">*  Champs obligatoire</div>
      <div class="form-group">
        <form [formGroup]="generalInformationGP">
          <ng-template matStepLabel>Informations générales</ng-template>
          <mat-form-field  appearance="fill" class="standard">
            <mat-label>Catgéorie</mat-label>
            <input matInput placeholder="Ex. 14520" formControlName="category" required value="RACCORDEMENT" readonly>
          </mat-form-field>
          <mat-form-field appearance="fill" class="standard">
            <mat-label>Type de produit</mat-label>
            <mat-select name="type" formControlName="product" (ngModelChange)="onChangeProduct()" required>
              <mat-option *ngFor="let product of products" [value]="product._id">
                {{product.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="standard">
            <mat-label>Réference commande EMA</mat-label>
            <input matInput placeholder="Ex. 14520" formControlName="externalReference" required>
          </mat-form-field>
          <mat-form-field appearance="fill" class="standard">
            <mat-label>Partenaire</mat-label>
            <mat-select name="clientId" formControlName="clientId" (ngModelChange)="onChangeClientCompany($event)" required>
              <mat-option *ngFor="let client of clients" value="{{client._id}}">
                {{client.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="standard">
            <mat-label>Réference FCI</mat-label>
            <input matInput placeholder="Ex. AV14520" formControlName="referenceFCI">
          </mat-form-field>
          <mat-form-field appearance="fill" class="standard" required>
            <mat-label>Boucle</mat-label>
            <mat-select name="loop" formControlName="opticalLoop" (ngModelChange)="onChangeLoop($event)" required>
              <mat-option *ngFor="let loop of opticalLoops" [value]="loop.name">
                {{loop.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="standard">
            <mat-label>Sous traitant</mat-label>
            <mat-select name="subcontractor" formControlName="subcontractor" (ngModelChange)="onChangeSubcontractor($event)" required>
              <mat-option *ngFor="let subcontractor of subcontractors" value="{{subcontractor._id}}">
                {{subcontractor.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="standard">
            <mat-label>Agence</mat-label>
            <mat-select name="agence" formControlName="agence" (ngModelChange)="onChangeAgence($event)" required>
              <mat-option *ngFor="let agence of agences" [value]="agence._id">
                {{agence?.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <button mat-button matStepperNext color="accent">Suivant ></button>
          </div>
        </form>
      </div>

    </mat-step>

    <!-- Site informations -->
    <mat-step [stepControl]="siteInfomationGP" [optional]="false" label="Informations de site">
      <div class="alert alert-warning">*  Champs obligatoire</div>
      <tr>
        <td class="userChoice">
          <mat-form-field appearance="fill" class='select-choice'>
          <mat-label>Choisir un site de livraison existant</mat-label>
          <mat-select [ngModel]="selectedSite" placeholder="Uilisateurs" (ngModelChange)="onChangeSite($event)">
            <mat-option *ngFor="let s of sites" [value]="s">{{s.name}}</mat-option>
          </mat-select>
        </mat-form-field></td>
        <td class='btn-td'><button mat-raised-button color="primary" class='btn-reset' (click)='resetSiteForm()'>Réinitialiser le formulaire</button></td>
      </tr>
      
      <form [formGroup]="siteInfomationGP">
        <p>
          <mat-form-field class="siteName" appearance="fill">
            <mat-label>Nom de site de livraison</mat-label>
            <input matInput placeholder="Ex. Résidence de la trémissinière" formControlName="siteName" required>
          </mat-form-field>
        </p>
        <mat-divider></mat-divider>
        <p>
          <mat-form-field class="postal_code" appearance="fill">
            <mat-label>Code Postal</mat-label>
            <input matInput #postalCode maxlength="5" placeholder="Ex. 44210" formControlName="postalCode">
            <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
          </mat-form-field>
          <mat-form-field class="city" appearance="fill">
            <mat-label>Ville</mat-label>
            <input matInput placeholder="Ex. Pornic" formControlName="city">
          </mat-form-field>

        </p>
        <mat-divider></mat-divider>
        <p>
          <mat-form-field class="streetNum" appearance="fill">
            <mat-label>Numéro de Rue</mat-label>
            <input type="text" matInput placeholder="Ex. 20" formControlName="streetNum" />
          </mat-form-field>
          <mat-form-field class="streetName" appearance="fill">
            <mat-label>Rue</mat-label>
            <input type="text" matInput placeholder="Ex. Rue Pont d'argent " formControlName="streetName" />
          </mat-form-field>
          <mat-form-field class="streetCode" appearance="fill">
            <mat-label>Code rivoli</mat-label>
            <input type="text" matInput placeholder="Ex. 3570" formControlName="streetCode" />
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="building" appearance="fill">
            <mat-label>Bâtiment</mat-label>
            <input type="text" matInput placeholder="Ex. 20" formControlName="building" />
          </mat-form-field>
          <mat-form-field class="stair" appearance="fill">
            <mat-label>Escalier</mat-label>
            <input type="text" matInput formControlName="stair" />
          </mat-form-field>
          <mat-form-field class="floor" appearance="fill">
            <mat-label>Étage</mat-label>
            <input type="text" matInput placeholder="Ex. 1" formControlName="floor" />
            <mat-hint>0 si RdC</mat-hint>
          </mat-form-field>
          <mat-form-field class="door" appearance="fill">
            <mat-label>Porte</mat-label>
            <input type="text" matInput placeholder="Ex. 4" formControlName="door" />
          </mat-form-field>
        </p>
        <mat-divider></mat-divider>

        <p>

          <mat-form-field class="siret" appearance="fill">
            <mat-label>Siret</mat-label>
            <input matInput #siret type="text" formControlName="siret" placeholder="11223344556677" pattern="[0-9]{14}"
              minlength="14" maxlength="14" required>
            <mat-hint align="end">{{siret.value.length}} / 14</mat-hint>
          </mat-form-field>
        </p>

        <div>
          <button mat-button matStepperPrevious color="warn">< Précédent</button>
          <button mat-button matStepperNext color="accent">Suivant ></button>
        </div>
      </form>
    </mat-step>

    <!-- Contact informations -->
    <mat-step [stepControl]="contactClientInformationGP">
      <div class="alert alert-warning">*  Champs obligatoire</div>
      <ng-template matStepLabel>Informations de contacts</ng-template>
      <mat-accordion class="headers-align" multi>
          <!-- Contact Partenaire -->
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Information du contact
              </mat-panel-title>
              <mat-panel-description>
                Partenaire
                <mat-icon>account_circle</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
  
            <br><br>
            <mat-form-field appearance="fill" class="contactChoice">
              <mat-label>Choisir un contact partenaire</mat-label>
              <mat-select [ngModel]="selectedClientContact" placeholder="Uilisateurs" (ngModelChange)="onChangeClientContact($event)">
                <mat-option *ngFor="let s of contactsClient" [value]="s">{{s.firstName}} {{s.lastName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-raised-button color="primary" class='btn-reset' (click)='resetClientContactForm()'>Réinitialiser le formulaire</button>
            <br>
            <form [formGroup]="contactClientInformationGP">
              <p>
                <mat-form-field appearance="fill" class="contact">
                  <mat-label>Nom</mat-label>
                  <input matInput placeholder="Placeholder" formControlName="lastNameClient">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field appearance="fill" class="contact">
                  <mat-label>Prénom</mat-label>
                  <input matInput placeholder="Placeholder" formControlName="firstNameClient">
                </mat-form-field>
              </p>
              <p>
                <mat-form-field appearance="fill" class="contact">
                  <mat-label>Téléphone</mat-label>
                  <input matInput #phoneContactClient placeholder="Placeholder" formControlName="phoneContactClient"
                    pattern="[0-9]{10}" minlength="10" maxlength="10" required>
                  <mat-hint align="end">{{phoneContactClient.value.length}} / 10</mat-hint>
                </mat-form-field>
              </p>
              <p>
                <mat-form-field appearance="fill" class="contact">
                  <mat-label>Téléphone secondaire</mat-label>
                  <input matInput #phoneSContactClient placeholder="Placeholder" formControlName="phoneSContactClient"
                    pattern="[0-9]{10}" minlength="10" maxlength="10">
                  <mat-hint align="end">{{phoneSContactClient.value.length}} / 10</mat-hint>
                </mat-form-field>
              </p>
              <p>
                <mat-form-field appearance="fill" class="contact">
                  <mat-label>Email</mat-label>
                  <input matInput placeholder="aaaa@alphalink.fr" formControlName="emailClient" type="email" email='true' required>
                </mat-form-field>
              </p>
            </form>
          </mat-expansion-panel>
        <!-- Contact Site de livraison -->
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Information du contact
            </mat-panel-title>
            <mat-panel-description>
              Site de livraison
              <mat-icon>account_circle</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <br><br>
          <mat-form-field appearance="fill" class="contactChoice">
            <mat-label>Choisir un contact de site de livraison</mat-label>
            <mat-select [ngModel]="selectedSiteContact" placeholder="Sites" (ngModelChange)="onChangeSiteContact($event)">
              <mat-option *ngFor="let s of contactsSite" [value]="s">{{s.firstName}} {{s.lastName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-raised-button color="primary" class='btn-reset' (click)='resetSiteContactForm()'>Réinitialiser le formulaire</button>
          <br>
          <form [formGroup]="contactSiteInformationGP">
            <p>
              <mat-form-field appearance="fill" class="contact">
                <mat-label>Nom</mat-label>
                <input matInput placeholder="Placeholder" formControlName="lastNameSite">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill" class="contact">
                <mat-label>Prénom</mat-label>
                <input matInput placeholder="Placeholder" formControlName="firstNameSite">
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill" class="contact">
                <mat-label>Téléphone</mat-label>
                <input matInput #phoneContactSite placeholder="Placeholder" formControlName="phoneContactSite"
                   minlength="10" maxlength="30" required>
                <mat-hint align="end">{{phoneContactSite.value.length}} / 10</mat-hint>
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill" class="contact">
                <mat-label>Téléphone secondaire</mat-label>
                <input matInput #phoneSContactSite placeholder="Placeholder" formControlName="phoneSContactSite"
                   minlength="10" maxlength="30">
                <mat-hint align="end">{{phoneSContactSite.value.length}} / 10</mat-hint>
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="fill" class="contact">
                <mat-label>Email</mat-label>
                <input matInput placeholder="aaaa@alphalink.fr" formControlName="emailSite" type="email" email='true' required>
              </mat-form-field>
            </p>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
      <br>
      <div>
        <button mat-button matStepperPrevious color="warn"> < Précédent</button>
        <button mat-button matStepperNext color="accent">Suivant > </button>
      </div>
    </mat-step>

    <!-- Recap step-->
    <mat-step>
      <ng-template matStepLabel>Récapitulatif de la commande</ng-template>
      <div class="row">
        <!-- Recap general-->
        <div class="col-sm">
          <div class="card">
            <div class="card-header">
              Informations générales
            </div>
            <div class="card-body">
              <table class="table table-stripped">
                <tr>
                  <th>Type de produit</th>
                  <td>{{ data.product }}</td>
                </tr>
                <tr>
                  <th>Boucle</th>
                  <td>{{ data.opticalLoop }}</td>
                </tr>
                <tr>
                  <th>Partenaire</th>
                  <td>{{ data.client }}</td>
                </tr>
                <tr>
                  <th>Sous traitant</th>
                  <td>{{ data.subcontractor }}</td>
                </tr>
                <tr>
                  <th>Agence</th>
                  <td>{{ data.agence }}</td>
                </tr>
                <tr>
              </table>
            </div>
          </div>
        </div>
        <!-- Recap site-->
        <div class="col-sm">
          <div class="card">
            <div class="card-header">
              Informations de site
            </div>
            <div class="card-body">
              <table class="table table-stripped">
                <tr>
                  <th>Nom de site</th>
                  <td>{{ siteInfomationGP.value.siteName }}</td>
                </tr>
                <tr>
                  <th>Adresse</th>
                  <td>
                    {{ siteInfomationGP.value.streetNum }} {{ siteInfomationGP.value.streetName }}
                    {{ siteInfomationGP.value.postalCode }} {{ siteInfomationGP.value.city }}
                  </td>
                </tr>
                <tr>
                  <th>Bâtiment </th>
                  <td>{{ siteInfomationGP.value.building }}</td>
                  <th>Étage </th>
                  <td>{{ siteInfomationGP.value.floor }} </td>
                  <th>Porte </th>
                  <td>{{ siteInfomationGP.value.door }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- Recap contact client-->
        <div class="col-sm">
          <div class="card">
            <div class="card-header">
              Informations de contact Partenaire
            </div>
            <div class="card-body">
              <table class="table table-stripped">
                <tr>
                  <th>Nom de contact client</th>
                  <td>{{ contactClientInformationGP.value.firstNameClient }} {{ contactClientInformationGP.value.lastNameClient }}</td>
                </tr>
                <tr>
                  <th>Émail</th>
                  <td>{{ contactClientInformationGP.value.emailClient}}</td>
                </tr>
                <tr>
                  <th>Télephone</th>
                  <td>{{ contactClientInformationGP.value.phoneContactClient}}</td>
                </tr>
                <tr>
                  <th>Télephone secondaire</th>
                  <td>{{ contactClientInformationGP.value.phoneSContactClient}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- Recap contact site-->
        <div class="col-sm">
          <div class="card">
            <div class="card-header">
              Informations de contact site de livraison
            </div>
            <div class="card-body">
              <table class="table table-stripped">
                <tr>
                  <th>Nom de contact site de livraison</th>
                  <td>{{ contactSiteInformationGP.value.firstNameSite }} {{ contactSiteInformationGP.value.lastNameSite }}</td>
                </tr>
                <tr>
                  <th>Émail</th>
                  <td>{{ contactSiteInformationGP.value.emailSite}}</td>
                </tr>
                <tr>
                  <th>Télephone</th>
                  <td>{{ contactSiteInformationGP.value.phoneContactSite}}</td>
                </tr>
                <tr>
                  <th>Télephone secondaire</th>
                  <td>{{ contactSiteInformationGP.value.phoneSContactSite}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button mat-button matStepperPrevious color="accent">Précédent</button>
        <button mat-button-raised (click)="onSubmit()" class="btn btn-success">Valider</button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<div class="content" *ngIf="this.permissionService.hasPermission(current_user  , 'ORDER_ASSIGN');">
    <div class="row col-xl-12">
        <span matTooltip="Modifier le BE" >BE</span>
    </div>
    <div class="row col-xl-12">
        <mat-form-field appearance="fill" matTooltip="Modifier le BE" (click)="openAssignmentForm('engineeringOfficer')">
            <mat-label>{{ command?.engineeringOfficerId?.contactId?.firstName}} {{
                command?.engineeringOfficerId?.contactId?.lastName}}</mat-label>
            <input matInput placeholder="" readonly='true' />
        </mat-form-field>
    </div>
</div>
import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { environment } from 'src/environments/environment';
const ACTIVITY_API = environment.back_url + '/activities/';
const ORDERS_API = environment.back_url + '/orders/';
const config = { withCredentials: true}

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor() { }
  async getActivitiesByOrder(orderId: string){
    return (await  axios.get(`${ORDERS_API}${orderId}/activities`, config)).data.data;
  }

  
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const STATUS_API = environment.back_url + '/status/';
const STATUS_CATEGORIES_API = environment.back_url + '/statusCategories';
const config = { withCredentials: true}
@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private httpClient: HttpClient) { }

  private statusUpdatedSubject = new Subject<void>();
  statusUpdated$: Observable<void> = this.statusUpdatedSubject.asObservable();
  async getStatus() : Promise<any>{
    let response = axios.get(STATUS_API, config );
    return (await response).data.data
  }
  async getStatusCategories(){
    return (await axios.get(STATUS_CATEGORIES_API, config )).data.data
  }
  getOneStatus(id: string): Promise<any>{
    return axios.get(STATUS_API + id, config);
  }

  getFailureReasons(fieldType: string) {
    return axios.get(environment.back_url +'/failureReasons/'+fieldType, config)
  }

  async getStatusByCategory(reference: string) : Promise<any>{
    return (await axios.get(STATUS_API + 'StatusByCategories/' + reference, config)).data.data;
  }
  addStatus(status: any): Promise<any>{
    return  axios.post(STATUS_API, status, config);
  }
  addStatusCategory(statusCategory: any): Promise<any>{
    return  axios.post(`${STATUS_API}categories`, statusCategory, config);
  }
  
  updateStatus(status: any){
    return  axios.put(`${STATUS_API}${status?.id}`, status, config);
  }
  updateStatusCategory(statusCategory: any){
    return  axios.put(`${STATUS_API}categories/${statusCategory?.id}`, statusCategory, config);
  }
  async getStatusCategory(id: string) : Promise<any>{
    return (await axios.get(`${STATUS_API}categories/${id}`, config)).data.data;
  }

  triggerStatusUpdated() {
    this.statusUpdatedSubject.next();
  }
}

<br>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">
            <div  (click)="openStatusUpdateForm(element)">
                <mat-icon color="primary">edit</mat-icon>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Catégorie </th>
        <td mat-cell *matCellDef="let element"> {{ element?.statusCategoryId?.name }} </td>
    </ng-container>
    <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef> Référence </th>
        <td mat-cell *matCellDef="let element"> {{ element?.reference }} </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nom </th>
        <td mat-cell *matCellDef="let element"> {{ element?.name }} </td>
    </ng-container>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Déscription </th>
        <td mat-cell *matCellDef="let element"> {{ element?.description }} </td>
    </ng-container>
    <ng-container matColumnDef="maxDuration">
        <th mat-header-cell *matHeaderCellDef> Délai en heures </th>
        <td mat-cell *matCellDef="let element"> {{ element?.maxDurationInHours }} </td>
    </ng-container>
    <ng-container matColumnDef="assignedRole">
        <th mat-header-cell *matHeaderCellDef> Rôle assigné </th>
        <td mat-cell *matCellDef="let element"> {{ element?.assignedRoleId?.name }} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
// saved-filter.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const FILTER_API = environment.back_url + '/filters/'; ;
const config = { withCredentials: true}

@Injectable({
  providedIn: 'root',
})
export class SavedFilterService {
  private filterUpdatedSubject = new Subject<void>();
  filterUpdated$: Observable<void> = this.filterUpdatedSubject.asObservable();

  constructor() {
  }

  saveFilter(data: any) {
    return  axios.post(FILTER_API, data, config);
  }

  private isSameFilter(filter1: any, filter2: any): boolean {
    return filter1.name === filter2.name;
  }

  removeFilter(filter: any) {
    return  axios.delete(`${FILTER_API}${filter?._id}` , config);
  }

  async getSavedFilters(userId: string) {
      return  (await axios.get(`${FILTER_API}${userId}` , config)).data.data;
  }

  triggerFilterUpdated() {
    this.filterUpdatedSubject.next();
  }
}

<div class="col-xl-12">
    <div class="title-header">
        <td>
            <i class="fa-solid fa-envelope"></i>
        </td>
        <td><span class="title-text">Messages</span></td>
    </div>
    <div class="comment-header">
        <div class="row">
            <mat-radio-group appearance="standard" [(ngModel)]="commentFilter" (change)="OnChangeFilter($event)"
                aria-label="Favorite Color">
                <mat-radio-button value="all" class="mat-mdc-radio-button" color="primary">Tous</mat-radio-button>
                <mat-radio-button value="few" class="mat-mdc-radio-button" color="primary">État actuel
                </mat-radio-button>
                <mat-radio-button value="mails" class="mat-mdc-radio-button" color="primary">Mails</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <button mat-button color="accent" (click)="OpenAddFollowereModal()">
                    <mat-icon color="accent">add</mat-icon>
                    <span>Ajouter un(e) abonné(e) </span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-xl-12 ">
        <br>
        <mat-divider></mat-divider>
        <mat-form-field appearance="fill" class="full-width">
            <!-- Bind textarea to the form group -->
            <textarea matInput [formControl]="textControl" class="comment" rows="12"
                placeholder="{{ isEscalated ? 'Saisir ici votre message d\'escalade.' : 'Saisir ici votre message public.' }}"></textarea>
        </mat-form-field>
        <br>
    </div>

    <div class="row">
        <div class="col-xl-3">
            <button mat-raised-button class="send" color="primary" (click)="addComment(text)">Envoyer</button>
        </div>
        <div class="col-xl-2">
            <mat-slide-toggle [(ngModel)]="isEscalated" class="isEscalated" color="primary"
                [disabled]="currentEscalation && currentEscalation?.dateEnd === null ">Escalade</mat-slide-toggle>
        </div>
        <div class="col-xl-3">
            <mat-form-field appearance="fill" class="escalation" *ngIf="isEscalated">
                <mat-label>Cause d'escalade</mat-label>
                <mat-select [(ngModel)]="escalationCause" (ngModelChange)="onChangeEscalationCause($event)" required
                    [disabled]="currentEscalation && currentEscalation?.dateEnd === null">
                    <mat-option *ngFor="let escalation of escalations" [value]="escalation.id">
                        {{escalation?.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-xl-2">
            <button mat-raised-button color="warn" *ngIf="isEscalated && currentEscalation"
                class="btn-width descativateEscalation" (click)="descativateEscalation()">Désactiver l'escalade</button>
        </div>
    </div>

    

    <br>
    <mat-divider></mat-divider>
    <div *ngIf="comments.length < 1">
        <br>
        <span>Aucun commentaire à afficher.</span>

    </div>
    <div>
        <div *ngFor="let comment of combinedItems">
            <div class="mailContainer" *ngIf="comment?.type ==='mail'">
                <h2>
                    <i class="fa-solid fa-envelope"></i>
                    <span>Objet : {{comment?.object | titlecase}}</span>
                    <span class="status">{{comment?.statusId?.name}}</span>
                    <span class="transition">({{comment?.transitionId?.name}})</span>
                </h2>
            
                <div class="metadata">
                    <p>Date d'envoi : {{comment?.date | date: 'd MMM yyyy à HH:mm'}}</p>
                    <p>Destinataire : {{comment?.receiver}}</p>
                    <p *ngFor="let cc of comment.copyReceivers">CC : {{cc}}</p>
                </div>
            
                <button (click)="toggleMessage()" *ngIf="!showMessage">Afficher contenu</button>
                
                <p *ngIf="showMessage" [innerHTML]="comment.message" class="message"></p>
                <button (click)="toggleMessage()" *ngIf="showMessage">Cacher contenu</button>
            </div>
            <mat-card class="card" [ngClass]="comment?.escalationId ? 'locked' : 'public'" *ngIf="comment?.type === 'comment'">
                <mat-card-header>
                    <mat-card-title>
                        <div class="commentatorName">
                            <span *ngIf="comment.userId">{{comment.userId?.contactId?.firstName }}
                                {{comment?.userId?.contactId?.lastName }}</span>
                            <span *ngIf="!comment?.userId">Système</span>
                            <span class="badge badge-primary" *ngIf="comment?.statusId?.name">{{comment?.statusId?.name }}</span>
                        </div>
                        
                    </mat-card-title>
                    <mat-card-subtitle>{{this.datepipe.transform(comment.date, 'yyyy/MM/dd HH:mm')}}
                    </mat-card-subtitle>
                    <mat-icon *ngIf='comment?.escalationId' class="lock">warning</mat-icon>
                    <span class="causeBadge badge badge-danger"
                        *ngIf="comment">{{translateCause(comment?.escalationId?.cause)}}</span>
                    <div class="followers">
                        <button mat-icon-button [matMenuTriggerFor]="menu"
                            matTooltip="Voir les abonnés au moment de la publication de message">
                            <mat-icon>group</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <div class="existedFollowers" *ngIf="comment?.followersIds">
                                <tr *ngFor="let follower of comment?.followersIds">
                                    <td mat-menu-item>
                                        <mat-icon>person</mat-icon>
                                        {{follower?.contactId?.firstName}} {{follower?.contactId?.lastName}}
                                    </td>
                            </div>
                            <div class="existedFollowers" *ngIf="comment?.followersIds?.length < 1">
                                <mat-icon>person_off</mat-icon> Aucun(e) abonné(e)
                            </div>
                        </mat-menu>
                    </div>
                </mat-card-header>
                <mat-card-content id="mainDiv" [innerHTML]="comment?.message">
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

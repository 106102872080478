import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PdfService } from 'src/app/core/services/pdf.service';
import { QuotationService } from 'src/app/core/services/quotation.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';


@Component({
  selector: 'app-bdc',
  templateUrl: './bdc.component.html',
  styleUrls: ['./bdc.component.scss']
})



export class BDCComponent implements OnInit {
  @Input() command: any;
  @ViewChild('content', { static: true }) content!: ElementRef;

  dynamicArray: Item[] = [];
  current_user: any;

  constructor(private qutationService: QuotationService, private tokenService: TokenStorageService, private toastr: ToastrService, private pdfService: PdfService) { }

  ngOnInit(): void {
    this.current_user = this.tokenService.getUser();
  }

  addRow() {
    this.dynamicArray.push({ reference: '', description: '', quantity: 0, unitPrice: 0, totalPrice: 0 });
  }
  deleteRow(index: number) {
    this.dynamicArray.splice(index, 1);
  }
  getValues() {
    this.dynamicArray.forEach(dynamic => {
      dynamic.totalPrice = dynamic.quantity * dynamic.unitPrice;
    });
    let quotation = {
      name: "BDC",
      orderId: this.command._id,
      userId: this.current_user.userId,
      listItems: this.dynamicArray,
      totalPrice: this.dynamicArray.reduce((sum, item) => sum + item.totalPrice, 0)
    }
    let html = '<h1>Hello, World!</h1>';
    const fileName = 'my_document.pdf'; // Replace with your desired file name
    this.pdfService.generatePdfFromHtml(html, fileName);
    /*
    this.qutationService.addQuotation(quotation).then((response) => {
      this.toastr.success('Quotation enregistré !', 'Infos : ', { positionClass: 'toast-top-full-width' });
    }).catch(err => {
      console.error(err)
    })
    */
  }

  calculateTotalPrice(dynamic: any) {
    return dynamic.quantity * dynamic.unitPrice;
  }

}
interface Item {
  reference: string;
  description: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
}
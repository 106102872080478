import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RdvService } from 'src/app/core/services/rdv.service';

@Component({
  selector: 'app-rdv-view',
  templateUrl: './rdv-view.component.html',
  styleUrls: ['./rdv-view.component.scss']
})
export class RdvViewComponent implements OnInit {

  public rdvs : any[] = [];
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private rdvService: RdvService , public datepipe: DatePipe,) { }

  async ngOnInit(): Promise<void> {
    this.rdvs = await this.rdvService.getRdvsByOrder(this.data.element);
  }

}

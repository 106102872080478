import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/core/services/order.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-fci-ref',
  templateUrl: './fci-ref.component.html',
  styleUrls: ['../dt.component.scss' , './fci-ref.component.scss']
})
export class FciRefComponent implements OnInit {

  @Input() command: any;
  command_id!: string;
  referenceFCI: string = '';
  current_user!: any;
  hasPermission = false;
  hasRole = false;
  isActive = false;
  fciRegex: RegExp = /^F\d{11}$/;
  errorLog: string = "";
  constructor(public permissionService: PermissionService, private tokenService: TokenStorageService, private orderService: OrderService, private route: ActivatedRoute,) { }

  async ngOnInit(): Promise<void> {
    this.current_user = this.tokenService.getUser();
    this.hasPermission = this.permissionService.hasPermission(this.current_user, 'ORDER_UPDATE');
    this.hasRole = await this.permissionService.hasRole(this.current_user, this.command?.statusId?.assignedRoleId?.reference);
    this.isActive = await this.permissionService.canShowComponent('DT_FCI_REF', this.command_id);
    this.loadOrder();
    if (this.hasPermission) {
      this.orderService.orderUpdated$.subscribe(()=>{
        this.loadOrder();
      })
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes?.command?.currentValue?.content?.referenceFCI ) {
      this.loadOrder();
      this.orderService.orderUpdated$.subscribe(()=>{
        this.loadOrder();
      })
    }
  }

  saveReferenceFci(){
    if (this.fciRegex.test(this.referenceFCI)) {
      this.errorLog  = "";
      this.orderService.UpdateReferenceFci( this.command?._id, this.current_user?.userId, this.referenceFCI).then(async response => {
        this.loadOrder();
        this.orderService.triggerOrderUpdated();
      }).catch(err => console.error(err))
    }
    else {
      this.errorLog  = "Format invalide pour la réference FCI , il doit être composé d'un F suivi de 11 numéros (12 caracteres).<br> Les 6 derniers numéros formes la date JJMMAA de la demande."
    }
  }

  async loadOrder() {
    this.route.params.subscribe(params => {
      this.command_id = params['id'];
    })
    this.command = await this.orderService.getOrder(this.command_id);
    this.hasPermission = this.permissionService.hasPermission(this.current_user, 'ORDER_UPDATE');
    this.hasRole = await this.permissionService.hasRole(this.current_user, this.command?.statusId?.assignedRoleId?.reference);
    this.isActive = await this.permissionService.canShowComponent('DT_FCI_REF', this.command?._id);
    this.referenceFCI = this.command?.content?.referenceFCI;
  }
}

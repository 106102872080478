<div class="content" *ngIf="hasPermission">
<mat-label><b>Date RDV MES</b></mat-label>
<div class="content">
    <div class="alert">
        <mat-icon class="rdv-icon">calendar_today</mat-icon>
        <div class="rdv-options">
            <mat-icon matTooltip="Ajouter un RDV MES" (click)="addRdv()" *ngIf="permissionService.hasPermission(current_user  , 'RDV_CREATE') && hasRole && isActive">add</mat-icon>
        </div>

        <div class="file-content">
            <table class="table">
                <th></th>
                <th>Date et heure de RDV</th>
                <th>Déscription</th>
                <th>Commentaire</th>
                <th>Cause d'échec</th>
                <tr *ngFor="let rdv of rdvs" class="{{rdvMapping(rdv.status)?.color}}"
                    matTooltip="{{rdv?.failureReason}}">
                    <td>
                        <mat-icon matTooltip="{{rdvMapping(rdv.status)?.translate}}">{{rdvMapping(rdv.status)?.icon}}
                        </mat-icon>
                    </td>
                    <td>
                        {{datepipe.transform(rdv.dateStart, 'dd/MM/yyyy')}} De {{datepipe.transform(rdv.dateStart,
                        'HH:mm')}} à {{datepipe.transform(rdv.dateEnd, 'HH:mm')}}
                    </td>
                    <td>
                        {{ rdv?.description }}
                    </td>
                    <td>
                        {{ rdv?.comment }}
                    </td>
                    <td>
                        {{ rdv?.failureReason }}
                    </td>
                    <td *ngIf="['DRAFT'].includes(rdv.status)">
                        <mat-icon matTooltip="Modifier le status de RDV" (click)='updateRdv(rdv)' *ngIf="permissionService.hasPermission(current_user  , 'RDV_UPDATE') && hasRole && isActive " >edit</mat-icon>
                    </td>
                    <td *ngIf="rdv.status === 'DRAFT'">
                        <mat-icon matTooltip="Supprimer le RDV" color='warn' (click)='deleteRdv(rdv)' *ngIf="permissionService.hasPermission(current_user  , 'RDV_DELETE') && hasRole && isActive  ">delete
                        </mat-icon>
                    </td>
                </tr>
            </table>
            <br>
        </div>

    </div>
</div>
</div>
<div class="col-xl-12">
    <div class="rt">
        <h2 class='title'>
            <mat-icon color="primary">mail</mat-icon> Derniers messages
        </h2>
    </div>
    <div *ngFor="let comment of comments">
        <mat-card class="card" [ngClass]="comment?.escalationId ? 'locked' : 'public'">
            <mat-card-header>
                <mat-card-title>
                    <div class="commentatorName">
                        <span *ngIf="comment.userId" >{{comment.userId?.contactId?.firstName }} {{comment?.userId?.contactId?.lastName }}</span>
                        <span *ngIf="!comment?.userId">Système</span>
                    </div>
                    <span class="badge badge-primary" *ngIf="comment">{{comment?.statusId?.name }}</span>
                </mat-card-title>
                <mat-card-subtitle>{{this.datepipe.transform(comment.date, 'yyyy/MM/dd HH:mm')}} </mat-card-subtitle>
                <mat-icon *ngIf='comment?.escalationId' class="lock">warning</mat-icon>
                <span class="causeBadge badge badge-danger"
                    *ngIf="comment">{{translateCause(comment?.escalationId?.cause)}}</span>
                <div class="followers">
                    <button mat-icon-button [matMenuTriggerFor]="menu"
                        matTooltip="Voir les abonnés au moment de la publication de message">
                        <mat-icon>group</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <div class="existedFollowers" *ngIf="comment?.followersIds">
                            <tr *ngFor="let follower of comment?.followersIds">
                                <td mat-menu-item>
                                    <mat-icon>person</mat-icon>
                                    {{follower?.contactId?.firstName}} {{follower?.contactId?.lastName}}
                                </td>
                            </tr>
                        </div>
                        <div class="existedFollowers" *ngIf="comment?.followersIds.length === 0">
                            <td mat-menu-item
                                matTooltip="Aucun abonné à la commande au moment de publication de message">
                                <mat-icon>person_off</mat-icon> Aucun abonné
                            </td>
                        </div>
                    </mat-menu>
                </div>
            </mat-card-header>
            <mat-card-content id="mainDiv" [innerHTML]="comment?.message">
            </mat-card-content>
        </mat-card>

    </div>

    <!--div class="col-xl-12 card" *ngFor="let comment of comments">
        <div class="mail-header row">
            <div class="col-xl-10">
                <span class="colxl12-text166">
                    Par :  {{comment?.userId?.contactId?.firstName }} {{comment?.userId?.contactId?.lastName }} / 
                    <span></span>
                </span>
                <span class="badge badge-primary">{{comment?.stateLogId?.statusId?.name }}</span>
            </div>
            <div class="col-xl-2">
                <span>
                    <span>Le {{this.datepipe.transform(comment?.date, 'yyyy/MM/dd HH:mm')}}</span>
                    <br />
                    <span></span>
                </span>
            </div>
        </div>
        <div class="col-xl-12 ">
            <div class="mail-content">
                <div class="mail-from">
                    De : {{comment?.userId?.contactId?.emailAddress }}
                </div>
                <br>
                {{ comment?.message }}
            </div>
        </div>
    </div-->
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AgenceService } from 'src/app/core/services/agence.service';
import { CompanyService } from 'src/app/core/services/company.service';

@Component({
  selector: 'app-agency-update-form',
  templateUrl: './agency-update-form.component.html',
  styleUrls: ['./agency-update-form.component.scss']
})
export class AgencyUpdateFormComponent implements OnInit {

  agencyInformationFG!: FormGroup;
  companies: any[] = [];
  constructor(private dialogRef: MatDialogRef<any>,private _formBuilder: FormBuilder, private router: Router,private companyService: CompanyService,private _snackBar: MatSnackBar,
    private agencyService: AgenceService , private toastrService: ToastrService,  @Inject(MAT_DIALOG_DATA) public data: any,) { }


  ngOnInit(): void {
    this.agencyInformationFG = this._formBuilder.group({
      siteName: new FormControl(this.data?.agency?.name, Validators.required),
      isDefault: [this.data?.agency?.isDefault],
      city: [this.data?.agency?.address?.cityName],
      postalCode:[this.data?.agency?.address?.cityZip],
      streetNum:[this.data?.agency?.address?.streetNum],
      streetName:[this.data?.agency?.address?.streetName],
      streetCode:[this.data?.agency?.address?.streetCode],
      building:[this.data?.agency?.address?.building],
      stair:[this.data?.agency?.address?.stair], 
      floor:[this.data?.agency?.address?.floor], 
      door:[this.data?.agency?.address?.door],
      siret: new FormControl(this.data?.agency?.siret, []),
      company: [this.data?.agency?.companyId?._id, Validators.required],
    });

   
  }

  onSubmit() {
    let agency = {
      id: this.data.agency?._id,
      name: this.agencyInformationFG.value.siteName,
      address: {
        cityCode : this.agencyInformationFG.value.postalCode,
        cityZip : this.agencyInformationFG.value.postalCode,
        cityName : this.agencyInformationFG.value.city,
        streetCode : this.agencyInformationFG.value.streetCode,
        streetName : this.agencyInformationFG.value.streetName,
        streetNum : this.agencyInformationFG.value.streetNum,
        building : this.agencyInformationFG.value.building,
        stair : this.agencyInformationFG.value.stair,
        floor : this.agencyInformationFG.value.floor,
        door : this.agencyInformationFG.value.door,
        hexacle : this.agencyInformationFG.value.streetCode,
        pto : this.agencyInformationFG.value.pto
      },
      ndi: this.agencyInformationFG.value.ndi,
      siret: this.agencyInformationFG.value.siret,
      companyId: this.agencyInformationFG.value.company,
      isDefault:  this.agencyInformationFG.value.isDefault,
    }
    
    this.agencyService.updateAgence(agency).then((response) => {
      if (response.data.error == false){
        this._snackBar.open('Agence mise à jour avec succès', 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
        this.agencyService.triggerAgenceUpdated();
      }else{
        this._snackBar.open("Mise à jour d'Agence a echoué", 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
      }
    }).catch(err => {
      this._snackBar.open("Mise à jour d'Agence a echoué", 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    }).finally(()=>{
      //window.location.reload();
      this.agencyService.triggerAgenceUpdated();
      this.dialogRef.close();
    })
  }

}

import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { environment } from 'src/environments/environment';
const SITE_API = environment.back_url + '/sites/';
const config = { withCredentials: true}

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor() { }

  getSites(){
    return axios.get(SITE_API, config)
  }

  addSite(site: any){
    return axios.post(SITE_API, site, config)
  }
}

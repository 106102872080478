import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FilterService } from 'src/app/core/services/filter.service';
import { OrderService } from 'src/app/core/services/order.service';

@Component({
  selector: 'app-order-redirect-component',
  templateUrl: './order-redirect-component.component.html',
  styleUrls: ['./order-redirect-component.component.scss']
})
export class OrderRedirectComponentComponent implements OnInit {

  constructor(private orderService: OrderService, private route: ActivatedRoute, private router: Router, private filterService: FilterService,) { }
  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(async params => {
      const reference = params.get('reference');
      const routePath = this.route?.snapshot?.url[0].path;
      if (reference) {
        if (routePath === 'mae') {
          let order = await from(this.orderService.getOrderByReference(reference)).pipe(
            catchError(error => {
              // Check if the error status is 424 or handle other error scenarios
              if (error.status === 424) {
                // Handle 424 error
                console.error('Order not found');
              } else {
                // Handle other errors
                console.error('An error occurred:', error);
              }
          
              return of(null); // Return a default value or null for unsuccessful requests
            })
          ).toPromise();
          if (order && order?._id) {
            // Redirect to order/:id route
            this.router.navigate(['/order', order?._id]);
          } else {
            this.router.navigate(['/notFound']);
          }
        }
        else  {
          let orders = await from(this.orderService.getOrderByEmaReference(reference)).pipe(
            catchError(error => {
              if (error.status === 424) {
                console.error('Order not found');
              } else {
                console.error('An error occurred:', error);
              }
          
              return of(null); 
            })
          ).toPromise();

          if (Array.isArray(orders) && orders.length > 0) {
            if (orders.length === 1) {
              this.router.navigate(['/order', orders[0]?._id]);
            }
            else {
              let orderReferences = orders.map(o => o.reference);
              // Set filter data
              this.filterService.setFormFilter({assignedRole: "true", orderNumber: orderReferences})
            
              // Navigate to command-table route
              this.router.navigate(['/orders']);
            }
          } else {
            this.router.navigate(['/notFound']);
          }
        }
      }
      else {
        this.router.navigate(['/notFound']);
      }
    });

  }


}
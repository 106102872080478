import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { authInterceptorProviders } from './core/interceptors/auth.interceptor';
import { CustomInterceptor } from './core/interceptors/custom.interceptor';
import { CommonModule } from '@angular/common';
import { MatTableModule} from '@angular/material/table';
import { ToastrModule } from 'ngx-toastr';

import { DefaultModule } from './pages/private/default.module';




registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    ToastrModule.forRoot(),
    DefaultModule,
    HttpClientModule,
    FormsModule,                            
    ReactiveFormsModule,
    CommonModule
  ],
  providers: [authInterceptorProviders , { provide: LOCALE_ID, useValue: 'fr' }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Inject } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RdvService } from 'src/app/core/services/rdv.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent implements OnInit {



  constructor(private dialogRef: MatDialogRef<ConfirmationDialogComponent> , @Inject(MAT_DIALOG_DATA) public data: any, private rdvService: RdvService) { }

  ngOnInit() {
  }

  accept(){
    if ( this.data.orderId ) {
      this.rdvService.rejectOrderRdvs(this.data.orderId , this.data.comment, this.data.type);
      this.rdvService.triggerRdvUpdated();
      this.dialogRef.close();
    }
  }
  decline(){
    this.dialogRef.close()
  }
  dismiss(){
    this.dialogRef.close()
  }

}

<mat-form-field>
    <mat-label>Recherche</mat-label>
    <input matInput placeholder="...." (keyup)="applySearch($event)">
</mat-form-field>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let element"> {{ element?._id }} </td>
      </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef> Nom </th>
      <td mat-cell *matCellDef="let element"> {{ element?.lastName }} </td>
    </ng-container>
    <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef> Prénom </th>
        <td mat-cell *matCellDef="let element"> {{ element.firstName }} </td>
      </ng-container>
      <ng-container matColumnDef="phone1">
        <th mat-header-cell *matHeaderCellDef> Téléphone </th>
        <td mat-cell *matCellDef="let element"> {{ element?.phone1 }} </td>
      </ng-container>
      <ng-container matColumnDef="phone2">
        <th mat-header-cell *matHeaderCellDef> Téléphone secondaire  </th>
        <td mat-cell *matCellDef="let element"> {{ element?.phone2 }} </td>
      </ng-container>
      <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef>  Adresse mail </th>
        <td mat-cell *matCellDef="let element"> {{ element?.emailAddress }} </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openNotificationDialog(row._id)"></tr>
</table>
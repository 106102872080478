<h1 mat-dialog-title ><span >Validation de fichier "{{ data?.element?.name}}"</span></h1>
<form [formGroup]="myForm" (ngSubmit)="submit()">
    <mat-label><b>Date de  création :                  </b> {{ datepipe.transform(data?.element?.date, 'dd/MM/yyyy HH:mm')}}</mat-label>
    <br>
    <mat-label><b>Date de  dernière de modification  : </b> {{ datepipe.transform(data?.element?.dateLastModification, 'dd/MM/yyyy HH:mm')}}</mat-label>
    <div class="form-group">
        <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select formControlName="status"[(ngModel)]="defaultStatus">
                <mat-option *ngFor="let status of documentStatus" [value]="status.id">{{status?.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <button class="btn btn-primary" type="submit">Confirmer</button>
</form>
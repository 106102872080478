import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AgenceService } from 'src/app/core/services/agence.service';
import { CompanyService } from 'src/app/core/services/company.service';

@Component({
  selector: 'app-new-agency',
  templateUrl: './new-agency.component.html',
  styleUrls: ['./new-agency.component.scss']
})
export class NewAgencyComponent implements OnInit {

  agencyInformationFG!: FormGroup;
  companies: any[] = [];
  constructor(private _formBuilder: FormBuilder, private router: Router,private companyService: CompanyService,
    private agencyService: AgenceService , private toastrService: ToastrService,) { }

  ngOnInit(): void {
    this.agencyInformationFG = this._formBuilder.group({
      siteName: new FormControl(null, Validators.required),
      city: [null],
      postalCode:[null],
      streetNum:[null],
      streetName:[null],
      streetCode:[null],
      building:[null], stair:[null], floor:[null], door:[null],
      siret: new FormControl(null, []),
      company: [null, Validators.required],
    });
    this.companyService.getCompanies().then((response) =>{
      this.companies = response.data.data.filter(
        (item: any) => item.type === "SUBCONTRACTOR"
      );
    });
  }

  async onSubmit(){
    let agency = {
      name: this.agencyInformationFG.value.siteName,
      address: {
        cityCode : this.agencyInformationFG.value.postalCode,
        cityZip : this.agencyInformationFG.value.postalCode,
        cityName : this.agencyInformationFG.value.city,
        streetCode : this.agencyInformationFG.value.streetCode,
        streetName : this.agencyInformationFG.value.streetName,
        streetNum : this.agencyInformationFG.value.streetNum,
        building : this.agencyInformationFG.value.building,
        stair : this.agencyInformationFG.value.stair,
        floor : this.agencyInformationFG.value.floor,
        door : this.agencyInformationFG.value.door,
        hexacle : this.agencyInformationFG.value.streetCode,
        pto : this.agencyInformationFG.value.pto
      },
      ndi: this.agencyInformationFG.value.ndi,
      siret: this.agencyInformationFG.value.siret,
      companyId: this.agencyInformationFG.value.company
    }

    this.agencyService.addAgence(agency).then((response) =>{
      if (response.data.error == false){
        this.toastrService.success('', 'Agence crée avec succès !', {positionClass: 'toast-top-center'})
        this.router.navigate(['/admin'])
      }else{
        window.location.reload();
      }
    }).catch(err => {console.error("Ajout d'agence echoué ")});
  }

}

<div  *ngIf="hasPermission && this.permissionService.hasRole(this.current_user, command?.statusId?.assignedRoleId?.reference)">
    <tr>
        <th>Status RDV VT</th>
    </tr>
    <div class="card box alert alert-warning"   readonly='true'>
        Merci d'attendre le choix de partenaire pour les RDV.
        <br>
        <div *ngIf= "permissionService.hasPermission(this.current_user  , 'RDV_UPDATE');"> 
            * Vous pouvez accèder dircetement aux rdvs proposés au partenaire via le lien suivant : 
            <a  href="order/rdvChoice/VT/{{command?._id}}" target="_blank">Lien vers les Rdvs VT porposés </a>
        </div>
    </div>
    
</div>
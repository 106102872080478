import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommentService } from 'src/app/core/services/comment.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { OrderService } from 'src/app/core/services/order.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-last-message',
  templateUrl: './last-message.component.html',
  styleUrls: ['./last-message.component.scss']
})
export class LastMessageComponent implements OnInit {
  @Input() command: any;
  public comments: any[] = []
  command_id: any ;
  current_user!: any;
  escalations : any = [
    {id: 'DEADLINE_EXCEEDED', name : 'DÉPASSEMENT DU DÉLAI'}, 
    {id: 'TECHNICAL_PROBLEM', name : 'PROBLEME TECHNIQUE'}, 
    {id: 'ADMINISTRATIF_PROBLEM', name : 'PROBLEME ADMINISTRATIF'}
  ]
  constructor(private commentService: CommentService, private tokenService: TokenStorageService, private orderService: OrderService,
    public datepipe: DatePipe, private route: ActivatedRoute) { 
      this.current_user = this.tokenService.getUser();
    }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(params => {
      this.command_id = params['id'];
    });
    this.commentService.getTwoLastComment(this.command_id).then((response) =>{
      this.comments = _.get(response ,'data.data')
    })
  }

  translateCause(name : string) {
    let cause = this.escalations.find((item: any) => item.id === name );
    return cause?.name;
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RdvService } from 'src/app/core/services/rdv.service';
import { StatusService } from 'src/app/core/services/status.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { RdvFormComponent } from '../rdv-form/rdv-form.component';

@Component({
  selector: 'app-rdv-update',
  templateUrl: './rdv-update.component.html',
  styleUrls: ['./rdv-update.component.scss']
})
export class RdvUpdateComponent implements OnInit {

  RdvStatusMapping: any = [
    {name: 'DRAFT',  icon: 'question_mark', color: 'text-secondary' , translate: "Brouillon" },
    {name: 'WAITING_VALIDATION', icon: 'hourglass_bottom', color: 'text-primary' , translate: "En attente de validation" },
    {name: 'ACCEPTED', icon: 'done', color: 'text-success' , translate: "Accepté par le partenaire" },
    {name: 'SCHEDULED', icon: 'hourglass_top', color: 'text-primary' , translate: "Programmé (confirmé par l'intervenant" },
    {name: 'REJECTED', icon: 'block', color: 'text-warning' , translate: "Rejetée" },
    {name: 'CANCELED', icon: 'auto_delete', color: 'text-light' , translate: "Annulé" },
    {name: 'FAILED', icon: 'warning', color: 'text-danger' , translate: "En échec" },
    {name: 'SUCCESS', icon: 'done_all', color: 'text-success' , translate: "Réalisé" }
  ]
  reasons: any[] = [];
  error = '';
  rdvUpdateForm!: FormGroup;
  current_user: any = null;
  defaultSlot: any = null;
  public slots  = [
    {start: '09:00', end: '11:00'},
    {start: '10:00', end: '12:00'},
    {start: '14:00', end: '16:30'}
  ]
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _formBuilder: FormBuilder,private rdvService: RdvService,
  private dialogRef: MatDialogRef<RdvFormComponent>, private statusService: StatusService, private tokenService: TokenStorageService) { 
    
  }

  ngOnInit(): void {
    this.current_user = this.tokenService.getUser();
    let rdvDateStart = new Date(this.data.rdv.dateStart);
    let rdvDateEnd = new Date(this.data.rdv.dateEnd);
    this.defaultSlot = {
      start: `${rdvDateStart.getHours()}:${rdvDateStart.getMinutes()  === 0 ? '00' : rdvDateStart.getMinutes()}`,
      end: `${rdvDateEnd.getHours()}:${rdvDateEnd.getMinutes() === 0 ? '00' : rdvDateEnd.getMinutes() }`
    }
    this.statusService.getFailureReasons(this.data?.type).then(response => {
      this.reasons = response.data.data
    }).catch(err => {
      console.error(err)
    })

    this.rdvUpdateForm = this._formBuilder.group({
      dateStart:[{ value: new Date(this.data.rdv.dateStart), disabled: true} , Validators.required],
      status: ['', Validators.required],
      failureReason: [''],
    });
    this.rdvUpdateForm.patchValue({status: this.data.rdv.status})
  }

  updateRdv() {
    const formData = new FormData();
    if (this.rdvUpdateForm.value?.status) {
      formData.append('rdvId', this.data?.rdv?._id);
      formData.append('status', this.rdvUpdateForm.value.status);
      formData.append('userId', this.current_user?.userId);
      formData.append('orderId', this.data?.rdv?.orderId);
      if(!['FAILED', 'CANCELED'].includes(this.rdvUpdateForm.get('status')?.value)) {
        formData.append('failureReason', '');
        this.rdvService.updateRdvStatus(formData).then(async response =>{
          this.rdvService.triggerRdvUpdated();
          this.dialogRef.close();
        }).catch(err => console.error(err))
      }
      else if (['FAILED', 'CANCELED'].includes(this.rdvUpdateForm.get('status')?.value) && this.rdvUpdateForm.value?.failureReason !== '') {
        formData.append('failureReason', this.rdvUpdateForm.value.failureReason);
        this.rdvService.updateRdvStatus(formData).then(async response =>{
          this.rdvService.triggerRdvUpdated();
          this.dialogRef.close();
        }).catch(err => console.error(err))
        
      }
      else {
        this.error = "Merci de renseigner une cause d'échec "
      }
    }
    else {
      this.error = "Merci de renseigner un status"
    }
  }

  onStatusChanged(event:any){
    this.error = '';
  }

}

<br>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions    </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openRoleModal(element)">
                    <mat-icon>assignment_ind</mat-icon>
                    <span>Voir</span>
                  </button>
                <button mat-menu-item (click)="openRoleUpdateForm(element)">
                  <mat-icon>edit</mat-icon>
                  <span>Modifier</span>
                </button>
              </mat-menu>
        </td>
      </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Intitulé </th>
        <td mat-cell *matCellDef="let element"> {{ element?.name }} </td>
    </ng-container>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let element"> {{ element?.description }} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
</table>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AgenceService } from 'src/app/core/services/agence.service';

@Component({
  selector: 'app-agence-modal',
  templateUrl: './agence-modal.component.html',
  styleUrls: ['./agence-modal.component.scss']
})
export class AgenceModalComponent implements OnInit {

  agence : any;
  constructor(private agenceService: AgenceService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.agenceService.getAgence(this.data?.agencyId).then((response: any) => {
      this.agence = response.data.data;
    }).catch(err => {
      console.error(err);
    });
  }

  

}

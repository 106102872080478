<h1 mat-dialog-title><span>Agence - Mise à jour</span></h1>
<div class="row standard">
    <div class="col-xl-2"></div>
    <div class="col-xl-9">
        <form [formGroup]="agencyInformationFG">
            <p>
                <mat-form-field class="agencyName" appearance="fill">
                    <mat-label>Nom d'agence</mat-label>
                    <input matInput placeholder="Nom d'agence" value="{{ data?.agency?.name }}" formControlName="siteName" required>
                </mat-form-field>
                <span class="company alert alert-secondary">
                    <mat-label><b>Sous traitant :  </b></mat-label>
                    <mat-label>{{ data?.agency?.companyId?.name }}</mat-label>
                </span>
                <mat-slide-toggle class="inputText3" formControlName="isDefault" class="slider" [checked]="data?.agency?.isDefault"
                    color="primary">Par défaut</mat-slide-toggle>
                

            </p>
            <br>
            <p>
                <mat-form-field class="postal_code" appearance="fill">
                    <mat-label>Code Postal</mat-label>
                    <input matInput #postalCode value="{{ data?.agency?.address?.cityZip }}"  maxlength="5" placeholder="Ex. 44210" formControlName="postalCode">
                    <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                </mat-form-field>
                <mat-form-field class="city"  appearance="fill">
                    <mat-label>Ville</mat-label>
                    <input matInput placeholder="Ex. Pornic" value="{{ data?.agency?.address?.cityName }}" formControlName="city">
                </mat-form-field>

            </p>
            <br>
            <p>
                <mat-form-field class="streetNum" appearance="fill">
                    <mat-label>Numéro de Rue</mat-label>
                    <input type="text" value="{{ data?.agency?.address?.streetNum }}"  matInput placeholder="Ex. 20" formControlName="streetNum" />
                </mat-form-field>
                <mat-form-field class="streetName" appearance="fill">
                    <mat-label>Rue</mat-label>
                    <input type="text" value="{{ data?.agency?.address?.streetName }}"  matInput placeholder="Ex. Rue Pont d'argent " formControlName="streetName" />
                </mat-form-field>
                <mat-form-field class="streetCode" appearance="fill">
                    <mat-label>Code rivoli</mat-label>
                    <input type="text" value="{{ data?.agency?.address?.streetCode }}"  matInput placeholder="Ex. 3570" formControlName="streetCode" />
                </mat-form-field>
            </p>
            <p>
                <mat-form-field class="building" appearance="fill">
                    <mat-label>Bâtiment</mat-label>
                    <input type="text" value="{{ data?.agency?.address?.building }}"  matInput placeholder="Ex. 20" formControlName="building" />
                </mat-form-field>
                <mat-form-field class="stair" appearance="fill">
                    <mat-label>Escalier</mat-label>
                    <input type="text" value="{{ data?.agency?.address?.stair }}"  matInput formControlName="stair" />
                </mat-form-field>
                <mat-form-field class="floor" appearance="fill">
                    <mat-label>Étage</mat-label>
                    <input type="text" value="{{ data?.agency?.address?.floor }}"  matInput placeholder="Ex. 1" formControlName="floor" />
                    <mat-hint>0 si RdC</mat-hint>
                </mat-form-field>
                <mat-form-field class="door" appearance="fill">
                    <mat-label>Porte</mat-label>
                    <input type="text" value="{{ data?.agency?.address?.door }}"  matInput placeholder="Ex. 4" formControlName="door" />
                </mat-form-field>
            </p>
            <br>

            <p>
                <mat-form-field class="siret" appearance="fill">
                    <mat-label>Siret</mat-label>
                    <input matInput #siret type="text" formControlName="siret" placeholder="11223344556677"
                        pattern="[0-9]{14}" value="{{ data?.agency?.siret }}"  minlength="14" maxlength="14" >
                    <mat-hint align="end">{{siret.value.length}} / 14</mat-hint>
                </mat-form-field>
            </p>

            <div>
                <button mat-button-raised (click)="onSubmit()" class="btn btn-success">Valider</button>
            </div>
        </form>
    </div>
    <div class="col-xl-2"></div>
</div>
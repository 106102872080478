<h1 mat-dialog-title><span>Ajout d'un abonné</span>
    <span class="close">
        <mat-icon (click)="cancel()">close</mat-icon>
    </span>
</h1>

<mat-form-field>
    <mat-label>Recherche</mat-label>
    <input matInput placeholder="Recherche ...." (keyup)="applySearch($event)">
</mat-form-field>


<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nom et prénom</th>
        <td mat-cell *matCellDef="let element"> {{ element?.contactId?.lastName }} {{ element?.contactId?.firstName }} </td>
    </ng-container>
    <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef> Entreprise</th>
        <td mat-cell *matCellDef="let element"> {{ element?.agencyId?.companyId?.name }} </td>
    </ng-container>
    <ng-container matColumnDef="emailAddress">
        <th mat-header-cell *matHeaderCellDef> Adresse mail </th>
        <td mat-cell *matCellDef="let element"> {{ element?.contactId?.emailAddress }} </td>
    </ng-container>
    <ng-container matColumnDef="phone1">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{ element?.contactId?.phone1 }} </td>
    </ng-container>
    <ng-container matColumnDef="add">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> 
            <button  mat-button color="accent" (click)="addSubscriber(element)">
                <mat-icon color="accent">add</mat-icon>
                <span>Ajouter</span>
              </button>
        </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
</table>

<mat-paginator #paginator [length]="totalData" [pageSizeOptions]="pageSizes" showFirstLastButtons>
</mat-paginator>
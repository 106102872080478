import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';


import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(
        private tokenService: TokenStorageService,
        private router: Router
    ) { }
    canActivate(): boolean {
        let current_user = this.tokenService.getUser();
        let isAdmin = current_user.role[0].includes('SUPER_ADMIN');
        if (!isAdmin) {
            this.router.navigate(['/orders']); 
            return false;
        }
        return true;
    }
}
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { RoleService } from 'src/app/core/services/role.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from 'src/app/core/services/company.service';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatChipInputEvent } from '@angular/material/chips';
const ENTER = 13;
const COMMA = 188;

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  hide = true;
  updateForm!: FormGroup;
  updatePassword!: FormGroup;
  user: any = {};
  roles:any = []
  emailAddresses: string[] = [];
	emailCCAuto: any = [];
	emailFollower: any = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(public datepipe: DatePipe, private _snackBar: MatSnackBar, private userService: UserService, private _Activatedroute:ActivatedRoute,private toastrService: ToastrService,
              private roleService: RoleService, private _formBuilder: FormBuilder, private companyService: CompanyService, private router: Router) { }

  ngOnInit(): void {
    let id = ""
    this._Activatedroute.paramMap.subscribe(params => {
      id = String(params.get('id'));
    });
    this.updateForm = this._formBuilder.group({
      contactLastName: ['', Validators.required],
      contactFirstName:['', Validators.required],
      contactEmailAddress: ['', Validators.required],
      username: ['', Validators.required],
      contactPhone1:  ['', Validators.required],
      contactPhone2:  ['', Validators.required],
      emailCCAddresses:  [''],
      emailCCAuto: [''],
      emailFollower:  [''],

    });
    this.updatePassword = this._formBuilder.group({
      old_password: ['', Validators.required],
      new_password:['', [
        Validators.required, 
        Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
      ]],
      new_password_confirmation: ['', [
        Validators.required, 
        Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
      ]]

    });
    this.userService.getProfile(id).then((response) =>{
      this.user = response.data.data;
      this.emailAddresses = this.user?.emailCCAddresses || [];
      this.updateForm.patchValue({company: this.user.managerId? this.user?.companyId?._id : null})
      this.updateForm.patchValue({manager: this.user.managerId? this.user.managerId._id : null })
      this.updateForm.patchValue({contactLastName: this.user.contactId? this.user.contactId.lastName : null})
      this.updateForm.patchValue({contactFirstName: this.user.contactId? this.user.contactId.firstName : null})
      this.updateForm.patchValue({contactEmailAddress: this.user.contactId? this.user.contactId.emailAddress : null})
      this.updateForm.patchValue({contactPhone1: this.user.contactId? this.user.contactId.phone1 : null})
      this.updateForm.patchValue({contactPhone2: this.user.contactId? this.user.contactId.phone2 : null})
      this.updateForm.patchValue({isActive: this.user.isActive})
      this.updateForm.patchValue({username: this.user.username})
      this.updateForm.patchValue({ emailCCAddresses: this.emailAddresses });
      this.updateForm.patchValue({ emailCCAuto: this.user.emailCCAuto });
      this.updateForm.patchValue({ emailFollower: this.user.emailFollower });
    })

    this.emailCCAuto = [
      {id: 'NO' , name: 'Je ne souhaite pas être en copie de mails'},
      {id: 'TEAM' , name: 'Je souhaite être en copie des mails envoyé à mon équipe'}, 
      {id: 'AGENCY' , name: 'Je souhaite être en copie des mails envoyé aux utilisateurs de mon agence'} , 
      {id: 'COMPANY' , name: 'Je souhaite être en copie des mails envoyé aux utilisateurs de mon entreprise'}
    ]
    this.emailFollower = [
      {id: 'NO' , name: "Ne pas m'envoyer d'email, meme lorsque je suis follower d'une commande"},
      {id: 'ACTIVE_ESCALATION_ONLY' , name: "M'envoyer un email, seulement lorsque je suis follower d'une commande et qu'elle est en escalade (recommandé)"}, 
      {id: 'SINCE_ESCALATION' , name: "M'envoyer un email, seulement lorsque je suis follower d'une commande et qu'elle a été en escalade"} , 
      {id: 'ALLWAYS' , name: "M'envoyer un email, seulement lorsque je suis follower d'une commande, peu importe son état d'escalade. (déconseillé)"}
    ]

  }

  updateProfile(){
    let user = {
      lastName: this.updateForm.value.contactLastName,
      firstName:  this.updateForm.value.contactFirstName,
      emailAddress: this.updateForm.value.contactEmailAddress,
      username: this.updateForm.value.username,
      phone1: this.updateForm.value.contactPhone1,
      phone2: this.updateForm.value.contactPhone2,
      emailCCAddresses:  this.emailAddresses,
      emailCCAuto:  this.updateForm.value.emailCCAuto,
      emailFollower:  this.updateForm.value.emailFollower,
      type: 'USERS'
    }
    this.userService.updateProfile((this.user?._id?._id || this.user?._id), user).then((response) =>{
      if (!response.data.error){
        this.toastrService.success('', 'Informations d\'tilisateur mises à jour avec succès !', {positionClass: 'toast-top-center'})
        this.ngOnInit();
      }else
      {
        this.toastrService.error('', 'Erreur est surevnue lors de la mise jour d\'utilisateur' ,{positionClass: 'toast-top-center'})
      }
    }).catch(err=>{
      this.toastrService.error('', 'Erreur est surevnue lors de la mise jour de vos coordonnées ' ,{positionClass: 'toast-top-center'})
    })
  }

  addEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      // Regular expression for email validation
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
      if (emailPattern.test(value)) {
        this.emailAddresses.push(value);
        this.updateForm.get('emailCCAddresses')?.setValue(this.emailAddresses);
        event.chipInput!.clear();
      } else {
        this._snackBar.open("Le format de mail n'est pas bon ", 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
      }
    }
  }

  removeEmail(email: string): void {
    const index = this.emailAddresses.indexOf(email);

    if (index !== -1) {
      this.emailAddresses.splice(index, 1);
      this.updateForm.get('emailCCAddresses')?.setValue(this.emailAddresses);
    }
  }
  updateUserPassWord(){
    if(this.updatePassword.value.new_password == this.updatePassword.value.new_password_confirmation){
      let user = {
        password: this.updatePassword.value.new_password,
        old_password: this.updatePassword.value.old_password
      }
      this.userService.updateUserPassWord((this.user._id._id || this.user._id), user).then((response) =>{
        if (!response.data.error){
          this.toastrService.success('', 'Informations d\'tilisateur mises à jour avec succès !', {positionClass: 'toast-top-center'})
          this.ngOnInit();
        }else
        {
          this.toastrService.error('', 'Erreur est surevnue lors de la mise jour d\'utilisateur' ,{positionClass: 'toast-top-center'})
        }
      }).catch(err=>{
        this.toastrService.error('', 'Erreur est surevnue lors de la mise jour de mot de passe ' ,{positionClass: 'toast-top-center'})
      })
    }else{
      this.toastrService.error('', 'Le nouveau mot de passe et la confirmation ne correspondent pas' ,{positionClass: 'toast-top-center'})
    }
  }
}

import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';


const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  

  constructor() {
    
  }

  signOut(): void {
    localStorage.clear();
  }

  public saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  /**
   * Check if an authenticated user is an ADMIN or not
   */
  public checkAdmin(): boolean {
    const data = localStorage.getItem(USER_KEY);
    if (data) {
      let user = JSON.parse(data);
      return user.role[0].includes('SUPER_ADMIN')
    } else {
      return false;
    }
  }

  /**
   * Check if a user is in an RACCO_ADMIN 
   */
  public checkRACCO_ADMIN(): boolean {
    const data = localStorage.getItem(USER_KEY);
    if (data) {
      let user = JSON.parse(data);
      return user.role[0].includes('RACCO_ADMIN')
    } else {
      return false;
    }
  }

  /**
   * Check if a user is in an RTC 
   */
  public checkRTC(): boolean {
    const data = localStorage.getItem(USER_KEY);
    if (data) {
      let user = JSON.parse(data);
      return user.role[0].includes('RTC')
    } else {
      return false;
    }
  }

  /**
   * Check if a user is in an ITC 
   */
  public checkITC(): boolean {
    const data = localStorage.getItem(USER_KEY);
    if (data) {
      let user = JSON.parse(data);
      return user.role[0].includes('ITC')
    } else {
      return false;
    }
  }

  /**
   * Check if a user is in an MBE 
   */
  public checkMBE(): boolean {
    const data = localStorage.getItem(USER_KEY);
    if (data) {
      let user = JSON.parse(data);
      return user.role[0].includes('MBE')
    } else {
      return false;
    }
  }

  /**
   * Check if the current token is expired
   */
  public isTokenExpired(): boolean {
    return false; // TO DO
  }
}

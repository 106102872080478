import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import axios from 'axios'; 
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const RDV_API = environment.back_url ;
const config = { withCredentials: true}
@Injectable({
  providedIn: 'root'
})
export class RdvService {

  private rdvUpdatedSubject = new Subject<void>();
  rdvUpdated$: Observable<void> = this.rdvUpdatedSubject.asObservable();
  constructor(private httpClient: HttpClient) { }
  async getRdvs() : Promise<any>{
    let response = axios.get(`${RDV_API}/rdvs`, config );
    return (await response).data.data
  }

  async getRdvById(rdvId: string) : Promise<any>{
    let response = axios.get(`${RDV_API}/rdvs/${rdvId}`, config );
    return (await response).data.data
  }
  async getRdvsByOrder(orderId: string) : Promise<any>{
    let response = axios.get(`${RDV_API}/orders/${orderId}/rdvs/`, config );
    return (await response).data.data
  }

  async getRdvsTypedByOrder(orderId: string, rdvType: string) : Promise<any>{
    let response = axios.get(`${RDV_API}/orders/${orderId}/rdvs/type/${rdvType}`, config );
    return (await response).data.data
  }

  async getRdvsByOrderStatus(orderId: string, rdvStatus: string) : Promise<any>{
    let response = axios.get(`${RDV_API}/orders/${orderId}/rdvs/status/${rdvStatus}`, config );
    return (await response).data.data
  }

  async getRdvsByOrderStatusTyped(orderId: string, rdvStatus: string, rdvType: string) : Promise<any>{
    let response = axios.get(`${RDV_API}/orders/${orderId}/rdvs/${rdvType}/status/${rdvStatus}`, config );
    return (await response).data.data
  }

  async getRdvsByOrderStatusPublic(orderId: string, rdvStatus: string, rdvType: string) : Promise<any>{
    let response = axios.get(`${RDV_API}/orders/${orderId}/rdvs/${rdvType}/status/${rdvStatus}/public`, config );
    return (await response).data.data
  }
  addRdv(rdv: any){
    return axios.post(`${RDV_API}/orders/${rdv?.orderId}/rdvs`, rdv, config)
  }

  async updateRdvStatus(data: FormData) {
    return (await axios.put(`${RDV_API}/orders/${data?.get('orderId')}/rdvs/status/${data?.get('rdvId')}`, data, config)).data.data
  }

  async updateRdvsDescription(orderId: string, userId: string, type: string, description: string) {
    return (await axios.put(`${RDV_API}/orders/${orderId}/rdvs/description`, { userId: userId, type: type, description: description}, config)).data.data
  }

  async rejectOrderRdvs(orderId: string, comment: string, rdvType: string) {
    return (await axios.put(`${RDV_API}/orders/rdvs/${rdvType}/reject` , {orderId: orderId , comment: comment}, config)).data.data
  }

  async defineRdvFailureReason(orderId: string, failureReason: string, rdvType: string) {
    return (await axios.put(`${RDV_API}/orders/${orderId}/rdvs/${rdvType}/failureReason` , { failureReason: failureReason}, config)).data.data
  }
  async selectRdv(orderId: string, rdvId: string , comment: string) {
    return (await axios.put(`${RDV_API}/orders/${orderId}/rdvs/${rdvId}/selectSlot` , {orderId: orderId , rdvId: rdvId , comment: comment}, config)).data.data
  }

  async searchRdvs(formdata: any){
    let api = RDV_API;
    api += `/rdvs/search?`;
    for (const key in formdata) {
      if (formdata.hasOwnProperty(key)) {
          const value = formdata[key];
          api += `${key}=${value}&`;
      }
    }
    
    return (await axios.get(api,  config)).data;
  }
 
  triggerRdvUpdated() {
    this.rdvUpdatedSubject.next();
  }
 
}


import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { StatusModalComponent } from 'src/app/pages/private/admin/components/status/status-modal/status-modal.component';
import { StatusUpdateFormComponent } from 'src/app/pages/private/admin/components/status/status-update-form/status-update-form.component';
import { StatusService } from 'src/app/core/services/status.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  displayedColumns: string[] = ['actions', 'category', 'reference', 'name',  'description', 'maxDuration', 'assignedRole' ];
  dataSource!: MatTableDataSource<any>;
  status: any = [];
  constructor(private statusService: StatusService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.statusService.statusUpdated$.subscribe(() =>{
      this.loadData();
    })
    this.loadData();
  }

  
  openStatusUpdateForm(status: any) {
    const dialogRef = this.dialog.open(StatusUpdateFormComponent, {
      data: {
        status: status,
      },
      width: '60%',
      height: '55%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  async loadData() {
    this.status = await this.statusService.getStatus();
    this.dataSource = new MatTableDataSource<any>(this.status);
  }
}

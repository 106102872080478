<h1 mat-dialog-title ><span *ngIf="agence">{{agence?.name}}</span></h1>

<div class='container'>
    <table class="table table-striped">
        <tr>
            <th scope="col">
                Nom d'agence : 
            </th>
            <td>
                {{ agence?.name }}
            </td>
            <th scope="col">
                Nom d'entreprise : 
            </th>
            <td>
                {{ agence?.companyId?.name }}
            </td>
        </tr>

        <tr>
            <th scope="col">
                Date de création : 
            </th>
            <td>
                {{ agence?.date }}
            </td>
            <th scope="col">
                Siret : 
            </th>
            <td>
                {{ agence?.siret}}
            </td>
        </tr>
        <tr>
            <th scope="col">
                Adresse : 
            </th>
            <td>
                {{agence?.address?.streetNum}} {{ agence?.address?.streetName }} {{agence?.address?.cityZip}} 
                {{agence?.address?.cityName}}
            </td>
            <th scope="col">
                Bâtiment : 
            </th>
            <td>
                {{agence?.address?.building }} 
            </td>
        </tr>
        <tr>
            <th scope="col">
                Etage : 
            </th>
            <td>
                {{agence?.address?.floor }} 
            </td>
            <th scope="col">
                Escalier : 
            </th>
            <td>
                {{agence?.address?.stair }} 
            </td>
        </tr>
        <tr>
            <th scope="col">PTO : </th>
            <td>
                {{agence?.address?.pto }} 
            </td>
            <td></td>
        </tr>
    </table> 
</div>
<mat-dialog-actions>
  <button mat-button mat-dialog-close color="primary">Fermer</button>
</mat-dialog-actions>
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StatusService } from 'src/app/core/services/status.service';

@Component({
  selector: 'app-status-categories-associated-form',
  templateUrl: './status-categories-associated-form.component.html',
  styleUrls: ['./status-categories-associated-form.component.scss']
})
export class StatusCategoriesAssociatedFormComponent implements OnInit {

  statusCatgory!: any;
  constructor(private statusService: StatusService,
    private _formBuilder: FormBuilder, 
    private dialogRef: MatDialogRef<any>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar) { }

  async ngOnInit(): Promise<void> {
    this.statusCatgory = await this.statusService.getStatusCategory(this.data?.statusCategory?._id);
  }

  exit(){
    this.dialogRef.close();
  }
}

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NewDocumentComponent } from 'src/app/shared/new-document/new-document.component';
import { DocumentService } from 'src/app/core/services/document.service';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { PdfViewerComponent } from 'src/app/pages/private/order/components/general-infos/modals/pdf-viewer/pdf-viewer.component';
import { Document } from 'src/app/core/interfaces/document';
import { FileValidationFormComponent } from 'src/app/pages/private/order/components/general-infos/modals/file-validation-form/file-validation-form.component';
import { OrderService } from 'src/app/core/services/order.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-vt',
  templateUrl: './vt.component.html',
  styleUrls: ['./vt.component.scss']
})
export class VTComponent implements OnInit {
  @Input() command: any;
  command_id!: string;
  current_user!: any;
  hasPermission = false;
  hasRole = false;
  isActive = false;
  constructor(public permissionService: PermissionService,private tokenService: TokenStorageService, private documentService: DocumentService, private orderService: OrderService , private route: ActivatedRoute ,private modalDialog: MatDialog,private toastr: ToastrService,
    public datepipe: DatePipe) { }
 
  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(params => {
      this.command_id = params['id'];
    })
    this.current_user = this.tokenService.getUser();
    this.hasPermission = this.permissionService.hasPermission(this.current_user  , 'ORDER_UPDATE');
    this.hasRole = await this.permissionService.hasRole(this.current_user, this.command?.statusId?.assignedRoleId?.reference);
  }

 
  
  async loadOrder() {
    this.command = await this.orderService.getOrder(this.command_id);
  }

}

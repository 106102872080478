import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ProductModalComponent } from 'src/app/pages/private/admin/components/products/product-modal/product-modal.component';
import { ProductUpdateFormComponent } from 'src/app/pages/private/admin/components/products/product-update-form/product-update-form.component';
import { ProductService } from 'src/app/core/services/product.service';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  displayedColumns: string[] = ['actions', 'type', 'reference', 'name', 'displayName' , 'hardware'];
  dataSource!: MatTableDataSource<any>;
  products: any = [];
  constructor(private productService: ProductService, public dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
    this.productService.productUpdated$.subscribe(() =>{
      this.loadData();
    })
    this.loadData();
   
  }

  openProductModal(product: any) {
    
    const dialogRef = this.dialog.open(ProductModalComponent, {
      data: {
        product: product,
      },
      width: '60%',
      height: '80%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  
  }
  openProductUpdateForm(product: any) {
    const dialogRef = this.dialog.open(ProductUpdateFormComponent, {
      data: {
        product: product,
      },
      width: '60%',
      height: '90%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  async loadData() {
    this.products = await this.productService.getProducts();
    this.dataSource = new MatTableDataSource<any>(this.products);
  }

}

<mat-divider></mat-divider>
<h1>Administration</h1>
<mat-tab-group dynamicWidth backgroundColor="primary">
  <mat-tab label="Utilisateurs">
    <div class="example-large-box mat-elevation-z4">
      <mat-divider></mat-divider>
      <button mat-raised-button color="primary" [routerLink]="['/admin/users/new']">Nouvel utilisateur</button>
      <mat-divider></mat-divider>
      <app-users></app-users>
    </div>
  </mat-tab>
  <mat-tab label="Entreprises">
    <div class="example-large-box mat-elevation-z4">
      <mat-divider></mat-divider>
      <button mat-raised-button color="primary" [routerLink]="['/admin/entreprises/new']">Nouvelle entreprise</button>
      <mat-divider></mat-divider>
      <app-entreprises></app-entreprises>
    </div>
  </mat-tab>
  <mat-tab label="Agences">
    <div class="example-large-box mat-elevation-z4">
      <mat-divider></mat-divider>
      <button mat-raised-button color="primary" [routerLink]="['/admin/agencies/new']">Nouvelle agence</button>
      <mat-divider></mat-divider>
      <app-agences></app-agences>
    </div>
  </mat-tab>
  <mat-tab label="Contacts">
    <div class="example-large-box mat-elevation-z4">
      <mat-divider></mat-divider>
      <button mat-raised-button color="primary" [routerLink]="['/admin/contacts/new']">Nouveau contact</button>
      <mat-divider></mat-divider>
      <app-contacts-administration></app-contacts-administration>
    </div>
  </mat-tab>
  <mat-tab label="Rôles">
    <div class="example-large-box mat-elevation-z4">
      <mat-divider></mat-divider>
      <button mat-raised-button color="primary" [routerLink]="['/admin/roles/new']">Nouveau rôle</button>
      <mat-divider></mat-divider>
      <app-roles></app-roles>
    </div>
  </mat-tab>
  <mat-tab label="Produits">
    <div class="example-large-box mat-elevation-z4">
      <mat-divider></mat-divider>
      <mat-divider></mat-divider>
      <app-products></app-products>
    </div>
  </mat-tab>
  <mat-tab label="Statut">
    <div class="example-large-box mat-elevation-z4">
      <mat-divider></mat-divider>
      <mat-divider></mat-divider>
      <button mat-raised-button color="primary" class="btn-statut" (click)="openStatusModal()">Nouveau statut</button>
      <mat-divider></mat-divider>
      <app-status></app-status>
    </div>
  </mat-tab>
  <mat-tab label="Catégorie de statut">
    <div class="example-large-box mat-elevation-z4">
      <mat-divider></mat-divider>
      <mat-divider></mat-divider>
      <button mat-raised-button color="primary" class="btn-statut category-btn" (click)="openStatusCategoriesModal()">Nouvelle catégorie de statut</button>
      <mat-divider></mat-divider>
      <app-status-categories></app-status-categories>
    </div>
  </mat-tab>
  <mat-tab label="Transitions">
    <div class="example-large-box mat-elevation-z4">
      <mat-divider></mat-divider>
      <mat-divider></mat-divider>
      <button mat-raised-button color="primary" class="btn-statut" (click)="openTransitionModal()">Nouvelle transition</button>
      <mat-divider></mat-divider>
      <app-transitions></app-transitions>
    </div>
  </mat-tab>
  <mat-tab label="Workflows">
    <div class="example-large-box mat-elevation-z4">
      <mat-divider></mat-divider>
      <mat-divider></mat-divider>
      <button mat-raised-button color="primary" class="btn-statut" (click)="openWorkflowDocumentModal()">Ajouter un document</button>
      <input #fileInput type="file" style="display: none" (change)="handleFileInputChange($event)" />
      <mat-divider></mat-divider>
      <app-workflow-documents></app-workflow-documents>
    </div>
  </mat-tab>
</mat-tab-group>
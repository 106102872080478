import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleService } from 'src/app/core/services/role.service';
import { StatusService } from 'src/app/core/services/status.service';

@Component({
  selector: 'app-status-categories-modal',
  templateUrl: './status-categories-modal.component.html',
  styleUrls: ['./status-categories-modal.component.scss']
})
export class StatusCategoriesModalComponent implements OnInit {

  statutCategoriesFG!: FormGroup;
  constructor(private statusService: StatusService,
    private roleService: RoleService ,
    private _formBuilder: FormBuilder, 
    private dialogRef: MatDialogRef<any>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar ) { }

  async ngOnInit(): Promise<void> {
    this.statutCategoriesFG = this._formBuilder.group({
      reference: new FormControl(null, Validators.required),
      name: [null, Validators.required],
      description: [null]
    });


  }

  onSubmit(){
    let status = {
      reference: this.statutCategoriesFG.value.reference,
      name: this.statutCategoriesFG.value.name,
      description: this.statutCategoriesFG.value.description
    }

    this.statusService.addStatusCategory(status).then((response)  => {
      this._snackBar.open('nouvelle catégorie de statut crée avec succès', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }).catch(err => {
      this._snackBar.open('Erreur lors de la création de la nouvelle catégorie de statut', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      })
    }).finally(() => {
        this.statusService.triggerStatusUpdated();
        this.exit();
    });
  }

  exit(){
    this.dialogRef.close();
  }

}

<h2 class='title'>
    <mat-icon color="primary">manage_accounts</mat-icon> Affectations
</h2>
<div class="row" *ngIf= "permissionService.hasPermission(current_user  , 'ORDER_ASSIGN');">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-xl-3">
                       <app-assign-agency [command]='command'></app-assign-agency>
                    </div>
                    <div class="col-xl-3">
                        <app-assign-provider [command]='command'></app-assign-provider>
                    </div>
                    <div class="col-xl-3">
                        <app-assign-supervisor [command]='command'></app-assign-supervisor>
                    </div>
                    <div class="col-xl-3">
                        <app-assign-engineering-officer [command]='command'></app-assign-engineering-officer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<h1 mat-dialog-title><span>Rôle - Mise à jour</span>
    <span class="close">
        <mat-icon (click)="cancelUpdate()">close</mat-icon>
    </span>
</h1>
<div class="col-xl-12">
    <div class="row">
        <div class="col-xl-10">
            <form [formGroup]="updateForm">

                <mat-form-field class="input" appearance="fill">
                    <mat-label>Nom</mat-label>
                    <input matInput value="{{data?.role?.name}}" formControlName="name" required>
                </mat-form-field>

                <mat-form-field class="input" appearance="fill">
                    <mat-label>Référence</mat-label>
                    <input matInput value="{{data?.role?.reference}}" formControlName="reference" required>
                </mat-form-field>

                <mat-form-field class="input" appearance="fill">
                    <mat-label>Description</mat-label>
                    <input matInput value="{{ data?.role?.description}}" formControlName="description" required>
                </mat-form-field>

                <mat-form-field appearance="fill" class="input">
                    <mat-label>Catégories</mat-label>
                    <mat-select name="caregories" formControlName="caregories" required>
                        <mat-option *ngFor="let category of categories" value="{{category.id}}">
                            {{category.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" class="input">
                    <mat-label>Accès aux commandes</mat-label>
                    <mat-select name="viewOrder" formControlName="viewOrder" required>
                        <mat-option *ngFor="let viewOrder of viewOrders" value="{{viewOrder.id}}">
                            {{viewOrder.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                
                <button mat-raised-button color="primary" (click)="updateRole()">Modifier les informations </button>
                <br>
                <mat-form-field appearance="fill" class="inputText">
                    <mat-label>Permissions</mat-label>
                    <mat-select formControlName="permissions" required multiple>
                        <mat-option *ngFor="let permission of allPermissions" [value]="permission?._id">
                            {{permission?.reference}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                <button mat-raised-button color="primary" (click)="updateRolePermission()">Modifier les
                    permissions</button>
            </form>
        </div>
    </div>

</div>
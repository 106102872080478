<div class="content" *ngIf="this.permissionService.hasPermission(current_user  , 'ORDER_ASSIGN');">
    <div class="row col-xl-12">
        <span matTooltip="Modifier l'agence" >
            Agence
        </span>
    </div>
    <div class="row col-xl-12">
        <mat-form-field appearance="fill" matTooltip="Modifier l'agence" (click)="openAssignmentForm('agency')">
            <mat-label>
                <div *ngIf="command?.agencyId">
                    [ {{ command?.agencyId?.companyId?.name}} ] {{ command?.agencyId?.name}} 
                </div>
            </mat-label>
            <input matInput placeholder="" readonly='true' />
        </mat-form-field>
    </div>
</div>
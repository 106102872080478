import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from 'src/app/core/services/company.service';

@Component({
  selector: 'app-company-update-form',
  templateUrl: './company-update-form.component.html',
  styleUrls: ['./company-update-form.component.scss']
})
export class CompanyUpdateFormComponent implements OnInit {

  companies: any[] = [];
  types = [{id: 'SUBCONTRACTOR', name:'Sous traitant'} , {id: 'CLIENT', name:'Client final'} , {id: 'SITE', name:'Site de livraison'} ]
  selectedCompanyType = '';
  constructor(private dialogRef: MatDialogRef<any>,private companyService: CompanyService,
     @Inject(MAT_DIALOG_DATA) public data: any, private toastr: ToastrService,private router: Router,) { }

  ngOnInit(): void {
    this.selectedCompanyType = this.data?.company?.type;
    this.companyService.getCompanies().then((response) => {
      this.companies = response.data.data;
    });
  }
  updateCompany(){
    this.companyService.updateCompanyType(this.data?.company?._id , {type: this.selectedCompanyType}).then(response => {
      if (response.data.error){
        this.toastr.error("Erreur de mise à jour de l'entreprise ")
      }
      else {
        this.dialogRef.close();
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute]); 
        }); 
      }
    }).catch((err) => {console.error(err)})
  }
  cancelUpdate() {
    this.dialogRef.close();
  }
}

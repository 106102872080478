<h1>Nouvelle entreprise</h1>
<form [formGroup] = "formdata" (ngSubmit) = "onClickSubmit()">
    <p>
        <mat-form-field appearance="fill">
            <mat-label>Nom</mat-label>
            <input matInput placeholder="Placeholder" formControlName="company">
        </mat-form-field>
    </p>
        <mat-form-field appearance="fill" class="type">
            <mat-label>Sous traitant</mat-label>
            <mat-select name="type" formControlName="type" required>
                <mat-option *ngFor="let type of types" value="{{type.id}}">
                    {{type.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    <p>
        <button mat-raised-button color="primary" type = "submit" class = "forsubmit" >Ajouter</button>
    </p>
</form>
<br>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 ">
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openStatusAssociated(element)">
                    <mat-icon>visibility</mat-icon>
                    <span>Voir les statut associées</span>
                  </button>
                <button mat-menu-item (click)="openStatusCategoriesUpdateForm(element)">
                  <mat-icon>edit</mat-icon>
                  <span>Modifier</span>
                </button>
              </mat-menu>
        </td>
        
    </ng-container>
    <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef> Référence </th>
        <td mat-cell *matCellDef="let element"> {{ element?.reference }} </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nom </th>
        <td mat-cell *matCellDef="let element"> {{ element?.name }} </td>
    </ng-container>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Déscription </th>
        <td mat-cell *matCellDef="let element"> {{ element?.description }} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { Observable, Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { AuthentificationService } from './authentification.service';
import { TokenStorageService } from './token-storage.service';

const ORDER_API = environment.back_url + '/orders/';
const RACCORDEMENT_API = environment.back_url + '/orders/raccordement/';
@Injectable({
  providedIn: 'root'
})

export class OrderService {
  config = {
    withCredentials: true
  };
  private orderUpdatedSubject = new Subject<void>();
  orderUpdated$: Observable<void> = this.orderUpdatedSubject.asObservable();
  constructor(private http: HttpClient, private authService: AuthentificationService, private tokenService : TokenStorageService) { 
    axios.defaults.withCredentials = true;
  }


  addOrder(order : any){
    return axios.post(RACCORDEMENT_API , order)
  }

  getOrders(){
    return axios.get(RACCORDEMENT_API)
  }

  getOrdersByPage(limit: number, page: number){
    return axios.get(`${RACCORDEMENT_API}limit=${limit}&page=${page}`)
  }


  async searchOrders(limit: Number, page: Number,formdata: any){
    let api = RACCORDEMENT_API;
    api += `search?limit=${limit}&page=${page}`;
    for (const key in formdata) {
      if (formdata.hasOwnProperty(key)) {
          const value = formdata[key];
          api += `&${key}=${value}`;
      }
    }
    return (await axios.get(api,  this.config)).data;
  }

  getFilteredOrders(user:any, filter: any, limit: Number, page: Number) : Observable<any> {
    let api = RACCORDEMENT_API;
    api += `search?limit=${limit}&page=${page}`
    return this.http.post<any>(api , filter, this.config)
  }

  getCustomizedOrdersAxios(user:any, limit: Number, page: Number){
      let api = RACCORDEMENT_API;
      if (_.includes(user.role,'SUPER_ADMIN' ) || _.includes(user.role,'RACCO_ADMIN' ) )
        api = api;
      else if( _.includes(user.role,'RTC' ))
        api += `rtc/${user.userId}`;
      else if( _.includes(user.role,'MBE' ))
        api += `mbe/${user.userId}`;
      else if(_.includes(user.role,'ITC' ))
        api += `itc/${user.userId}`;
      else 
        api += `created/${user.userId}`;
      api += `?limit=${limit}&page=${page}`
      return axios.get(api , this.config)
  }

  getCustomizedOrders(limit: Number, page: Number, formdata?: any) : Observable<any> {
    let api = RACCORDEMENT_API;
    api += `search?limit=${limit}&page=${page}`;
    // Include filter parameters if provided
    if (formdata || formdata?.source?._value) {
      let data = formdata?.source?._value || formdata; 
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];
            api += `&${key}=${value}`;
        }
      }
    }
  
    return this.http.get<any>(api, this.config);
}

  getOrdersByState(ids: any){
    return axios.post(RACCORDEMENT_API + 'byState', {ids:ids}, this.config)
  }

  async  getOrder(id: string){
   let response = axios.get(RACCORDEMENT_API + id, this.config);
    return (await response).data.data;
  }

  async getOrderByReference(reference: string){
    let response = axios.get(`${RACCORDEMENT_API}reference/mae/${reference}`, this.config);
    return (await response).data.data[0];
  }

  async getOrderByEmaReference(reference: string){
    let response = axios.get(`${RACCORDEMENT_API}reference/ema/${reference}`, this.config);
    return (await response).data.data;
  }

  async  getOrderPublic(id: string){
    let response = axios.get(RACCORDEMENT_API  + id + '/public',  this.config);
     return (await response).data.data;
   }
  async getAvailableTransitions(id: string){
    return (await axios.get(RACCORDEMENT_API + id + '/transitions/availables', this.config)).data.data;
    
  }

  async  suggestSlots( content: any , subject: string , emails: string[] ) {
    let response = axios.post(RACCORDEMENT_API + content.orderId +'/sendEmail/', {content: content , subject: subject , emails: emails}, this.config)
     return (await response).data.data;
   }
  UpdateEngineeringOfficer(orderId: string, userId: string, engineeringOfficerId: string ){
    return axios.put(RACCORDEMENT_API+ orderId + '/engineeringOfficer', {engineeringOfficerId: engineeringOfficerId , userId: userId }, this.config)
  }
  UpdateSupervisor(orderId: string, userId: string, supervisorId: string ){
    return axios.put(RACCORDEMENT_API+ orderId + '/supervisor', {supervisorId: supervisorId , userId: userId}, this.config)
  }
  UpdateProvider(orderId: string, userId: string, providerId: string ){
    return axios.put(RACCORDEMENT_API+ orderId + '/provider', {providerId: providerId , userId: userId} , this.config)
  }
  UpdateAgency(orderId: string, userId: string, agencyId: string ){
    return axios.put(RACCORDEMENT_API+ orderId + '/agency', {agencyId: agencyId , userId: userId} , this.config)
  }
  UpdateReferenceFci(orderId: string, userId: string, referenceFCI: string ){
    return axios.put(RACCORDEMENT_API+ orderId + '/referenceFCI', {referenceFCI: referenceFCI , userId: userId }, this.config)
  }
  UpdateOrderToNextState(orderId: String, transition: any, user?: any) {
    return axios.put(RACCORDEMENT_API+ orderId + '/status', {assignedRole: transition.statusId.assignedRoleId.name, transitionId: transition._id, userId: user?.userId} , this.config)
  }

  UpdateOrderForceStatus(orderId: String, statusId: String) {
    return axios.put(RACCORDEMENT_API+ orderId + '/forceStatus/' + statusId,   this.config)
  }
  UpdateOrderToPreviousState(orderId: String){
    return axios.put(RACCORDEMENT_API+ orderId + '/state', {next: "-1"} , this.config)
  }

  async getEscalations(orderId: String)  {
    return (await axios.get(`${ORDER_API}${orderId}/escalations`, this.config)).data.data;
  }

  handleError(error: any) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }

 triggerOrderUpdated() {
  this.orderUpdatedSubject.next();
}

}

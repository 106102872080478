<h1>Nouvel utilisateur</h1>
<form [formGroup]="formdata" (ngSubmit)="onClickSubmit(formdata.value)">
    <p>
        <mat-form-field appearance="fill">
            <mat-label>Nom</mat-label>
            <input matInput placeholder="Placeholder" formControlName="lastName" required>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="fill">
            <mat-label>Prénom</mat-label>
            <input matInput placeholder="Placeholder" formControlName="firstName" required>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput placeholder="aaaa@alphalink.fr" formControlName="email" required>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="fill">
            <mat-label>Mot de passe</mat-label>
            <input matInput placeholder="" pattern="^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$" type="password"
                formControlName="password" required>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Confirmation de mot de passe</mat-label>
            <input matInput placeholder="" type="password" formControlName="passwordConfirm" required>
        </mat-form-field>
    </p>
    <p>
        <mat-form-field appearance="fill" class="standard">
            <mat-label>Entreprise</mat-label>
            <mat-select name="company" formControlName="company" (ngModelChange)="onChangeCompany($event)" required>
                <mat-option *ngFor="let company of companies" value="{{company._id}}">
                    {{company.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </p>
    
    <mat-form-field appearance="fill" *ngIf="formdata.get('company')?.value">
        <mat-label>Choisir une agence</mat-label>
        <mat-select formControlName="agency" required>
            <mat-option></mat-option>
            <mat-option [value]="agency._id" *ngFor="let agency of agencies">{{ agency.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <p>
        <mat-form-field appearance="fill" *ngIf="formdata.get('company')?.value">
            <mat-label>Choisir un Manager</mat-label>
            <mat-select formControlName="manager" required>
                <mat-option></mat-option>
                <mat-option [value]="manager._id" *ngFor="let manager of managers"> {{manager.contactId.firstName}}
                    {{manager.contactId.lastName}}</mat-option>
            </mat-select>
        </mat-form-field>
    </p>
    <p>
        <button mat-raised-button color="primary" type="submit" class="forsubmit">Ajouter</button>
    </p>
</form>
import { ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification.service';
import { StatusService } from 'src/app/core/services/status.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { OrderService } from 'src/app/core/services/order.service';
import { Notification } from 'src/app/core/interfaces/notification';
import { NewDocumentComponent } from 'src/app/shared/new-document/new-document.component';
import { StatusCategory } from 'src/app/core/interfaces/statuscategory';
import { ComponentCallerService } from 'src/app/core/services/component-caller.service';
import { AcRequestComponent } from '../rt/ac-request/ac-request.component';
import { OtherBeComponent } from '../dt/other-be/other-be.component';
import { ComponentType } from '@angular/cdk/portal';
import { PermissionService } from 'src/app/core/services/permission.service';
import { SimpleChanges } from '@angular/core';
import { PdfViewerComponent } from 'src/app/pages/private/order/components/general-infos/modals/pdf-viewer/pdf-viewer.component';
import { DocumentManagerComponent } from '../document-manager/document-manager.component';

@Component({
  selector: 'app-status-management',
  templateUrl: './status-management.component.html',
  styleUrls: ['./status-management.component.scss']
})
export class StatusManagementComponent implements OnInit {
  @Input() command: any;
  @ViewChild('componentContainerMandatory', { read: ViewContainerRef }) componentContainerMandatory!: ViewContainerRef;
  @ViewChild('componentContainerOptional', { read: ViewContainerRef }) componentContainerOptional!: ViewContainerRef;
  public componentRefs: ComponentRef<any>[] = [];
  rdvChecked!: boolean;
  commandId = ''
  transitions: any[] = [];
  current_user!: any;
  hasPermission = false;
  hasRole = false;
  state = '';
  transitionDesc = '';
  superStates: String[] = []
  public statusCategories: StatusCategory[] = [];
  constructor(public permissionService: PermissionService,private orderService: OrderService, private toastr: ToastrService, private router: Router, private route: ActivatedRoute,
    private modalDialog: MatDialog, private statusService: StatusService, private tokenService: TokenStorageService, private componentCallerService: ComponentCallerService,
    private notificationService: NotificationService, private componentFactoryResolver: ComponentFactoryResolver, private cdr: ChangeDetectorRef) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.current_user = this.tokenService.getUser();
  }

  async ngOnInit(): Promise<void> {
    this.current_user = this.tokenService.getUser();
    this.route.params.subscribe(params => {
      this.commandId = params['id'];
    })
    this.hasPermission =  this.permissionService.hasPermission(this.current_user  , 'ORDER_UPDATE');
    this.hasRole = await this.permissionService.hasRole(this.current_user, this.command?.statusId?.assignedRoleId?.reference);
    if (this.commandId) {
      this.transitions = await this.orderService.getAvailableTransitions(this.commandId);
      this.statusCategories = await this.statusService.getStatusCategories()
      this.superStates = this.statusCategories.map((sc: StatusCategory) => sc.name)
    }

  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.command.currentValue.statusId && changes.command.currentValue._id) {
      this.hasRole = await this.permissionService.hasRole(this.current_user, changes.command.currentValue.statusId?.assignedRoleId?.reference);
    }
  }

  saveState(transition: any) {
    // TO DO
    if (transition) {
      this.validateTransition(transition);
    } else {
      this.toastr.error('Merci de choisir un choix pour valider le traitement', 'Infos : ', { positionClass: 'toast-top-center' })
    }
  }

  openFileImportModal() {
    const dialogRef = this.modalDialog.open(NewDocumentComponent, {
      data: {
        command_id: this.command._id,
      },
      width: '60%',
      height: '40%',
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }
  async onChange(data: any) {
    this.transitionDesc = data?.description;
    this.clearComponents();
    let components = await this.componentCallerService.getComponentsByReference(data?.reference);
    let mandatoryComponents = components.components.MANDATORY_FIELDS?.split(',');
    let optionalComponents = components.components.OPTIONAL_FIELDS?.split(',');
    let roComponents = components.components.RO_FIELDS?.split(',');
    const componentTypesMandatory: { reference: string, component: ComponentType<any> }[] = [];
    const componentTypesOptional: { reference: string, component: ComponentType<any> }[] = [];
    
    for (const element of mandatoryComponents) {
      if (element) {
        const { reference, component } = this.componentCallerService.getComponent(element);
        componentTypesMandatory.push({ reference, component });
      }
    }
    for (const element of optionalComponents) {
      if (element) {
        const { reference, component } = this.componentCallerService.getComponent(element);
        componentTypesOptional.push({ reference, component });
      }
    }

    this.addComponentsToContainer(componentTypesMandatory, this.componentContainerMandatory);
    this.addComponentsToContainer(componentTypesOptional, this.componentContainerOptional);

    this.cdr.detectChanges();
    this.state = data;
  }

  private addComponentsToContainer(components: { reference: string, component: ComponentType<any> }[], container: ViewContainerRef) {
    components.forEach(({ reference, component }) => {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const componentRef = container.createComponent(componentFactory);
      componentRef.instance.command = this.command;
      if (component === DocumentManagerComponent) {
        componentRef.instance.documentType = reference;
      }
      this.componentRefs.push(componentRef);
    });
  }

  validateTransition(transition: any){
    this.orderService.UpdateOrderToNextState(this.command._id, transition, this.current_user).then((response) => {
      this.toastr.success('Changement d\'état de la commande', 'Infos : ', { positionClass: 'toast-top-full-width' });
      window.location.reload();
    }).catch((error) => {
      if (error.response.status === 500) {
        this.toastr.error("Erreur est survenu lors de traitement (callback) : Merci de vérifier que vous avez bien renseigné les champs obligatoires", 'Infos : ', { positionClass: 'toast-top-center' })
      }
      else if (error.response.status === 403) {
        this.toastr.error('Vous n\'avez pas les droits pour établir ce traitement ', 'Infos : ', { positionClass: 'toast-top-center' })
      }
      else
        this.toastr.error('une Erreur est survenu lors de la mise à jour de traitement', 'Infos : ', { positionClass: 'toast-top-center' })
    })
  }
  private clearComponents() {
    // Destroy existing components and clear the array
    this.componentRefs.forEach(componentRef => {
      componentRef.destroy();
    });
    this.componentRefs = [];
  }


  openPdfViewer() {
    const dialogRef = this.modalDialog.open(PdfViewerComponent, {
      data: {
        type: "workflow",
        condition: "last"
      },
      width: '90%',
      height: '90%',
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }





}

<h1 mat-dialog-title>
    <span>Transition - Mise à jour</span>
    <span class="close">
        <mat-icon (click)="exit()">close</mat-icon>
    </span>
</h1>
<div class="row standard">
    <div class="col-xl-2"></div>
    <div class="col-xl-9">
        <form [formGroup]="transitionFG">
            <p>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Référence</mat-label>
                    <input matInput value="{{data?.transition?.reference}}" [disabled]="onlyReadInput" readonly>
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Nom</mat-label>
                    <input matInput placeholder="Nom" value="{{data?.transition?.name}}" formControlName="name" required>
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="Description" value="{{data?.transition?.description}}" formControlName="description" required>
                </mat-form-field>
                <mat-form-field appearance="fill" class="input">
                    <mat-label>Statut courant</mat-label>
                    <mat-select name="statusId" formControlName="statusId" required>
                        <mat-option *ngFor="let s of status" value="{{s?._id}}">
                            {{s?.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="input">
                    <mat-label>Prochain statut</mat-label>
                    <mat-select name="nextStatusId" formControlName="nextStatusId" required>
                        <mat-option *ngFor="let s of status" value="{{s?._id}}">
                            {{s?.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
            <div>
                <button mat-button-raised (click)="onSubmit()" class="btn btn-success">Valider</button>
            </div>
        </form>
    </div>
    <div class="col-xl-2"></div>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Company } from 'src/app/core/interfaces/company';
import { CompanyModalComponent } from 'src/app/pages/private/admin/entreprises/components/company-modal/company-modal.component';
import { CompanyUpdateFormComponent } from 'src/app/pages/private/admin/entreprises/components/company-update-form/company-update-form.component';
import { CompanyService } from 'src/app/core/services/company.service';


@Component({
  selector: 'app-entreprises',
  templateUrl: './entreprises.component.html',
  styleUrls: ['./entreprises.component.scss']
})
export class EntreprisesComponent implements OnInit {
  displayedColumns: string[] = ['actions', 'nom','type'];
  dataSource!: MatTableDataSource<any>;
  companies!: any[]

  constructor(private companyService: CompanyService, public dialog: MatDialog) { }

  ngOnInit(): void {

    this.companyService.getCompanies().then(response =>{
      this.companies = response.data.data;
      this.dataSource = new MatTableDataSource<any>(this.companies);
    });
   
  }
  applySearch(event: any){
    const searchVaule = (event.target as HTMLInputElement).value;
    this.dataSource.filter = searchVaule.trim().toLowerCase();
  }

  openCompanyInfo(id: string) {
    const dialogRef = this.dialog.open(CompanyModalComponent, {
      data: {
        companyId: id,
      },
      width: '60%',
      height: '40%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openCompanyUpdateForm(company: any) {
    const dialogRef = this.dialog.open(CompanyUpdateFormComponent, {
      data: {
        company: company,
      },
      width: '60%',
      height: '40%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getType(type: string){
    switch (type) {
      case 'CLIENT':
        return 'Partenaire';
      case 'SITE':
        return 'Site de livraison';
      case 'SUBCONTRACTOR':
        return 'Sous traitant';
      default:
        return ''
    }
  }

}

<div class="row">
    <div class="col-xl-6">
        <div class="colxl12-div">
            <div class="colxl12-revendeur">
                <div class="colxl12-colxl1202">
                    <div class="colxl12-frame1000002316">
                        <div class="colxl12-activities-medal">
                            <span class="colxl12-text">
                                <mat-icon>sell</mat-icon>
                            </span>
                        </div>
                    </div>
                    <div class="colxl12-title">
                        <span class="colxl12-text002">
                            <span>
                                {{command?.content?.clientId?.name }} - {{command?.content?.siteId?.name }} - 
                                <a class="link_to_ema" target=”_blank”
                                    href="https://ema.expert/ema/manage/order/detail.php?idproduct_order={{command?.content?.ema?.idProductOrder}}">
                                    {{command?.content?.ema?.idProductOrder}} 
                                </a>
                                - [{{command?.content?.productId?.displayName || command?.content?.productId?.name}}]
                            </span>
                            
                            <mat-icon color="warn" class="escaladeWarn" *ngIf="currentEscalation">warning</mat-icon>

                            
                        </span>
                    </div>
                </div>
            </div>
            <div class="colxl12-frame1">
                <div class="colxl12-revendeur1">
                    <div class="colxl12-tag">
                        <span class="colxl12-text004 BodyMedium">
                            <span >
                                {{command?.agencyId?.companyId?.name}} - {{command?.agencyId?.name}} -
                            </span>
                            <span>
                                {{ command?.statusId?.name }}
                            </span>
                        </span>
                    </div>
                </div>
                <span class="colxl12-text006 Body">
                    <span></span>
                </span>
                <div class="colxl12-tagetat-info">
                    <span class="colxl12-text008 Subtitles">
                        <span>
                            <button mat-button color="primary" *ngIf="!isFollowing" (click)='follow()'>
                                <mat-icon color="primary">card_membership</mat-icon>
                                <span >S'abonner</span>
                            </button>
                            <button mat-button color="primary" *ngIf="isFollowing" (click)='unfollow()'>
                                <mat-icon color="primary">unsubscribe</mat-icon>
                                <span >Se désabonner</span>
                            </button>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-6">
        <div class="row">
            <div class="col-xl-2 colxl12-b-cdatetag">
                <div class="colxl12-frame1193">
                    <span class="colxl12-text028 Icon16Regular">
                        <mat-icon>event_available</mat-icon>
                    </span>
                </div>
                <div class="colxl12-frame10003">
                    <span class="colxl12-text030">
                        <span>Creation du BDC EMA</span>
                    </span>
                    <span class="colxl12-text032">
                        {{ command?.content?.ema?.dateCreation ? datepipe.transform(command?.content?.ema?.dateCreation, 'dd/MM/yyyy') : '-'}}
                    </span>
                </div>
            </div>
            <div class="col-xl-2  colxl12-b-cdatetag">
                <div class="colxl12-frame119">
                    <span class="colxl12-text010 Icon16Regular">
                        <mat-icon>event_available</mat-icon>
                    </span>
                </div>
                <div class="colxl12-frame100">
                    <span class="colxl12-text012">
                        <span>Emission du BDC MAE</span>
                    </span>
                    <span class="colxl12-text014">
                        <span>{{ command?.dateSubmission ? datepipe.transform(command?.dateSubmission, 'dd/MM/yyyy') : '--'}} </span>
                    </span>
                </div>
            </div>
            <div class="col-xl-2  colxl12-b-cdatetag">
                <div class="colxl12-frame1191">
                    <span class="colxl12-text016 Icon16Regular">
                        <mat-icon>event_available</mat-icon>
                    </span>
                </div>
                <div class="colxl12-frame10001">
                    <span class="colxl12-text018">
                        <span>Echéance initiale </span>
                    </span>
                    <span class="colxl12-text020">
                        <span>{{command?.dateInitialDeadline ? datepipe.transform(command?.dateInitialDeadline, 'dd/MM/yyyy') : datepipe.transform(deadLineDate, 'dd/MM/yyyy') }}</span>
                    </span>
                </div>
            </div>
            <div class="col-xl-3 colxl12-b-cdatetag">
                <div class="colxl12-frame1192">
                    <span class="colxl12-text022 Icon16Regular">
                        <mat-icon>event_available</mat-icon>
                    </span>
                </div>
                <div class="colxl12-frame10002">
                    <span class="colxl12-text024">
                        <span>RDV Visite Technique</span>
                    </span>
                    <span class="colxl12-text026">
                        <span> {{ rdvVT ? datepipe.transform(rdvVT, 'dd/MM/yyyy') : '-' }}</span>
                    </span>
                </div>
            </div>
            <div class="col-xl-3 colxl12-b-cdatetag">
                <div class="colxl12-frame1193">
                    <span class="colxl12-text028 Icon16Regular">
                        <mat-icon>calendar_today</mat-icon>
                    </span>
                </div>
                <div class="colxl12-frame10003">
                    <span class="colxl12-text030">
                        <span>RDV de mise en service</span>
                    </span>
                    <span class="colxl12-text032">
                        {{ rdvMES ? datepipe.transform(rdvMES, 'dd/MM/yyyy') : '-'}}
                    </span>
                </div>
            </div>
           
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import axios from 'axios';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const BACK_API = environment.back_url ;
const config = { withCredentials: true}
@Injectable({
  providedIn: 'root'
})
export class FollowerService {
  private followerUpdatedSubject = new Subject<void>();
  followerUpdated$: Observable<void> = this.followerUpdatedSubject.asObservable();
  constructor() { }
  triggerFollowerUpdated() {
    this.followerUpdatedSubject.next();
  }
  async getFolowers(orderId: string){
    return (await axios.get(`${BACK_API}/orders/${orderId}/followers`, config)).data.data;
  }

  async addFollower(orderId: string, userId: string){
    return (await axios.put(`${BACK_API}/orders/${orderId}/followers/${userId}`, config)).data.data;
  }

  async removeFollower(orderId: string, userId: string){
    return (await axios.delete(`${BACK_API}/orders/${orderId}/followers/${userId}`, config)).data.data;
  }
}

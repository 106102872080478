<h1 mat-dialog-title><span>Ajouter un RDV {{data?.type}} </span></h1>
<div class="form" [formGroup]="rdvForm">
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="col-xl-3">
                <mat-form-field>
                    <mat-label>Choisir une date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="dateStart">
                    <mat-hint>JJ/MM/AAAA</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <!--div class="col-xl-3">
                <mat-form-field>
                    <mat-label>Créneaux</mat-label>
                    <mat-select formControlName="slot">
                        <mat-option *ngFor="let slot of slots" [value]="slot">De : {{slot.start}} À : {{slot.end}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div-->
            <div class="col-xl-3">
                <mat-form-field>
                    <mat-label>Heure de début</mat-label>
                    <mat-select formControlName="startTime">
                        <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-xl-3">
                <mat-form-field>
                    <mat-label>Heure de fin</mat-label>
                    <mat-select formControlName="endTime">
                        <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <br><br>

    <td><button mat-raised-button color="primary" (click)="addRdv()">Ajouter</button></td>
    <td><button mat-raised-button color="warn" mat-dialog-close>Annuler</button></td>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleService } from 'src/app/core/services/role.service';
import { StatusService } from 'src/app/core/services/status.service';

@Component({
  selector: 'app-status-update-form',
  templateUrl: './status-update-form.component.html',
  styleUrls: ['./status-update-form.component.scss']
})
export class StatusUpdateFormComponent implements OnInit {

  public onlyReadInput =  true;
  statutFG!: FormGroup;
  statusCategories: any[] = [];
  roles: any[] = [];
  constructor(private statusService: StatusService,
    private roleService: RoleService ,
    private _formBuilder: FormBuilder, 
    private dialogRef: MatDialogRef<any>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar ) { }

  async ngOnInit(): Promise<void> {
    this.statutFG = this._formBuilder.group({
      reference: new FormControl(this.data?.status?.reference , Validators.required),
      name: [this.data?.status?.name , Validators.required],
      description: [this.data?.status?.description],
      maxDurationInHours: [this.data?.status?.maxDurationInHours, [Validators.pattern(/^\d+$/)]],
      statusCategoryId: [this.data?.status?.statusCategoryId?._id , Validators.required],
      assignedRoleId: [this.data?.status?.assignedRoleId?._id , Validators.required]
    });

    this.roles = await this.roleService.getRoles();
    this.statusCategories = await this.statusService.getStatusCategories();
  }

  onSubmit(){
    let status = {
      id: this.data.status?._id,
      reference: this.statutFG.value.reference,
      name: this.statutFG.value.name,
      description: this.statutFG.value.description,
      maxDurationInHours: this.statutFG.value.maxDurationInHours,
      assignedRoleId: this.statutFG.value.assignedRoleId,
      statusCategoryId: this.statutFG.value.statusCategoryId
    }

    this.statusService.updateStatus(status).then((response)  => {
      this._snackBar.open('Mise à jour de statut est effectuée avec succès', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }).catch(err => {
      this._snackBar.open('Erreur lors de la mise à jour de statut', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      })
    }).finally(() => {
        this.statusService.triggerStatusUpdated();
        this.exit();
    });

  }

  exit(){
    this.dialogRef.close();
  }

}

import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from 'src/app/core/services/order.service';
import { DocumentService } from 'src/app/core/services/document.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { AbstractControl } from '@angular/forms';
import { WorkflowDocumentsService } from 'src/app/core/services/workflow-documents.service';

@Component({
  selector: 'app-new-document',
  templateUrl: './new-document.component.html',
  styleUrls: ['./new-document.component.scss']
})
export class NewDocumentComponent implements OnInit {
  current_user!: any;
  myForm!: FormGroup; 
  public documentCategories : any[] = ['RACCORDEMENT', 'DEPLOIMENT']
  public defaultCategory: string = 'RACCORDEMENT';
  constructor(public permissionService: PermissionService, private documentService: DocumentService,@Inject(MAT_DIALOG_DATA) public data: any,private dialogRef: MatDialogRef<NewDocumentComponent>,
    private toastr: ToastrService, private orderService: OrderService, private tokenService: TokenStorageService,private router: Router,private workflowDocumentsService: WorkflowDocumentsService,
    public datepipe: DatePipe) { }
  ngOnInit() {
    this.current_user = this.tokenService.getUser();
    this.myForm = new FormGroup({
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required]),
      category: new FormControl('', [Validators.required])
    });
    
    
  }
  get f(){

    return this.myForm.controls;

  }
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  submit(){

    const formData = new FormData();
    formData.append('file', this.myForm.value.fileSource);

      formData.append('userId', this.current_user.userId);
      formData.append('category',this.myForm.value.category);
      this.workflowDocumentsService.addWorkflowDocument(formData).then(async response =>{
        this.toastr.success('Document importé avec succès', 'Infos : ' ,{positionClass: 'toast-top-full-width'});
        this.workflowDocumentsService.triggerWorkflowDocumentsUpdated();
        this.dialogRef.close()
      }).catch(err=>{
        this.toastr.error('Une Erreur est survenu lors de l\'import de fichier ', 'Infos : ' ,{positionClass: 'toast-top-center'})
      })


  
  }
  

  clearFileSource() {
    const fileSourceControl: AbstractControl | null = this.myForm.get('fileSource');
    if (fileSourceControl) {
      fileSourceControl.setValue(''); // Reset the file input
    }
  }
}

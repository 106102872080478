<div class="modal-header">
    <h4 class="modal-title">Infos</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    Êtes-vous sur de refuser tout les créneaux ?
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">Annuler</button>
    <button type="button" class="btn btn-primary" (click)="accept()">Confirmer</button>
</div>
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SaveFilterDialogComponent } from 'src/app/pages/private/orders/components/table-filters/save-filter-dialog/save-filter-dialog.component';
import { AgenceService } from 'src/app/core/services/agence.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { ContactService } from 'src/app/core/services/contact.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { OpticalLoopService } from 'src/app/core/services/optical-loop.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ProductService } from 'src/app/core/services/product.service';
import { SavedFilterService } from 'src/app/core/services/saved-filter.service';
import { SiteService } from 'src/app/core/services/site.service';
import { StatusService } from 'src/app/core/services/status.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements OnInit {
  current_user!: any;
  filterGP!: FormGroup;
  orderNumber!: string;
  subcontractor: any[] = [];
  subcontractorAgency: any[] = [];
  statusCategory: any[] = [];
  status: any[] = [];
  assignedRole!: boolean;
  finishedOrders!: boolean;
  isEscalated!: boolean;
  escalationMotif: any[] = [];
  escalationMotifs: any[] = [];
  following!: boolean;
  actifRole!: boolean;
  dateCreation!: Date;
  dateSubmission!: Date;
  dateDelivery!: Date;
  dateEnd!: Date;
  dateRdvVt!: Date;
  dateRdvMes!: Date;
  dateCreationE!: Date;
  dateSubmissionE!: Date;
  dateDeliveryE!: Date;
  dateEndE!: Date;
  dateRdvVtE!: Date;
  dateRdvMesE!: Date;
  emaOrderNumber!: string;
  clients: any[] = [];
  products: any[] = [];
  opticalLoops: any[] = [];
  statusRdv: any [] = [];
  statusVt: any[] = [];
  statusMes: any[] = [];
  isLoading = false;
  savedFilters: any[] = []
  @Output() searchLoading = new EventEmitter<boolean>();
  constructor(private _formBuilder: FormBuilder, private router: Router, private userService: UserService, private opticalLoopService: OpticalLoopService,
    private productService: ProductService, private orderService: OrderService, private filterService: FilterService, private statusService: StatusService,
    private companyService: CompanyService, private siteService: SiteService,
    private contactService: ContactService, private tokenService: TokenStorageService,
    private toastrService: ToastrService, private agenceService: AgenceService,
    private dialog: MatDialog, private savedFilterService: SavedFilterService, private _snackBar: MatSnackBar,) { 
      this.initForm();
    }

  ngOnInit(): void {

    this.current_user = this.tokenService.getUser();
    this.initForm();
    this.loadData();

  }
  private async search(pageNumber: Number, pageSize: Number, formValues: any) {
    this.searchLoading.emit(true);
    let response = null;//await this.orderService.searchOrders(pageNumber, pageSize, formValues);
    this.searchLoading.emit(false);
    return response;
  }

  applyFilters() {
    let filters = this.filterGP.value;
    //this.savedFilterService.saveFilter({ name: 'Filter Name', filter: filters , userId });
    this.filterService.setFilter(filters);
    this.filterService.setFormFilter(filters);

  }

  clearForm() {
    this.initForm();
    this.filterService.setFormFilter({'assignedRole': 'true', 'finishedOrders': 'true'});
    //this.applyFilters();
  }

  initForm() {
    let assignedRoleFilter = this.filterService.getActiveRoleFilter();
    let finishedOrders = this.filterService.getFinishedOrdersFilter();
    this.filterGP = this._formBuilder.group({
      category: ['RACCORDEMENT', Validators.required],
      orderNumber: [''],
      subcontractor: [''],
      subcontractorAgency: [''],
      statusCategory: [''],
      status: [''],
      assignedRole: [assignedRoleFilter],
      finishedOrders: [finishedOrders],
      isEscalated: [''],
      escalationMotif: [''],
      following: [''],
      dateCreation: [null],
      dateSubmission: [null],
      dateDelivery: [''],
      dateEnd: [''],
      dateRdvVt: [''],
      dateRdvMes: [''],
      dateCreationE: [null],
      dateSubmissionE: [null],
      dateDeliveryE: [''],
      dateEndE: [''],
      dateRdvVtE: [''],
      dateRdvMesE: [''],
      statusVt: [''],
      statusMes: [''],
      emaOrderNumber: [''],
      clients: [''],
      products: [''],
      opticalLoops: [''],
    });
    this.filterGP.valueChanges
      .pipe(
        debounceTime(300), // Delay emitting values to avoid too many API calls
        distinctUntilChanged(), // Only emit distinct values
        switchMap((formValues) => this.search(10, 1, formValues)) // Trigger API call
      )
      .subscribe((response) => {
        // Handle the API response here
        this.applyFilters();
      });
  }
  private async loadData() {

    //this.subcontractors = await this.agenceService.getSubcontractors();
    let clientsData = await this.agenceService.getClients();
    this.clients = clientsData?.agencies.sort((a: any, b: any) => a.name.localeCompare(b.name));
    let subcontractorData = await this.agenceService.getSubcontractors();
    this.subcontractor = subcontractorData?.companies.sort((a: any, b: any) => a.name.localeCompare(b.name));;
    this.subcontractorAgency = subcontractorData?.agencies.sort((a: any, b: any) => a.name.localeCompare(b.name));;
    this.escalationMotifs = [
      { id: 'DEADLINE_EXCEEDED', name: 'DÉPASSEMENT DU DÉLAI' },
      { id: 'TECHNICAL_PROBLEM', name: 'PROBLEME TECHNIQUE' },
      { id: 'ADMINISTRATIF_PROBLEM', name: 'PROBLEME ADMINISTRATIF' }
    ]
    this.escalationMotifs.sort((a: any, b: any) => a.name.localeCompare(b.name));
    this.products = [
      { id: 'FTTH', name: 'Basic' },
      { id: 'FTTE', name: 'Business' },
      { id: 'FTTB', name: 'Premium' }
    ]
    this.statusCategory = await this.statusService.getStatusCategories();
    this.statusCategory.sort((a: any, b: any) => a.name.localeCompare(b.name));
    this.status = await this.statusService.getStatus();
    this.status.sort((a: any, b: any) => a.name.localeCompare(b.name));
    this.statusRdv = [
      { name: 'DRAFT',  translate: "Brouillon" },
      { name: 'WAITING_VALIDATION',  translate: "En attente de validation" },
      { name: 'ACCEPTED', translate: "Accepté par le partenaire" },
      { name: 'SCHEDULED', translate: "Programmé (confirmé par l'intervenant" },
      { name: 'REJECTED', translate: "Rejetée" },
      { name: 'CANCELED', translate: "Annulé" },
      { name: 'FAILED', translate: "En échec" },
      { name: 'SUCCESS', translate: "Réalisé" }
    ]
    this.statusRdv.sort((a: any, b: any) => a.translate.localeCompare(b.translate));
    this.savedFilters = await this.savedFilterService.getSavedFilters(this.current_user?.userId);
    this.opticalLoops = await this.opticalLoopService.getOpticalLoops();
    

  }

  openSaveFilterDialog() {
    const dialogRef = this.dialog.open(SaveFilterDialogComponent, {
      data: {
        filter: this.filterGP.value,
        userId: this.current_user?.userId
      },
      width: '60%',
      height: '80%'
    });

    return dialogRef.afterClosed();
  }

  loadSavedFilter(savedFilter: any) {
    this.filterGP.setValue(savedFilter.filter);
    //this.filterGP.patchValue(savedFilter.filter);
  }



  async onChangeStatusCategory(id: any) {
    if (id)
      this.status = await this.statusService.getStatusByCategory(id);

  }
  async onChangeSubcontractor(id: any) {
    if (id)
      this.subcontractorAgency = await this.agenceService.getAgenciesByCompany(id);
  }
  
  async removeFilter(filter: any) {
    try {
      let f = await this.savedFilterService.removeFilter(filter);
      this._snackBar.open("Filtre supprimé avec succès", 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
      this.savedFilterService.filterUpdated$.subscribe(() => {
        this.loadData();
      });
    }
    catch(error: any){
        this._snackBar.open("Suppression de filtre echouée ", 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
    }
    finally {
      this.savedFilterService.triggerFilterUpdated();
      this.savedFilterService.filterUpdated$.subscribe(() => {
        this.loadData();
      });
    }
  }
}
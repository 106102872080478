import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { StatusService } from '../../../core/services/status.service';
import { TokenStorageService } from '../../../core/services/token-storage.service';
import { OrderService } from '../../../core/services/order.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { merge, Observable, of as observableOf, pipe } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { PermissionService } from '../../../core/services/permission.service';
import { FilterService } from '../../../core/services/filter.service';
import { MatDialog } from '@angular/material/dialog';
import { MessagesComponent } from '../order/components/messages/messages.component';

export interface SearchItem {
  name: string;
}
@Component({
  selector: 'app-command-table',
  templateUrl: './command-table.component.html',
  styleUrls: ['./command-table.component.scss']
})
export class CommandTableComponent implements AfterViewInit {
  current_user!: any;
  commands!: any[];
  emaOrderLink: string = "https://ema.expert/ema/manage/order/detail.php?idproduct_order";

  canCreate = false;
  displayedColumns: string[] = [
    'actions',
    'content.ema.idProductOrderDetail',
    'reference',
    'agencyId.companyId.name',
    'agencyId.name',
    'dateCreation',
    'content.siteId.name',
    'content.opticalLoop',
    'statusId.name',
    'content.clientId.name',
    'content.productId.name',];
  columnHeaders: { [key: string]: string } = {
    'content.ema.idProductOrderDetail': 'N° cde EMA',
    'reference': 'N° cde MAE',
    'agencyId.companyId.name': 'Fournisseur',
    'agencyId.name': 'Agence',
    'dateCreation': 'Date Création MAE',
    'content.productId.name': 'Type',
    'content.clientId.name': 'Partenaire',
    'content.siteId.name': 'Site de livraison',
    'content.opticalLoop': 'Boucle',
    'statusId.name': 'Statut',
  };
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  sortedCommands!: any[];
  sortDirection: { [key: string]: string } = {};
  isLoadingResults = false;
  totalData!: number;
  pageSizes = [10, 30, 100];
  constructor(
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    public permissionService: PermissionService,
    public datepipe: DatePipe,
    private orderService: OrderService,
    private tokenService: TokenStorageService,
    private filterService: FilterService
  ) {
    this.current_user = this.tokenService.getUser();
    this.filterService.setFilter({})
  }

  ngOnInit() {
    this.current_user = this.tokenService.getUser();
    this.canCreate = this.permissionService.hasPermission(this.current_user, 'ORDER_CREATE');

  }

  ngAfterViewInit() {
    this.current_user = this.tokenService.getUser();
    this.dataSource.paginator = this.paginator;
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    const activeRoleFilter = this.filterService.getActiveRoleFilter();
    const finishedOrdersFilter = this.filterService.getFinishedOrdersFilter();
    if(activeRoleFilter === 'true'){
      this.filterService.setFilter({'assignedRole': 'true'})
    }
    if(finishedOrdersFilter === 'true'){
      this.filterService.setFilter({'finishedOrders': 'true'})
    }
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          setTimeout(() => {
            this.isLoadingResults = true; // Show loader when data loading starts
            this.changeDetectorRef.detectChanges(); // Trigger change detection
          });
          
          let filters = this.filterService.getFormFilter();
          return this.getTableData$(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            filters
          ).pipe(catchError(() => observableOf(null)));
        }),
        map((response: any) => {
          if (response == null) return [];
          this.totalData = response.data.total;
          this.isLoadingResults = false;
          return response.data.data;
        })
      )
      .subscribe((response) => {
        this.commands = response;
        this.dataSource = new MatTableDataSource(this.commands);
      });

    this.filterService.getFilter().subscribe((filter) => {
      if (filter?.category) {
        this.paginator.pageIndex = 0;
        setTimeout(() => {
          this.isLoadingResults = true; // Show loader when data loading starts
          this.changeDetectorRef.detectChanges(); // Trigger change detection
        });
        this.updateTableData(filter, this.paginator.pageIndex + 1, this.paginator.pageSize);

      }
    });
  }

  getTableData$(pageNumber: Number, pageSize: Number, filter?: any) {
    return this.orderService.getCustomizedOrders(pageSize, pageNumber, filter);
  }

  getColumnHeader(column: string): string {
    return this.columnHeaders[column] || column;
  }

  getCellValue(row: any, column: string): string {
    const columnParts = column.split('.');
    let value = row;
    for (const part of columnParts) {
      if (value && value.hasOwnProperty(part)) {
        if (part === 'dateCreation') {
          value = this.datepipe.transform(value[part], 'dd/MM/yyyy HH:mm')
        }
        else {
          value = value[part];
        }
      } else {
        value = '';
        break;
      }
    }

    return value?.toString();
  }

  async updateTableData(filter: any, pageNumber: Number, pageSize: Number) {
    let response = await this.orderService.searchOrders(pageSize, pageNumber, filter)
    this.isLoadingResults = false;
    this.dataSource.data = response.data.data;
    this.totalData = response.data.total;
  }

  handleSearchLoading(loading: boolean) {
    this.isLoadingResults = loading; // Update the isLoadingResults flag
  }


  openMessages(element: any ) {
    const dialogRef = this.dialog.open(MessagesComponent, {
      data: {
        command: element
      },
      width: '60%',
      height: '80%',
    });
    dialogRef.componentInstance.command = element;
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}

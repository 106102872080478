import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RdvService } from 'src/app/core/services/rdv.service';
import { StatusService } from 'src/app/core/services/status.service';

@Component({
  selector: 'app-failure-reason',
  templateUrl: './failure-reason.component.html',
  styleUrls: ['./failure-reason.component.scss' , '../vt.component.scss']
})
export class FailureReasonComponent implements OnInit {

  showSelectOptions: boolean = false;
  reasons: any[] = [];
  selectedReason = '';
  orderId!: string;
  constructor( private route: ActivatedRoute ,private statusService: StatusService, private rdvService: RdvService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.orderId = params['id'];
    })
    this.statusService.getFailureReasons('VT').then(response => {
      this.reasons = response.data.data
    }).catch(err => {
      console.error(err)
    })
  }

  cancelUpdate(){
    this.selectedReason = '';
    this.showSelectOptions = false;
  }
  updateReason(){
    this.rdvService.defineRdvFailureReason(this.orderId , this.selectedReason, 'VT');
    this.showSelectOptions = true;
  }
  acivate(){
    this.showSelectOptions = true;
  }

}

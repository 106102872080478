import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { FollowerService } from 'src/app/core/services/follower.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-add-follower-modal',
  templateUrl: './add-follower-modal.component.html',
  styleUrls: ['./add-follower-modal.component.scss']
})
export class AddFollowerModalComponent implements OnInit {

  @ViewChild('paginator') paginator!: MatPaginator ;
  current_user! : any;
  followerInformationFG!: FormGroup;
  dataSource!: MatTableDataSource<any>;
  users!: any[];
  displayedColumns: string[] = ['name', 'company', 'emailAddress','phone1','add'];
  pageSizes = [5, 10, 30 , 100];
  totalData!: number;
  constructor (
    private dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any,private tokenService: TokenStorageService,
    private userService: UserService, private followerService: FollowerService, private _snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    this.current_user = this.tokenService.getUser();
    if(this.current_user?.role?.includes('RACCO_ADMIN')){
      this.userService.getUsers().then((response) => {
        this.users = response.data.data;
        this.totalData = response.data.total;
        this.dataSource = new MatTableDataSource<any>(this.users); 
      }).catch(err =>{
        console.error(err)
      })
    }
    else {
      this.userService.getMyCompanyColleagues(`${this.current_user?.company?._id},${this.data?.order?.agencyId?.companyId?._id}`).then((response) => {
        this.users = response;
        this.dataSource = new MatTableDataSource<any>(this.users); 
      }).catch(err =>{
        console.error(err)
      })
    }
  }


  cancel(){
    this.dialogRef.close();
  }

  applySearch(event: any){
    const searchVaule = (event.target as HTMLInputElement).value;
    this.dataSource.filter = searchVaule.trim().toLowerCase();
  }

  async addSubscriber(element: any){
    try {
    let follower = await this.followerService.addFollower(this.data?.order?._id, element?._id)
    }
    catch(error: any){
      if(error?.response?.data?.motif === 'Cet utilisateur est déjà abonné à cette commande ')
      {
        this._snackBar.open(error?.response?.data?.motif, 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
      }
      else {
        this._snackBar.open("Ajout d'abonné echoué ", 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
      }
    }
    finally {
      this.followerService.triggerFollowerUpdated();
      this.dialogRef.close();
    }

  }

}

<div class='header'>
    <mat-icon class='arrow-back'>arrow_back</mat-icon>
    <span class="title">Commandes</span>
    <button class="newOrder" mat-raised-button color="primary" *ngIf="canCreate" [routerLink]="['/orders/new']">Nouvelle
        commande</button>
</div>

<div class="div table-wrapper">
    <div class="filters">
        <app-table-filters (searchLoading)="handleSearchLoading($event)"></app-table-filters>
    </div>
    <div *ngIf="isLoadingResults" class="loader">
        <!-- Show loader when isLoadingResults is true -->
        <mat-spinner></mat-spinner>
    </div>

    <table mat-table matSort matSortActive="created" matSortDisableClear matSortDirection="desc"
        [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openMessages(element)">
                        <mat-icon>mail</mat-icon>
                        <span>Voir les messages</span>
                      </button>
                  </mat-menu>
            </td>
        </ng-container>
        <ng-container matColumnDef="content.ema.idProductOrderDetail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> N° cde EMA </th>
            <td mat-cell *matCellDef="let element">
                <a href="{{ emaOrderLink}}={{element?.content?.ema?.idProductOrder}}" target="_blank" matTooltip="Ouvrir le BDC EMA dans un nouvel onglet">
                    {{element?.content?.ema?.idProductOrderDetail}}
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> N° cde MAE </th>
            <td mat-cell *matCellDef="let element"> 
                <a [routerLink]="['/order',element._id]" target="_blank" matTooltip="Ouvrir la CMD MAE dans un nouvel onglet"> 
                    {{element?.reference}}
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="agencyId.companyId.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fournisseur </th>
            <td mat-cell *matCellDef="let element"> {{element?.agencyId?.companyId?.name}}</td>
        </ng-container>
        <ng-container matColumnDef="agencyId.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Agence </th>
            <td mat-cell *matCellDef="let element"> {{element?.agencyId?.name}}</td>
        </ng-container>
        <ng-container matColumnDef="dateCreation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Création MAE </th>
            <td mat-cell *matCellDef="let element"> {{ datepipe.transform(element?.dateCreation, 'dd/MM/yyyy HH:mm')}}</td>
        </ng-container>
        <ng-container matColumnDef="content.productId.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let element"> {{element?.content?.productId?.name}}</td>
        </ng-container>
        <ng-container matColumnDef="content.clientId.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Partenaire </th>
            <td mat-cell *matCellDef="let element"> {{element?.content?.clientId?.name}}</td>
        </ng-container>
        <ng-container matColumnDef="content.siteId.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Site de livraison </th>
            <td mat-cell *matCellDef="let element">
                <a [routerLink]="['/order',element._id]" target="_blank" matTooltip="Ouvrir la CMD MAE dans un nouvel onglet"> 
                    {{element?.content?.siteId?.name}}
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="content.opticalLoop">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Boucle </th>
            <td mat-cell *matCellDef="let element"> {{element?.content?.opticalLoop}}</td>
        </ng-container>
        <ng-container matColumnDef="statusId.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
            <td mat-cell *matCellDef="let element"> {{element?.statusId?.name}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let order; columns: displayedColumns" ></tr>
    </table>

    <mat-paginator #paginator [length]="totalData" [pageSizeOptions]="pageSizes" showFirstLastButtons>
    </mat-paginator>
</div>
<!-- [routerLink]="['/order',order._id]" -->
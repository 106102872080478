<mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-icon-button (click)="toggleSideBar()">
        <mat-icon>menu</mat-icon>
    </button>


    <div fxLayout="row" fxLayoutGap="20px">
        <button mat-button [matMenuTriggerFor]="menuLogin" *ngIf="!isLoggedIn">
            <mat-icon>person_outline</mat-icon>
        </button>
        <mat-menu #menuLogin="matMenu">
            <button mat-menu-item (click)="signIn($event)">
                Se connecter
            </button>
        </mat-menu>
    </div>
</mat-toolbar>
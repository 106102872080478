<div class="card">
    <div  [hidden]="showSelectOptions">
        <mat-form-field appearance="fill" class="select-options">
            <mat-label>Cause d'échec</mat-label>
            <mat-select name="reason" [(ngModel)]="selectedReason" matTooltip="{{selectedReason}}" required>
                <mat-option *ngFor="let reason of reasons" matTooltip="{{reason}}" [value]="reason">
                    {{reason}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <tr>
            <td><button mat-raised-button color="primary" (click)="updateReason()">Sauvegarder</button></td>
            <td><button mat-raised-button color="warn" (click)="cancelUpdate()">Annuler</button></td>
        </tr>
    </div>
    <div class="col-xl-12" [hidden]="!showSelectOptions">
        <div class="alert alert-danger">
            Cause d'échec : {{ selectedReason }}
        </div>
    </div>
    <br>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { StatusCategoriesAssociatedFormComponent } from 'src/app/pages/private/admin/components/status-categories/status-categories-associated-form/status-categories-associated-form.component';
import { StatusCategoriesUpdateFormComponent } from 'src/app/pages/private/admin/components/status-categories/status-categories-update-form/status-categories-update-form.component';
import { StatusService } from 'src/app/core/services/status.service';

@Component({
  selector: 'app-status-categories',
  templateUrl: './status-categories.component.html',
  styleUrls: ['./status-categories.component.scss']
})
export class StatusCategoriesComponent implements OnInit {

  displayedColumns: string[] = ['actions',  'reference', 'name',  'description' ];
  dataSource!: MatTableDataSource<any>;
  statusCatgories: any = [];
  constructor(private statusService: StatusService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.statusService.statusUpdated$.subscribe(() =>{
      this.loadData();
    })
    this.loadData();
  }

  
  openStatusCategoriesUpdateForm(statusCategory: any) {
    const dialogRef = this.dialog.open(StatusCategoriesUpdateFormComponent, {
      data: {
        statusCategory: statusCategory,
      },
      width: '60%',
      height: '55%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openStatusAssociated(statusCategory: any){
    const dialogRef = this.dialog.open(StatusCategoriesAssociatedFormComponent, {
      data: {
        statusCategory: statusCategory,
      },
      width: '60%',
      height: '55%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  async loadData() {
    this.statusCatgories = await this.statusService.getStatusCategories();
    this.dataSource = new MatTableDataSource<any>(this.statusCatgories);
  }

}

import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddFollowerModalComponent } from 'src/app/pages/private/order/components/followers/add-follower-modal/add-follower-modal.component';

import { FollowerService } from 'src/app/core/services/follower.service';

@Component({
  selector: 'app-followers',
  templateUrl: './followers.component.html',
  styleUrls: ['./followers.component.scss']
})
export class FollowersComponent implements OnInit {

  @Input() command: any;
  followers :any[] = []
  displayedColumns: string[] = ['actions', 'name', 'emailAddress', 'phone1'];
  dataSource!: MatTableDataSource<any>;
  constructor(private followerService: FollowerService, public dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
   this.followerService.followerUpdated$.subscribe(() => {
     this.loadData();
   })
   this.loadData();
  }


  async unsubscribe(element: any){
    await this.followerService.removeFollower(element?.orderId, element?.userId?._id)
    this.followerService.triggerFollowerUpdated();
  }

  OpenAddFollowereModal(){
    const dialogRef = this.dialog.open(AddFollowerModalComponent, {
      data: {
        order: this.command
      },
      width: '60%',
      height: '60%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.followerService.triggerFollowerUpdated();
    });
  }

  async loadData() {
    this.followers = await this.followerService.getFolowers(this.command?._id);
    this.dataSource = new MatTableDataSource<any>(this.followers);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommentService } from 'src/app/core/services/comment.service';
import { EscalationService } from 'src/app/core/services/escalation.service';
import { FollowerService } from 'src/app/core/services/follower.service';
import { OrderService } from 'src/app/core/services/order.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-escalation-cancel-confirm-dialg',
  templateUrl: './escalation-cancel-confirm-dialg.component.html',
  styleUrls: ['./escalation-cancel-confirm-dialg.component.scss']
})
export class EscalationCancelConfirmDialgComponent implements OnInit {

  current_user!: any;
  currentFollowers: any[] = []
  constructor(
    private dialogRef: MatDialogRef<EscalationCancelConfirmDialgComponent> ,
     @Inject(MAT_DIALOG_DATA) public data: any,private _snackBar: MatSnackBar,private followerService: FollowerService,
     private orderService: OrderService, private escalationService: EscalationService , private commentService: CommentService, private tokenService: TokenStorageService) { }

  ngOnInit() {
    this.current_user = this.tokenService.getUser();
  }

  async accept(){
    if ( this.data?.escalation?._id && this.current_user ) {

      let escalation = await this.escalationService.endEscalation(this.data?.escalation?._id)
      if (escalation?.dateEnd){
        this._snackBar.open('Escalade terminée avec succès', 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
        this.currentFollowers = await this.followerService.getFolowers(this.data?.escalation?.orderId);
        let comment = {
          message: "Escalade desactivée. ",
          userId: this.current_user?.userId,
          orderId: this.data?.escalation?.orderId,
          statusId: this.data?.order?.statusId._id,
          escalationId: this.data?.escalation?._id,
          isEscalated: true,
          escalationCause: this.data?.escalation?.cause,
          followersIds: this.currentFollowers.map(f => f.userId?._id)
        }
    
        if (comment.message){
          this.commentService.addNewComment(comment).then(async (response) =>{   
           this.commentService.triggerCommentUpdated();
          })
        }
      }
      else {
        this._snackBar.open('Erreur lors de la termination d\'escalade ', 'Fermer', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom'
        });
      }
      this.dialogRef.close();
      this.commentService.triggerCommentUpdated();
      this.orderService.triggerOrderUpdated();
    }
  }
  decline(){
    this.dialogRef.close()
  }
  dismiss(){
    this.dialogRef.close()
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleService } from 'src/app/core/services/role.service';

@Component({
  selector: 'app-role-update-form',
  templateUrl: './role-update-form.component.html',
  styleUrls: ['./role-update-form.component.scss']
})
export class RoleUpdateFormComponent implements OnInit {
  updateForm!: FormGroup;
  public  allPermissions :any[] = []
  public  permissions :any[] = [];
  categories = [{ id: 'RACCORDEMENT', name: 'RACCORDEMENT' }];
  viewOrders = [{ id: 'NONE', name: 'Aucune' }, { id: 'ALL', name: 'Tout' }, { id: 'ASSIGNED', name: 'Affectées' }];
  constructor(private dialogRef: MatDialogRef<any>, private _snackBar: MatSnackBar , private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private roleService: RoleService) { 
      this.updateForm = this._formBuilder.group({
        reference: new FormControl(data?.role?.reference, Validators.required),
        name: [data?.role?.name, Validators.required],
        description: [data?.role?.description, Validators.required],
        caregories: this.data?.role?.listCategories,
        viewOrder: [data?.role?.viewOrder],
        permissions: ['', Validators.required]
      });
    }

  async ngOnInit(): Promise<void> {
    this.allPermissions = await this.roleService.getAllPermissions();
    let response = await this.roleService.getRoleWithPermissions(this.data?.role?._id);
    this.permissions = response?.permissions;
    this.updateForm.patchValue({ permissions: this.permissions?.map((p: { _id: any; }) => p._id) })
  }

  updateRole(){
    let role = {
      roleId: this.data?.role?._id,
      reference: this.updateForm.value.reference,
      name: this.updateForm.value.name,
      description: this.updateForm.value.description,
      listCategories: [this.updateForm.value.caregories],
      viewOrder: this.updateForm.value.viewOrder
    }

    this.roleService.UpdateRole(role)
    .then(response => {
      this._snackBar.open('Rôle mis à jour avec succès', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    }).catch(err => {
      this._snackBar.open('Erreur lors de mise à jour de rôle', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: "error-dialog"
      });
    }).finally(()=> {
      this.dialogRef.close();
      window.location.reload();
    })
  }
  updateRolePermission(){
    this.roleService.AddNewPermissionToRole({roleId: this.data?.role?._id, permissionIds: this.updateForm.value.permissions})
    .then(response => {
      this._snackBar.open('Permissions de rôle mises à jour avec succès', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    }).catch(err => {
      this._snackBar.open('Erreur lors de mise à jour de rôle', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: "error-dialog"
      });
    }).finally(()=> {
      this.dialogRef.close();
    })
  }
  cancelUpdate(){
    this.dialogRef.close();
  }

}

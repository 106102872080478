import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const DOCUMENT_API = environment.back_url ;
const config = { withCredentials: true}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private documentUpdatedSubject = new Subject<void>();
  documentUpdated$: Observable<void> = this.documentUpdatedSubject.asObservable();
  constructor(private http: HttpClient) { }

  async getDocuments(orderId: any){
    return (await axios.get(`${DOCUMENT_API}/orders/${orderId}/documents`, config)).data.data
  }
  async getDocument(documentId: any){
    return (await axios.get(DOCUMENT_API +documentId, config)).data.data
  }

  async getDocumentCategories(){
    return (await axios.get(`${DOCUMENT_API}/documents/categories`, config)).data.data
  }

  async getDocumentStatus(){
    return (await axios.get(`${DOCUMENT_API}/documents/status`, config)).data.data
  }


  async addDocument(data: FormData){
    return (await axios.post(`${DOCUMENT_API}/orders/${data?.get('orderId')}/documents`, data, config)).data.data
  }
  async updateDocument(data: FormData){
    return (await axios.put(`${DOCUMENT_API}/orders/${data?.get('orderId')}/documents/${data?.get('documentId')}`, data, config)).data.data
  }

  async DownloadDocument(orderId: string, fileId: string): Promise<any>{
    return (await axios.get(`${DOCUMENT_API}/orders/${orderId}/documents/${fileId}` , { withCredentials: true, responseType: "blob" })).data
  }

  async getDocumentByOrder(orderId: string, fileCategories: string[]){
    return (await axios.get(`${DOCUMENT_API}/orders/${orderId}/documents/categories/${fileCategories}`, config)).data.data
  }

  getPdfUri(documentId: string) : string {
    return `${DOCUMENT_API}/documents/uploads/${documentId}`;
  }


  triggerDocumentUpdated() {
    this.documentUpdatedSubject.next();
  }
}

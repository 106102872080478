<h2 class='title'>
    <mat-icon color="primary">calendar_month</mat-icon> Visite technique
</h2>
<div class="col-xl-12 twoboxes">
    <div class="row">
        <!-- rdv-date -->
        <div class="col-xl-12">
            <app-rdv-date [command]="command"></app-rdv-date>
        </div>
    </div>
    <div class="row">
        <!-- DTI_VT -->
        <div class="col-xl-6">
            <app-document-manager [command]="command" documentType = "VT_DTI_VT"></app-document-manager>
        </div>
         <!-- FOA -->
         <div class="col-xl-6">
            <app-document-manager [command]="command" documentType = "VT_FOA"></app-document-manager>
        </div>
        <!-- Cause d'échec -->
        <!--div class="col-xl-6">
            <app-failure-reason></app-failure-reason>
        </div-->
    </div>
    <div class="row">
        <!-- OTHER_VT -->
        <div class="col-xl-6">
            <app-document-manager [command]="command" documentType = "VT_OTHER_VT"></app-document-manager>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AgenceModalComponent } from 'src/app/pages/private/admin/components/agences/agence-modal/agence-modal.component';
import { AgencyUpdateFormComponent } from 'src/app/pages/private/admin/components/agences/agency-update-form/agency-update-form.component';
import { AgenceService } from 'src/app/core/services/agence.service';

@Component({
  selector: 'app-agences',
  templateUrl: './agences.component.html',
  styleUrls: ['./agences.component.scss']
})
export class AgencesComponent implements OnInit {

  displayedColumns: string[] = ['actions','company' ,'nom',  'address' , 'isDefault'];
  dataSource!: MatTableDataSource<any>;
  agences!: any[]
  
  constructor(private agencyService: AgenceService, public dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
    
    this.agencyService.agenceUpdated$.subscribe(() =>{
      this.loadData();
    })
    this.loadData();

  }
  applySearch(event: any){
    const searchVaule = (event.target as HTMLInputElement).value;
    this.dataSource.filter = searchVaule.trim().toLowerCase();
  }

  openAgencyModal(id: string) {
    const dialogRef = this.dialog.open(AgenceModalComponent, {
      data: {
        agencyId: id,
      },
      width: '60%',
      height: '40%',
    });

  }

  openAgencyUpdateForm(agency : any) {
    const dialogRef = this.dialog.open(AgencyUpdateFormComponent, {
      data: {
        agency: agency,
      },
      width: '60%',
      height: '90%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.agencyService.triggerAgenceUpdated();
    });
  }

  async loadData() {
    this.agences = await this.agencyService.getAgences();
    this.agences = this.agences.filter((item: any) => item?.companyId?.type === "SUBCONTRACTOR");
    this.dataSource = new MatTableDataSource<any>(this.agences);
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  template: `
    <input type="color" [(ngModel)]="selectedColor" (input)="onColorSelected()">
  `
})
export class ColorPickerComponent {

  @Output() colorSelected = new EventEmitter<string>();
  selectedColor = '#ffffff';

  onColorSelected() {
    this.colorSelected.emit(this.selectedColor);
  }

}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  user: any = {};
  constructor(public datepipe: DatePipe, private userService: UserService, private _Activatedroute:ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    let id = ""
    this._Activatedroute.paramMap.subscribe(params => { 
      id = String(params.get('id')); 
    });
    this.user = await this.userService.getUser(id);   
  }

}

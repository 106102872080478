import { Injectable } from '@angular/core';
import axios from 'axios'; 
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const PRODUCT_API = environment.back_url + '/products/';

const config = { withCredentials: true}

@Injectable({
  providedIn: 'root'
})


export class ProductService {
  
  constructor() { }
  private productUpdatedSubject = new Subject<void>();
  productUpdated$: Observable<void> = this.productUpdatedSubject.asObservable();
  async getProducts(){
    return await (await axios.get(PRODUCT_API , config)).data.data;
  }
  getProduct(id : string){
    return axios.get(PRODUCT_API + id, config)
  }

  updateProduct(product: any){
    return  axios.put(`${PRODUCT_API}${product?.id}`, product, config);
  }

  triggerProductUpdated() {
    this.productUpdatedSubject.next();
  }
}

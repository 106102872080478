<br>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="action-cell">Actions </th>
        <td mat-cell *matCellDef="let element" class="action-cell">
            <div  (click)="openTransitionUpdateForm(element)">
                <mat-icon color="primary">edit</mat-icon>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef> Référence </th>
        <td mat-cell *matCellDef="let element"> {{ element?.reference }} </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nom </th>
        <td mat-cell *matCellDef="let element"> {{ element?.name }} </td>
    </ng-container>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Déscription </th>
        <td mat-cell *matCellDef="let element"> {{ element?.description }} </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Statut courante</th>
        <td mat-cell *matCellDef="let element"> {{ element?.statusId?.name }} </td>
    </ng-container>
    <ng-container matColumnDef="nextStatus">
        <th mat-header-cell *matHeaderCellDef> Prochain statut </th>
        <td mat-cell *matCellDef="let element"> {{ element?.nextStatusId?.name }} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<app-header></app-header>

<mat-drawer-container>
  <div class="alert alert-danger" *ngIf="isLoggedIn">
    Page indisponible
  </div>
  <div class="col-md-12"  *ngIf="!isLoggedIn">
      <mat-card class="card card-container">
        <form #f  *ngIf="!isLoggedIn"  name="form" (ngSubmit)="onSubmit()" (keydown.enter)="onSubmit()" class="form" [formGroup] = "formdata"  novalidate>
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Identifiant</mat-label>
            <input matInput formControlName="username"  required />
          </mat-form-field>
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Mot de passe</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" >
            <span mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </span>
          </mat-form-field>
          <div class="full-width">
            <button class="btn btn-primary btn-block">
              Se connecter
            </button>
          </div>
          <div class="full-width">
            <div class="alert alert-danger" role="alert" *ngIf="isLoginFailed" >
              Erreur de connexion : {{ errorMessage }}
            </div>
          </div>
        </form>
  
      </mat-card>
  </div>
</mat-drawer-container>

<app-footer></app-footer>
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AgenceService } from 'src/app/core/services/agence.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { RoleService } from 'src/app/core/services/role.service';

@Component({
  selector: 'app-new-role',
  templateUrl: './new-role.component.html',
  styleUrls: ['./new-role.component.scss']
})
export class NewRoleComponent implements OnInit {
  roleFG!: FormGroup;
  categories = [{ id: 'RACCORDEMENT', name: 'RACCORDEMENT' }];
  viewOrders = [{ id: 'NONE', name: 'Aucune' }, { id: 'ALL', name: 'Tout' }, { id: 'ASSIGNED', name: 'Affectées' }];
  constructor(private _formBuilder: FormBuilder, private router: Router, private toastrService: ToastrService, private roleService: RoleService) { }

  ngOnInit(): void {
    this.roleFG = this._formBuilder.group({
      reference: new FormControl(null, Validators.required),
      name: [null, Validators.required],
      description: [null, Validators.required],
      caregories: [null],
      viewOrder: [null]
    });

  }

  async onSubmit() {
    let role = {
      reference: this.roleFG.value.reference,
      name: this.roleFG.value.name,
      description: this.roleFG.value.description,
      listCategories: [this.roleFG.value.caregories],
      viewOrder: this.roleFG.value.viewOrder
    }
    this.roleService.addNewRole(role).then((response) => {
      this.toastrService.success('', 'Rôle crée avec succès !', { positionClass: 'toast-top-center' })
      this.router.navigate(['/admin'])
      
    }).catch(err => {
      this.toastrService.success('', 'Erreur lors de la création de rôle !', { positionClass: 'toast-top-center' })
    })
  }
}

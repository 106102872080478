import { SimpleChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-delivery-site',
  templateUrl: './delivery-site.component.html',
  styleUrls: ['./delivery-site.component.scss']
})
export class DeliverySiteComponent implements OnInit {
  @Input() command: any;
  copied: boolean = false;
  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  
  }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.command.currentValue.statusId && changes.command.currentValue._id) {
    }
  }

  copyGPSToClipboard() {
    const coordinates = `${this.command.content?.siteId?.address?.coordinateY},${this.command.content?.siteId?.address?.coordinateX}`;
    
    const textArea = document.createElement('textarea');
    textArea.value = coordinates;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    
    this.copied = true;

    this._snackBar.open('Coordonnées GPS copiés', 'Fermer', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  copyReferenceToClipboard(reference: string, referenceType: string) {
    const baseUrl = window.location.origin;
    const link = `${baseUrl}/search/${referenceType}/${reference}`;
    
    const textArea = document.createElement('textarea');
    textArea.value = link;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    
    this.copied = true;

    this._snackBar.open('Lien copié', 'Fermer', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusCategory } from 'src/app/core/interfaces/statuscategory';
import { StatusService } from 'src/app/core/services/status.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { OrderService } from 'src/app/core/services/order.service';
import { PermissionService } from 'src/app/core/services/permission.service';

@Component({
  selector: 'app-command-container',
  templateUrl: './command-container.component.html',
  styleUrls: ['./command-container.component.scss']
})
export class CommandContainerComponent implements OnInit {

  activeComponent= "GeneralInfos";
  private id!: string;
  command = {} as any;
  current_user = {} as any;
  hasAccess = false;
  actor = {} as any;
  statusCategories: StatusCategory[] = [];
  superStates: String[] = []
  constructor(public permissionService: PermissionService, private _Activatedroute:ActivatedRoute, private orderService: OrderService,public datepipe: DatePipe,
    public tokenService: TokenStorageService, private statusService: StatusService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.current_user = this.tokenService.getUser();
    this.hasAccess = this.permissionService.hasPermission(this.current_user, 'ORDER_VIEW_ID'); // TO DO
    if (this.hasAccess) {
      this._Activatedroute.paramMap.subscribe(params => { 
          this.id = String(params.get('id')); 
      });
      try {
      this.command = await this.orderService.getOrder(this.id);
      this.actor = this.command?.statusId?.assignedRoleId;
      this.statusCategories = await this.statusService.getStatusCategories() 
      this.superStates = this.statusCategories.map((sc: StatusCategory) => sc.name);
      
      } catch(error: any){
        if(error.response && error.response.status === 403 ){
          this.hasAccess = false;
        }
      }
    }
    else {
      
    }
  }

  changeComponent(componentName: string) {
    this.activeComponent = componentName;
  }

  async loadOrder() {
    this.command = await this.orderService.getOrder(this.id);
  }
}

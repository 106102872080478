<div class="content" *ngIf="this.permissionService.hasPermission(current_user  , 'ORDER_ASSIGN');">
    <div class="row col-xl-12">
        <span matTooltip="Modifier le responsable" >Pilote ADV </span>
    </div>
    <div class="row col-xl-12">
        <mat-form-field class="supervisorName" appearance="fill" matTooltip="Modifier le responsable" (click)="openAssignmentForm('supervisor')">
            <mat-label>{{ command?.supervisorId?.contactId?.firstName}} {{ command?.supervisorId?.contactId?.lastName}}
            </mat-label>
            <input matInput placeholder="" readonly='true' />
        </mat-form-field>
    </div>
</div>
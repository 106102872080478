import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formdata = this.formBuilder.group({
    username: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
    password: ['', [
      Validators.required, 
      Validators.pattern('^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[!#$%&?]).*$')
    ]],
  });
  isLoggedIn !: boolean;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  hide = true;

  constructor(private formBuilder: FormBuilder, private authService: AuthentificationService, private tokenStorage: TokenStorageService,  private router: Router) { 
    this.authService.isLoggedIn.subscribe(loggedIn => {
      this.isLoggedIn = loggedIn 
    });
  }

  ngOnInit(): void {

    if (this.tokenStorage.getToken() && !this.tokenStorage.isTokenExpired()) {
      this.authService.isLoggedIn.subscribe(loggedIn => {
        this.isLoggedIn = loggedIn 
      });
      this.roles = this.tokenStorage.getUser().roles;
    }
    if (this.isLoggedIn){
      this.router.navigate(['/orders']);
    }
  }

  onSubmit(): void {
    const { username, password } = this.formdata.value;
    if (username !== null && username !== undefined && password !== null && password !== undefined) {
      this.authService.login(username, password).subscribe(
        (data: any) => {
          this.tokenStorage.saveUser(data.data);

          this.isLoginFailed = false;
          //this.isLoggedIn = this.authService.isLoggedIn;;
          this.roles = this.tokenStorage.getUser().roles;
          this.router.navigate(['/orders']).then(()=>{
            //window.location.reload()
          })
        },
        err => {
          this.errorMessage = "Merci de saisir un identifiant et mot de passe valides";
          this.isLoginFailed = true;
        }
      );
    }
    else {
      this.errorMessage = "Merci de saisir un identifiant et mot de passe valides";
      this.isLoginFailed = true;
    }
  }

  reloadPage(): void {
    window.location.reload();
  }
}
import { SecurityContext } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentService } from 'src/app/core/services/document.service';
import { WorkflowDocumentsService } from 'src/app/core/services/workflow-documents.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  pdf!: any;
  pdfSrc: any;
  pdfUrl: any; 
  fileReader = new FileReader();
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer, private documentService: DocumentService, private workflowDocumentsService: WorkflowDocumentsService) {
    this.sanitizer.sanitize(SecurityContext.URL, this.pdfSrc);
   }

  async ngOnInit(): Promise<void> {
    if (this.data.element) {
      this.pdf = this.data.element;
      this.pdf = this.data.element;
      this.getPdf(this.pdf?._id)
    }
    else {
      this.getLastWorkflow();
    }
  }
  getPdf(fileId: string) {
    if (this.data?.type === "workflow" ) {
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.workflowDocumentsService.getWorkflowUri(fileId));
    }
    else
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.documentService.getPdfUri(fileId));
  }
  getLastWorkflow() {
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.workflowDocumentsService.getLastWorkflow());
  }

}

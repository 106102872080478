<div class="rows col-xl-12" id="facturation">
    <br>
    <h1>
        <mat-icon color="primary">lists</mat-icon> Historique
    </h1>
    <hr>
    <div class="rows">
        <div *ngFor="let data of combinedData">
            <div class="rows event" *ngIf="data?.category === 'Orders_Raccordement' || (data?.transitionId?.statusId !== null) ||(data?.statusId)">
                <div class="col-xl-2">
                    Commande n° : <b>{{command?.externalReference}}</b>
                </div>
                <div class="col-xl-7">
                    <div *ngIf="data.category === 'Orders_Raccordement'">
                        {{ translateActivity(data)}} 
                        <a *ngIf= "data?.content?.callback === 'EC_PREPARE_RDV_VT__EC_WAIT_RETAILER_RDV_VT'" href="order/rdvChoice/VT/{{data?.orderId}}" target="_blank">Lien vers les Rdvs VT porposés </a>
                        <a *ngIf= "data?.content?.callback === 'EC_PREPARE_RDV_MES__EC_WAIT_RETAILER_RDV_MES'" href="order/rdvChoice/MES/{{data?.orderId}}" target="_blank">Lien vers les Rdvs MES porposés </a>
                    </div>
                    <div *ngIf="data?.category !== 'Orders_Raccordement'">
                        <span *ngIf="data?.statusId">
                            Changement d'état : État forcée <span *ngIf="data?.previousStatusId">de <b>{{data?.previousStatusId?.name}} </b> </span> à : <b>{{data?.statusId?.name}} </b>
                        </span>
                        <span *ngIf="data?.transitionId?.statusId !== null ">
                            Changement d'état : {{ data?.transitionId?.statusId?.name }} -> {{
                            data?.transitionId?.nextStatusId?.name }}
                        </span>
                        <!-- Handle other categories as needed -->
                    </div>
                </div>
                <div class="col-xl-3">
                    <div *ngIf="data?.category === 'Orders_Raccordement'">
                        Le
                        {{ datepipe.transform(data?.date, 'dd/MM/yyyy HH:mm:ss')}}
                    </div>
                    <div
                        *ngIf="data?.category !== 'Orders_Raccordement'">
                        Le {{ datepipe.transform(data?.date, 'dd/MM/yyyy HH:mm:ss')}}
                    </div>
                    <br> par :<b> {{data?.userId?.contactId?.firstName}} {{data?.userId?.contactId?.lastName}}</b>

                </div>
            </div>

        </div>
    </div>
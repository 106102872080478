import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { Router } from '@angular/router';
import { FilterService } from './filter.service';

const AUTH_API = environment.back_url + '/authentication/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json',
    'Pragma': 'no-cache',
    'Expires': '0'}),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {
  private loggedIn: Subject<boolean>;

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService, private filterService: FilterService ) {
    this.loggedIn = new Subject();
    this.getLogin();
    
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signin', {
      username,
      password
    }, httpOptions);
  }

  logout(current_user: any): Observable<any> {
    this.filterService.setFormFilter({assignedRole: "true", finishedOrders: "true"})
    return this.http.post(AUTH_API + 'signout', {userId: current_user} , httpOptions);
    
    
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username,
      email,
      password
    }, httpOptions);
  }

  isTokenExpired(): boolean {
    return false; // TO DO
  }

  getLogin(): Observable<any>  {
    return this.http.get(AUTH_API + 'login', {
      withCredentials: true
    })
    /*.subscribe(
      (resp: any) => {
        if (resp.loggedIn) {
          // Session is active, continue as usual
          this.loggedIn.next(true);
        } else {
          // Session expired or invalid
          this.loggedIn.next(false);
          // Handle session expiration, e.g., redirect to login page
        }
      },
      (errorResp) => {
        console.error(errorResp);
        // Handle error response
      }
    );*/
  }
  get isLoggedIn() {
    return this.loggedIn;
  }
}

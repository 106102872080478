import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AgenceModalComponent } from 'src/app/pages/private/admin/components/agences/agence-modal/agence-modal.component';
import { ContactService } from 'src/app/core/services/contact.service';

@Component({
  selector: 'app-contacts-administration',
  templateUrl: './contacts-administration.component.html',
  styleUrls: ['./contacts-administration.component.scss']
})
export class ContactsAdministrationComponent implements OnInit {

  displayedColumns: string[] = ['id', 'lastName','firstName', 'phone1' , 'phone2', 'emailAddress'];
  dataSource!: MatTableDataSource<any>;
  contacts!: any[]
  
  constructor(private contactService: ContactService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.contactService.getContacts().then(response =>{
      this.contacts = response.data.data;
      this.dataSource = new MatTableDataSource<any>(this.contacts);
    });
   
  }
  applySearch(event: any){
    const searchVaule = (event.target as HTMLInputElement).value;
    this.dataSource.filter = searchVaule.trim().toLowerCase();
  }

  openNotificationDialog(id: string) {
    const dialogRef = this.dialog.open(AgenceModalComponent, {
      data: {
        contactId: id,
      },
      width: '60%',
      height: '40%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}

<div class="row">
    <div class="col-xl-12">
        <mat-form-field class="input" appearance="fill">
            <mat-label>Description</mat-label>
            <textarea matInput  [(ngModel)]="description"></textarea>
        </mat-form-field>
    </div>
    <div class="col-xl-4">
        <button mat-raised-button color="accent" (click)="saveDescription()">Sauvegarder la déscription</button>
    </div>
   
</div>
<div class="row standard">
    <div class="col-xl-2"></div>
    <div class="col-xl-9">
        <form [formGroup]="roleFG">
            <p>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Référence</mat-label>
                    <input matInput placeholder="Référence" formControlName="reference" required>
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Nom</mat-label>
                    <input matInput placeholder="Nom" formControlName="name" required>
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="Description" formControlName="description" required>
                </mat-form-field>
                <mat-form-field appearance="fill" class="input">
                    <mat-label>Catégorie</mat-label>
                    <mat-select name="caregories" formControlName="caregories" required>
                        <mat-option *ngFor="let category of categories" value="{{category.id}}">
                            {{category.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="input">
                    <mat-label>Accès aux commandes</mat-label>
                    <mat-select name="viewOrder" formControlName="viewOrder" required>
                        <mat-option *ngFor="let viewOrder of viewOrders" value="{{viewOrder.id}}">
                            {{viewOrder.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
            <div>
                <button mat-button-raised (click)="onSubmit()" class="btn btn-success">Valider</button>
            </div>
        </form>
    </div>
    <div class="col-xl-2"></div>
</div>
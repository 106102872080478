import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { RdvFormComponent } from 'src/app/pages/private/calendar/components/rdv-form/rdv-form.component';
import { RdvUpdateComponent } from 'src/app/pages/private/calendar/components/rdv-update/rdv-update.component';
import { RdvViewComponent } from 'src/app/pages/private/calendar/components/rdv-view/rdv-view.component';
import { PermissionService } from 'src/app/core/services/permission.service';
import { RdvService } from 'src/app/core/services/rdv.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

const RdvMapping: any = {
  'DRAFT': { icon: 'question_mark', color: 'text-secondary' , translate: "Brouillon" },
  'WAITING_VALIDATION': { icon: 'hourglass_bottom', color: 'text-primary' , translate: "En attente de validation" },
  'ACCEPTED':{ icon: 'done', color: 'text-success' , translate: "Accepté par le partenaire" },
  'SCHEDULED':{ icon: 'hourglass_top', color: 'text-primary' , translate: "Programmé (confirmé par l'intervenant)" },
  'REJECTED': { icon: 'block', color: 'text-warning' , translate: "Rejetée" },
  'CANCELED': { icon: 'auto_delete', color: 'text-danger' , translate: "Annulé" },
  'FAILED':{ icon: 'warning', color: 'text-danger' , translate: "En échec" },
  'SUCCESS':{ icon: 'done_all', color: 'text-success' , translate: "Réalisé" }
}
@Component({
  selector: 'app-rdv-date',
  templateUrl: './rdv-date.component.html',
  styleUrls: ['./rdv-date.component.scss', '../vt.component.scss']
})


export class RdvDateComponent implements OnInit {
  @Input() command: any;
  command_id!: string;
  public rdvLogo = ''
  public rdvs : any[] = []
  current_user!: any;
  hasPermission = false;
  hasRole = false;
  isActive = false;
  constructor(public permissionService: PermissionService,private rdvService: RdvService, private tokenService: TokenStorageService, 
    private route: ActivatedRoute , private modalDialog: MatDialog,public datepipe: DatePipe,) { }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(params => {
      this.command_id = params['id'];
    });
    this.current_user = this.tokenService.getUser();
    this.hasPermission = this.permissionService.hasPermission(this.current_user  , 'RDV_VIEW_ID');
    this.hasRole = await this.permissionService.hasRole(this.current_user, this.command?.statusId?.assignedRoleId?.reference);
    this.isActive = await this.permissionService.canShowComponent('VT_RDV_DATE', this.command_id);
    
    
    if (this.hasPermission ) {
      try {
        this.rdvs = await this.rdvService.getRdvsTypedByOrder(this.command_id, 'VT');
        this.rdvService.rdvUpdated$.subscribe(() => {
          // Refresh your RDV data here
          this.loadData();
        });
      }
      catch(err) {
        console.warn('VT appointment not found');
      }
    }

  }


  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.command.currentValue.statusId && changes.command.currentValue._id) {
      this.hasRole = await this.permissionService.hasRole(this.current_user, changes.command.currentValue.statusId?.assignedRoleId?.reference);
      this.isActive = await this.permissionService.canShowComponent('VT_RDV_DATE', changes.command.currentValue._id);
    }
  }

  viewRdvs(){
    const dialogRef = this.modalDialog.open(RdvViewComponent, {
      data: {
        element: this.command_id,
        type: 'VT'
      },
      width: '60%',
      height: '40%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  addRdv(){
    const dialogRef = this.modalDialog.open(RdvFormComponent, {
      data: {
        element: this.command_id,
        type: 'VT'
      },
      width: '60%',
      height: '40%',
    });
    
  }

  updateRdv(rdv: any){
    const dialogRef = this.modalDialog.open(RdvUpdateComponent, {
      data: {
        element: this.command_id,
        rdv: rdv,
        type: 'VT'
      },
      width: '60%',
      height: '40%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  rdvMapping(status: string) {
    return RdvMapping[status as keyof any];
  }

  deleteRdv(rdv: any) {
    if(rdv.status === 'DRAFT') {
      const formData = new FormData();
      formData.append('rdvId', rdv?._id);
      formData.append('status', 'DELETED');
      formData.append('userId', this.current_user?.userId);
      formData.append('failureReason', '');
      formData.append('orderId', this.command_id);
      this.rdvService.updateRdvStatus(formData).then(async response =>{
        this.rdvService.triggerRdvUpdated();
      }).catch(err => console.error(err))
    }
  }

  async loadData() {
    try {
      if (this.hasPermission )
        this.rdvs = await this.rdvService.getRdvsTypedByOrder(this.command_id, 'VT');
    }
    catch(err) {
      console.warn('VT appointment not found');
    }
  }

}

<br>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">
            <div  (click)="openProductUpdateForm(element)">
                <mat-icon color="primary">edit</mat-icon>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let element"> {{ element?.type }} </td>
    </ng-container>
    <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef> Référence </th>
        <td mat-cell *matCellDef="let element"> {{ element?.reference }} </td>
    </ng-container>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nom </th>
        <td mat-cell *matCellDef="let element"> {{ element?.name }} </td>
    </ng-container>
    <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef> Nom à afficher </th>
        <td mat-cell *matCellDef="let element"> {{ element?.displayName }} </td>
    </ng-container>
    <ng-container matColumnDef="hardware">
        <th mat-header-cell *matHeaderCellDef> Matériel </th>
        <td mat-cell *matCellDef="let element"> {{ element?.hardware }} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
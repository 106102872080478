<h1 mat-dialog-title><span>Produit - Mise à jour</span>
    <span class="close">
        <mat-icon (click)="cancelUpdate()">close</mat-icon>
    </span>
</h1>
<div class="col-xl-12">
    <div class="row">
        <div class="col-xl-10">
            <form [formGroup]="updateForm">

                <mat-form-field class="input" appearance="fill">
                    <mat-label>Nom</mat-label>
                    <input matInput value="{{data?.product?.name}}" [disabled]="onlyReadInput" readonly/>
                </mat-form-field>


                <mat-form-field class="input" appearance="fill">
                    <mat-label>Type</mat-label>
                    <input matInput value="{{data?.product?.type}}" [disabled]="onlyReadInput" readonly/>
                </mat-form-field>


                <mat-form-field class="input" appearance="fill">
                    <mat-label>Référence</mat-label>
                    <input matInput value="{{data?.product?.reference}}" [disabled]="onlyReadInput" readonly/>
                </mat-form-field>

                <mat-form-field class="input" appearance="fill">
                    <mat-label>Nom à afficher</mat-label>
                    <input matInput value="{{ data?.product?.displayName}}" formControlName="displayName" required>
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Matériel</mat-label>
                    <input matInput value="{{data?.product?.hardware}}" formControlName="hardware" required>
                </mat-form-field>

                
                <button mat-raised-button color="primary" (click)="updateProduct()">Modifier les informations </button>

            </form>
        </div>
    </div>

</div>
<div class="row" >
    <div class="col-xl-12">
        <mat-label><b>Référence FCI</b></mat-label>
    </div>
    <div class="col-xl-12" >
        <div *ngIf="command?.content?.referenceFCI" class="alert alert-success">
            Référence FCI actuelle : {{ command?.content?.referenceFCI }}
        </div>
    </div>
    <div class="col-xl-12" *ngIf="hasPermission && isActive">
        <mat-form-field class="fci" appearance="fill">
            <input matInput [(ngModel)]="referenceFCI"/>
        </mat-form-field>
    </div>
    <div class="col-xl-12">
        <div *ngIf="!command?.content?.referenceFCI" class="alert alert-info">
            aucune référence FCI est renseignée
        </div>
    </div>
    <div class="col-xl-12">
        <div *ngIf="errorLog" class="alert alert-danger" [innerHTML]="errorLog">
        </div>
    </div>
    <div class="col-xl-4" *ngIf="hasPermission && isActive">
        <button mat-raised-button color="accent" (click)="saveReferenceFci()">Sauvegarder </button>
    </div>
</div>
<h1 mat-dialog-title>
    <span>Nouvelle catégorie de statut</span>
    <span class="close">
        <mat-icon (click)="exit()">close</mat-icon>
    </span>
</h1>
<div class="row standard">
    <div class="col-xl-2"></div>
    <div class="col-xl-9">
        <form [formGroup]="statutCategoriesFG">
            <p>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Référence</mat-label>
                    <input matInput placeholder="Référence" formControlName="reference" required>
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Nom</mat-label>
                    <input matInput placeholder="Nom" formControlName="name" required>
                </mat-form-field>
                <mat-form-field class="input" appearance="fill">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="Description" formControlName="description">
                </mat-form-field>
            </p>
            <div>
                <button mat-button-raised (click)="onSubmit()" class="btn btn-success">Valider</button>
            </div>
        </form>
    </div>
    <div class="col-xl-2"></div>
</div>
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivityService } from 'src/app/core/services/activity.service';
import { TransitionsLogsService } from 'src/app/core/services/transitions-logs.service';

const documentCategories: any = {
  'OTHER_BE': 'Autres BE',
  'OTHER_VT': 'Autre VT',
  'QUOTATION': 'DEvis TS',
  'PLAN_PDF': 'PLAN APS', 
  'PLAN_ZIP': 'APS', 
  'PURCHASE_ORDER': 'BDC', 
  'DTI_VT': 'CRVT', 
  'FOA': 'FOA', 
  'AC_REQUEST': 'Demande AC',
  'AC_RESPONSE': 'Réponse AC',
  'MEASURES': 'Réflectométrie', 
  'DOE': 'DOE', 
  'DFT': 'DFT', 
  'BDR': 'BDR'
};

const assignmentCategories: any = {
  'agency': 'Agence',
  'supervisor': 'Responsable',
  'provider': 'Intervenant',
  'engineeringOfficer': 'BE',
  'referenceFCI': 'Réference FCI'
}

const rdvStatus: any = {
  'DRAFT': 'Brouillon',
  'WAITING_VALIDATION': 'En attente de validation',
  'ACCEPTED':'Accepté par le partenaire',
  'SCHEDULED': "Programmé (confirmé par l'intervenant",
  'REJECTED': 'Rejetée',
  'CANCELED': 'Annulé',
  'FAILED': 'En échec',
  'SUCCESS': 'Réalisé'
}
@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.scss']
})
export class HistoriqueComponent implements OnInit {
  @Input() command: any;
  command_id = ''
  events: any[] = []
  activities: any[] = []
  combinedData: any[] = [];
  constructor(private transitionsLogsService: TransitionsLogsService,
    private route: ActivatedRoute, public datepipe: DatePipe, private activityService: ActivityService) { }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(params => {
      this.command_id = params['id'];
    })
    this.events = await this.transitionsLogsService.getTransitionsLogs(this.command_id)
    this.activities = await this.activityService.getActivitiesByOrder(this.command_id)
    this.mergeAndSortData();

  }


  mergeAndSortData(): void {
    this.combinedData = [...this.activities, ...this.events];
    this.combinedData.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA;
    });
  }

  translateActivity(data: any) {
    let translation = 'Nouvelle Activité : ';
    if (data?.category === 'Orders_Raccordement') {
      translation += this.Order_RaccordementPossibleActivities(data);
    }
    return translation;
  }

  Order_RaccordementPossibleActivities(data: any) {
    switch (data?.type) {
      case 'NEW_DOCUMENT': {
        let documentCategory = documentCategories[data?.content?.fileCategory as keyof any];
        return `Import d'un nouveau document ${documentCategory} (${data?.content?.fileStatus })`;
      }
      case 'UPDATE_DOCUMENT': {
        let documentCategory = documentCategories[data?.content?.fileCategory as keyof any];
        return `Changement de status d'un document ${documentCategory} -> ${data?.content?.fileStatus}`;
      }
      case 'NEW_ASSIGNMENT':  {
        let assignmentCaregory = assignmentCategories[data?.content?.type as keyof any]
        if (assignmentCaregory === 'Réference FCI')
          return `Mise à jour de Réference FCI -> la nouvelle valeur : ${data?.content?.name }`;
        else
          return `Nouvelle affectation -> Changement de ${assignmentCaregory} -> ${data?.content?.name }`;
      }
      case 'NEW_RDV':  {
        return `RDV [${data?.content?.type}] - Nouveau créneaux crée [${rdvStatus[data?.content?.status as keyof any]}] ->   (De ${this.datepipe.transform(data?.content?.dateStart, 'dd/MM/yyyy HH:mm:ss')} À ${this.datepipe.transform(data?.content?.dateEnd, 'dd/MM/yyyy HH:mm:ss')})`;
      }
      case 'UPDATE_RDV': {
        return `RDV [${data?.content?.type}] - Mise à jour de status à [${rdvStatus[data?.content?.status as keyof any]}] ->   (De ${this.datepipe.transform(data?.content?.dateStart, 'dd/MM/yyyy HH:mm:ss')} À ${this.datepipe.transform(data?.content?.dateEnd, 'dd/MM/yyyy HH:mm:ss')})`;
      }
      case 'MAIL_SENDING': {
        let content = `Envoi de mail automatique  à ${data?.content?.mail} `
        if (data?.content?.callback === 'EC_ADV__EC_WAIT_PROVIDER') {
          content += ` , Commande MAE soumise à ${this.datepipe.transform(data?.content?.date, 'dd/MM/yyyy HH:mm:ss')}`
        }
        return content;
      }
      default:
        return ''
    }
  }

}

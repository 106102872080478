import { Component,  OnInit, EventEmitter, Output } from '@angular/core';
import { PermissionService } from 'src/app/core/services/permission.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
  selector: 'app-command-menu',
  templateUrl: './command-menu.component.html',
  styleUrls: ['./command-menu.component.scss']
})
export class CommandMenuComponent implements OnInit {
  @Output() componentChange = new EventEmitter<string>();
  menuItems: { label: string, permission: string }[] = [
    { label: 'General', permission: 'ORDER_VIEW_ID' },
    { label: 'Messages', permission: 'ORDER_VIEW_ID' },
    { label: 'Facturation', permission: 'FINANCIAL_VIEW' },
    { label: 'Historique', permission: 'ORDER_ADMIN' },
    { label: 'Abonnés', permission: 'ORDER_VIEW_ID' }
    // Add more menu items as needed
  ];
  public activeOption= 'general';
  current_user!: any;
  hasPermission = false;
  constructor(public permissionService: PermissionService,private tokenService: TokenStorageService,) { }
  hiddenDivCount = 0;
  ngOnInit(): void {
    this.current_user = this.tokenService.getUser();
    this.updateHiddenDivCount()
  }

  

  // Your permissionService code goes here
  updateHiddenDivCount() {
    if (!this.permissionService.hasPermission(this.current_user, 'FINANCIAL_VIEW')) {
      this.hiddenDivCount += 1;
    } 
    if (!this.permissionService.hasPermission(this.current_user, 'ORDER_ADMIN')){
      // to show the historique 
      this.hiddenDivCount += 1;
    }


  }

  showGeneralInfos(){
    this.activeOption = 'general';
    this.componentChange.emit('GeneralInfos');
  }
  
  showMessages(){
    this.activeOption = 'messages';
    this.componentChange.emit('Messages');
  }

  showFacturation(){
    this.activeOption = 'facturation';
    this.componentChange.emit('Facturation');
  }

  showHistorique() {
    this.activeOption = 'historique';
    this.componentChange.emit('Historique');
  }

  showFollowers() {
    this.activeOption = 'followers';
    this.componentChange.emit('Followers');
  }

}

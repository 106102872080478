<div class='container-fluid'>
    <div class="col-xl-12 header">
        <app-header-v2></app-header-v2>
    </div>
    <div class="col-xl-12 content">
        <mat-drawer-container fxFlexFill>
            <mat-drawer-content fxFlex>
                <router-outlet></router-outlet>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>
<div class="">
    <div class="row">
        <div class="col-xl-8">
            <h2>
                <mat-icon color="primary">build</mat-icon> Statuts
            </h2>
        </div>
        <div class="col-xl-3">
            
        </div>
        <div class="col-xl-1">
            <mat-icon color="primary" (click)="openPdfViewer()" matTooltip="Visionner le workflow d'une commande ">picture_as_pdf</mat-icon>
        </div>
    </div>
    <div class="col-xl-12">
        <br>
        <mat-stepper [linear]="true" #stepper color="primary">
            <mat-step [completed]="superStates.slice(0).includes(command.statusId?.statusCategoryId?.name)">
                <ng-template matStepLabel *ngIf="statusCategories[0]">{{ statusCategories[0].name }}</ng-template>
            </mat-step>
            <mat-step [completed]="superStates.slice(1).includes(command.statusId?.statusCategoryId?.name)"
                [editable]="false">
                <ng-template matStepLabel *ngIf="statusCategories[1]">{{ statusCategories[1].name }}</ng-template>
            </mat-step>
            <mat-step [completed]="superStates.slice(2).includes(command.statusId?.statusCategoryId?.name)"
                [editable]="false">
                <ng-template matStepLabel *ngIf="statusCategories[2]">{{ statusCategories[2].name }}</ng-template>
            </mat-step>
            <mat-step [completed]="superStates.slice(3).includes(command.statusId?.statusCategoryId?.name)"
                [editable]="false">
                <ng-template matStepLabel *ngIf="statusCategories[3]">{{ statusCategories[3].name }}</ng-template>
            </mat-step>
            <mat-step [completed]="superStates.slice(4).includes(command.statusId?.statusCategoryId?.name)"
                [editable]="false">
                <ng-template matStepLabel *ngIf="statusCategories[4]">{{ statusCategories[4].name }}</ng-template>
            </mat-step>
            <mat-step [completed]="superStates.slice(5).includes(command.statusId?.statusCategoryId?.name)"
                [editable]="false">
                <ng-template matStepLabel *ngIf="statusCategories[5]">{{ statusCategories[5].name }}</ng-template>
            </mat-step>
            <mat-step [completed]="superStates.slice(6).includes(command.statusId?.statusCategoryId?.name)"
                [editable]="false">
                <ng-template matStepLabel *ngIf="statusCategories[6]">{{ statusCategories[6].name }}</ng-template>
            </mat-step>
        </mat-stepper>

    </div>
    <div class="col-xl-12 content">
        <div class="status-assignedTo">
            <div class="status" matTooltip='{{ command.statusId?.statusCategoryId?.name }}'><span>Statut : </span>
                <b>{{ command?.statusId?.name }}</b>
            </div>
            <div class="assignedTo"><span> Assigné à :</span>
                <b> {{command?.agencyId?.name}}</b>
                <div *ngIf="command?.providerId">
                    - {{ command?.providerId?.contactId?.firstName}} {{
                    command?.providerId?.contactId?.lastName}}
                </div>
            </div>
        </div>
        <div class="statusDescription">
            <div class="alert alert-secondary"><b>Déscription de statut : </b>{{command?.statusId?.description}}</div>
        </div>
        <div class="card-body">
            <mat-radio-group class="actions" [ngModel]="state" (ngModelChange)="onChange($event)" class="radio-group">
                <mat-radio-button *ngFor="let transition of transitions" [value]="transition"
                    [disabled]="!hasPermission || !hasRole || !command.statusId">
                    <div matTooltip="{{ transition.description }}" class="action">
                        {{transition.name}} <br>(Prochain état :
                        <span><b>{{transition.nextStatusId.name }} ) </b></span>
                    </div>

                </mat-radio-button>
            </mat-radio-group>
            <div class="col-xl-12">
                <div class="alert alert-secondary" *ngIf="transitionDesc"><b>Déscription de la transition : </b>{{ transitionDesc }}</div>
            </div>
            <br>
            <div #componentContainerMandatory class="component-container col-xl-6 ">
                <div class="row">
                    <span *ngIf="state && componentRefs.length" class="col-xl-12 alert alert-warning mandatory">Données obligatoire</span>
                </div>
            </div>
            
            <div #componentContainerOptional class="component-container col-xl-6">
                <div class="row">
                    <span *ngIf="state && componentRefs.length" class="col-xl-12 alert alert-primary optional">Données Optionnelles</span>
                </div>
            </div>
            <button mat-raised-button color="primary" (click)="saveState(state)" class="validateAction"
                *ngIf="hasPermission && hasRole && command.statusId" [disabled]="!state">Valider</button>
        </div>
    </div>
</div>
import { SimpleChanges } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/core/services/order.service';
import { StatusService } from 'src/app/core/services/status.service';

@Component({
  selector: 'app-force-status',
  templateUrl: './force-status.component.html',
  styleUrls: ['./force-status.component.scss']
})
export class ForceStatusComponent implements OnInit {

  @Input() command: any;
  status : any[] = []
  selectedStatus = '';
  constructor(private statusService: StatusService, private _snackBar: MatSnackBar,
    private orderService: OrderService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.status = await this.statusService.getStatus();
  }
  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.selectedStatus = this.command?.statusId?._id;
  }

  updateStatus(){
    this.orderService.UpdateOrderForceStatus(this.command?._id, this.selectedStatus).then((response ) => {
      this._snackBar.open('Status :  mise à jour avec succès', 'Fermer', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
      window.location.reload();
      
    }).catch(err => {
      console.log("erreur lors de la mise à jour de status")
    })
  }

}

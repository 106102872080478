import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { RoleModalComponent } from 'src/app/pages/private/admin/components/roles/role-modal/role-modal.component';
import { RoleUpdateFormComponent } from 'src/app/pages/private/admin/components/roles/role-update-form/role-update-form.component';
import { RoleService } from 'src/app/core/services/role.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  displayedColumns: string[] = ['actions', 'name', 'description'];
  dataSource!: MatTableDataSource<any>;
  roles: any = [];
  constructor(private roleService: RoleService, public dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
    this.roles = await this.roleService.getRoles();
    this.dataSource = new MatTableDataSource<any>(this.roles);
  }

  openRoleModal(role: any) {
    
    const dialogRef = this.dialog.open(RoleModalComponent, {
      data: {
        role: role,
      },
      width: '60%',
      height: '80%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  
  }
  openRoleUpdateForm(role: any) {
    const dialogRef = this.dialog.open(RoleUpdateFormComponent, {
      data: {
        role: role,
      },
      width: '60%',
      height: '90%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
const BACK_API = environment.back_url ;
const config = { withCredentials: true}
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }),
  withCredentials: false
};


@Injectable({
  providedIn: 'root'
})
export class OpticalLoopService {

  
  constructor(private http: HttpClient,) { }

  async getOpticalLoops() : Promise<any>{
    return (await axios.get(`${BACK_API}/loops`, config)).data.data;
  }
}

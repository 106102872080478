<h1 mat-dialog-title><mat-icon>calendar_today</mat-icon><span> Rdv {{  data?.type}}</span></h1>
<div class="form" [formGroup]="rdvUpdateForm">
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="col-xl-3">
                <mat-form-field appearance="fill" readOnly='true' disbaled>
                    <mat-label>Choisir une date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="dateStart" readOnly='true' disbaled>
                    <mat-hint>JJ/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker readOnly='true' disbaled></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-xl-3">
                <mat-label>Créneau De {{ defaultSlot.start }} À {{ defaultSlot.end }}</mat-label>
            </div>
            <div class="col-xl-3">
                <mat-form-field appearance="fill" >
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status" (selectionChange)="onStatusChanged($event)">
                        <mat-option *ngFor="let status of RdvStatusMapping" matTooltip="{{status.translate}}" [value]="status.name"> {{status.translate}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-xl-3">
                <mat-form-field appearance="fill" *ngIf="['FAILED', 'CANCELED'].includes(rdvUpdateForm.get('status')?.value)">
                    <mat-label>Cause d'échec</mat-label>
                    <mat-select name="reason" formControlName="failureReason" [required]="['FAILED', 'CANCELED'].includes(rdvUpdateForm.get('status')?.value)" >
                        <mat-option *ngFor="let reason of reasons" matTooltip="{{reason}}"  [value]="reason">
                            {{reason}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="alert alert-warning" *ngIf="error">{{error}}</div>
    </mat-dialog-content>
    <br><br>

    <td><button mat-raised-button color="primary" (click)="updateRdv()">Valider</button></td>
    <td><button mat-raised-button color="warn" mat-dialog-close>Annuler</button></td>
</div>
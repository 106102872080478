import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TransitionUpdateFormComponent } from 'src/app/pages/private/admin/components/transitions/transition-update-form/transition-update-form.component';
import { TransitionsLogsService } from 'src/app/core/services/transitions-logs.service';


@Component({
  selector: 'app-transitions',
  templateUrl: './transitions.component.html',
  styleUrls: ['./transitions.component.scss']
})
export class TransitionsComponent implements OnInit {

  displayedColumns: string[] = ['actions',  'reference', 'name',  'description', 'status' , 'nextStatus' ];
  dataSource!: MatTableDataSource<any>;
  transitions: any = [];
  links: any= [];
  nodes: any= [];
  constructor(private transitionService: TransitionsLogsService,  public dialog: MatDialog) { }

  ngOnInit(): void {
    this.transitionService.transitionsUpdated$.subscribe(() =>{
      this.loadData();
    })
    this.loadData();
  }


  openTransitionUpdateForm(transition: any) {
    const dialogRef = this.dialog.open(TransitionUpdateFormComponent, {
      data: {
        transition: transition,
      },
      width: '60%',
      height: '55%',
    });
    
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  
  
  

  async loadData() {
    this.transitions = await this.transitionService.getTransitions();
    this.dataSource = new MatTableDataSource<any>(this.transitions);
    this.links = this.transitions.map((t: any) => ({ source: t?.statusId, target: t?.nextStatusId  }))
    this.nodes = this.transitions.map((t: any) => ({ id: t?.statusId, label: t?.statusId }))
  }
}

import { Injectable } from '@angular/core';
import axios from 'axios';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const COMMENT_API = environment.back_url ;
const config = { withCredentials: true}
@Injectable({
  providedIn: 'root'
})
export class CommentService {
  private messageText: string = '';
  private escalationCause!: string ;
  private commentUpdatedSubject = new Subject<void>();
  commentUpdated$: Observable<void> = this.commentUpdatedSubject.asObservable();
  constructor() { 
  }
  

  getMessageText() {
    return this.messageText;
  }

  setMessageText(text: string) {
    this.messageText = text;
  }

  getEscalationCause() {
    return this.escalationCause;
  }

  setEscalationCause(cause: string) {
    this.escalationCause = cause;
  }
  getComments(){
    return  axios.get(COMMENT_API);
  }

  async getCommentsByStatus(orderId: string, statusId: string){
    return  (await axios.get(`${COMMENT_API}/orders/${orderId}/comments/few/${statusId}` , config)).data.data;
  }
  async getCommentsByOrderId(orderId: string){
    return  (await axios.get(`${COMMENT_API}/orders/${orderId}/comments/all`, config)).data.data;
  }
  getComment(id: string){
    return  axios.get(COMMENT_API + '/comments/comment/' + id), config;
  }

  getTwoLastComment(orderId: String){
    return  axios.get(`${COMMENT_API}/orders/${orderId}/comments/last`, config);
  }

  addNewComment(comment: any){
    return  axios.post(`${COMMENT_API}/orders/${comment?.orderId}/comments`, comment, config);
  }

  
  triggerCommentUpdated() {
    this.commentUpdatedSubject.next();
  }

}

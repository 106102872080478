<div class="container-fluid mt-3">
    <nav class="navbar navbar-light bg-light header">
        <a class="navbar-brand" href="#">
            <img src="../../../../assets/images/alphalink-logo.svg" width="200" height="100"
                class="d-inline-block align-top" alt="">
        </a>
    </nav>
</div>
<div class="container mt-6 pt-5" *ngIf="groupedRdvs.length && acceptedRdv?.length === 0">
    <div class="text-center">
        Afin de raccorder le client : <b>{{ command?.content?.siteId?.name }} </b>,  Commande EMA : <b>{{ command?.content?.ema?.idProductOrder }}</b> ,
        <span class="rdvDescription" *ngIf="description">
            Commentaire : <b>{{ description }} </b>
        </span>
        <br><br> Veuillez sélectionner l'un des créneaux horaires  <b>{{ type}}</b> ci-dessous :
    </div>
</div>
<div class="container-fluid pt-3" *ngIf="groupedRdvs.length && acceptedRdv?.length === 0">
    <div class="slider-extremities">
        <div class="extremity left-extremity">{{groupedRdvs[0].date | date: 'EEEE dd MMMM y' : '' : 'fr'}}</div>
        <div class="extremity right-extremity">{{groupedRdvs[groupedRdvs.length-1].date | date: 'EEEE dd MMMM y' : '' :
            'fr'}}</div>
    </div>
    <mat-slider min="" max="" color="primary" thumbLabel [step]="100">
    </mat-slider>


</div>
<div class="container" *ngIf="groupedRdvs?.length === 0 && acceptedRdv?.length">
    <div class="text-center"><b>Vous avez bien choisi ce RDV : </b></div>
    <div class="col-xl-2 acceptedRdv pt-3">
        <div class="card box">
            <div class="card-header">
                {{ acceptedRdv[0]?.dateStart | date: 'MMMM y' : '' : 'fr' }}
            </div>
            <mat-divider color='primary'></mat-divider>
            <div class="card-body">
                <div class="card-text">
                    {{ acceptedRdv[0]?.dateStart | date: 'EEEE dd' : '' : 'fr' }}
                </div>
            </div>
        </div>
        <div class="slot card card-header">
            {{ acceptedRdv[0]?.dateStart | date: 'shortTime' : '' : 'fr' }} - {{ acceptedRdv[0]?.dateEnd | date:
            'shortTime' :
            '' :
            'fr' }}
        </div>
    </div>
</div>
<div class="container" *ngIf="groupedRdvs?.length === 0 && acceptedRdv?.length === 0">
    <div class="col-xl-2 acceptedRdv pt-3">
        <div class="card box warning" >
            <div class="card-header warning-title">
                Information
            </div>
            <div class=" card-body card-text" >
                Pas de créneaux disponibles pour le moment.
            </div>
        </div>
    </div>
</div>
<div class="container pt-3" *ngIf="groupedRdvs.length && acceptedRdv?.length === 0">

    <div class="row col-xl-12 ">
        <div *ngFor="let groupedRdv of groupedRdvs" class="col-xl-2">
            <div class="row-spacing">
                <div class="card box">
                    <div class="card-header">
                        {{ groupedRdv.date | date: 'MMMM y' : '' : 'fr' }}
                    </div>
                    <mat-divider color='primary'></mat-divider>
                    <div class="card-body">
                        <div class="card-text">
                            {{ groupedRdv.date | date: 'EEEE dd' : '' : 'fr' }}
                        </div>
                    </div>
                </div>
                
                <div *ngFor="let rdv of groupedRdv.slots">
                        <div class="slot card card-header" matTooltip="sélectionner ce créneau" (click)="selectSlot(rdv)" [class.selected]="rdv === selectedSlot">
                            {{ rdv.dateStart | date: 'shortTime' : '' : 'fr' }} - {{ rdv.dateEnd | date: 'shortTime' :
                            '' :
                            'fr' }}
                            <!-- Add other information specific to each slot here -->
                        </div>
                        
                </div>
                
            </div>
        </div>
    </div>
    <div class="row pt-3">
        <mat-label>Ajouter un commentaire</mat-label>
    </div>
    <div class="row pt-2">
        <div class="col-xl-12 ">
            <mat-form-field class="full-width">
                <textarea matInput placeholder="Ex ...." [(ngModel)]="comment"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col-xl-4"></div>
        <div class="col-xl-4">
            <button mat-raised-button color="accent" (click)="bookSlot(comment)">Confirmer</button>&nbsp; &nbsp;&nbsp;
            <button mat-raised-button color="warn" (click)="cancelSlots(comment)">Tout refuser</button>
        </div>
        <div class="col-xl-4"></div>
    </div>

</div>